.catalogsPage {
  background-color: $color-white;
  border-radius: 25px;
  padding: 20px;
  .data {
    margin: 20px 0;
    .catalog {
      @include flex-between;
      background-color: $color-white-02;
      margin: 20px 0;
      border-radius: 15px;
      padding: 10px;
      p {
        font-size: 18px;
      }
      svg {
        cursor: pointer;
        height: 30px;
        width: 30px;
      }
    }
  }
}

.createCatalogPage {
  background-color: $color-white;
  padding: 20px;
  border-radius: 25px;
  .fileLabel {
    border: 1px dashed black;
    padding: 30px;
    display: block;
    width: 20%;
    margin: 10px 0;
    text-align: center;
  }
  input[type='file'] {
    color: transparent;
    text-indent: -9999px;
    position: absolute;
  }
}
