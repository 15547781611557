/* Fonts */
/* Colors */
/* Radius */
/* Spacings */
/* Sizes */
/* Icons */
/* Screens */
.m-0 {
  margin: 0 !important;
}

.mb_0 {
  margin-bottom: 0 !important;
}

.m-7 {
  margin: 0 7px !important;
}

.mt_10 {
  margin-top: 10px;
}

.bg_primary {
  background: #0787ea !important;
  color: #fff !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mt-0 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml_20 {
  margin-left: 20px !important;
}

.ml_8 {
  margin-left: 8px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml_10 {
  margin-left: 10px !important;
}

.align_row {
  display: flex;
  align-items: center;
}

.cursor {
  cursor: pointer;
  padding: 15px 0;
}

.pointer {
  cursor: pointer;
}

h1.page_root_title {
  font-size: 2rem;
  font-weight: 400;
  color: #333e48;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  h1.page_root_title {
    font-size: 1.5em;
  }
}

.space_between {
  justify-content: space-between;
}

.flex_align {
  display: flex;
  align-items: center;
}

.jc_end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mt-0px {
  margin-top: 0 !important;
}

.img_placeholder {
  opacity: 0.3;
  width: 80px;
}

.custom_m {
  margin: 40px 0;
  align-items: start !important;
}

.editorClassName {
  z-index: 1;
  position: relative;
  font-size: 13px;
  border-radius: 5px;
  padding: 0 20px;
  background: transparent;
  color: #333e48;
  transition: all 0.2s ease;
  width: 100%;
  min-height: 200px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.dangerouslySetInnerHTML {
  font-size: 18px !important;
  display: flex !important;
  flex-direction: column !important;
}
.dangerouslySetInnerHTML ol,
.dangerouslySetInnerHTML li {
  list-style-type: revert;
}
.dangerouslySetInnerHTML p {
  font-size: 18px !important;
}
.dangerouslySetInnerHTML a {
  color: #0787ea !important;
  font-size: 18px !important;
  margin: 0 2px !important;
}

.dangerouslySetInnerHTMLNews {
  font-size: 14px !important;
  display: flex !important;
}
.dangerouslySetInnerHTMLNews p {
  font-size: 14px !important;
}
.dangerouslySetInnerHTMLNews a {
  color: #0787ea !important;
  font-size: 14px !important;
  margin: 0 2px !important;
}

.change_device_dropdown_outer {
  background: #fff;
  width: 250px;
  height: 40px;
  position: relative;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.4);
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.change_device_dropdown_outer .change_device_dropdown_inner {
  width: 100%;
  height: 100%;
}
.change_device_dropdown_outer .change_device_dropdown_inner .root {
  padding: 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  color: #333e48;
}
.change_device_dropdown_outer .change_device_dropdown_inner .dropdown_holder {
  transition: all 0.2s ease;
  z-index: 2;
  position: absolute;
  top: calc(100% + 5px);
  left: -1px;
  right: -1px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 0;
  background: #fff;
  padding: 5px 0;
}
.change_device_dropdown_outer .change_device_dropdown_inner .dropdown_holder .dropdown_content {
  width: 100%;
  height: 100%;
}
.change_device_dropdown_outer .change_device_dropdown_inner .dropdown_holder .dropdown_content p {
  font-size: 14px;
  padding: 5px 15px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.change_device_dropdown_outer .change_device_dropdown_inner .dropdown_holder .dropdown_content p:hover {
  background: #f8f8f8;
}
.change_device_dropdown_outer .change_device_dropdown_inner .dropdown_holder .dropdown_content p.active {
  color: #333e48;
  background: #f8f8f8;
}

.expand_more_arrow {
  color: #b9b9b9;
  transition: all 0.2s ease !important;
}
.expand_more_arrow.active {
  transform: rotate(180deg);
}

.category_dropzone {
  width: 200px !important;
  height: 200px !important;
}
.category_dropzone .input_file_holder {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  height: 100% !important;
}
.category_dropzone .input_file_holder .text {
  font-size: 16px !important;
}
.category_dropzone .input_file_holder .delete_icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(234, 84, 85);
  z-index: 2;
}

.root_loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.color_black {
  color: #333e48 !important;
}

.active_index {
  color: #333e48;
  background: rgb(204, 204, 204);
}

.mass_update_discounts_modal .MuiDialogContent-root {
  padding: 0;
}
.mass_update_discounts_modal .MuiDialog-paper {
  padding: 30px;
  width: 500px;
  overflow: unset;
}
@media only screen and (max-width: 600px) {
  .mass_update_discounts_modal .MuiDialog-paper {
    margin: 20px;
    padding: 20px;
    width: 100%;
  }
}
.mass_update_discounts_modal .MuiDialogContent-root {
  overflow: unset;
}
.mass_update_discounts_modal .MuiDialogTitle-root {
  padding: 0;
}
.mass_update_discounts_modal .MuiDialogTitle-root h2 {
  margin-bottom: 40px;
  text-align: center;
}
.mass_update_discounts_modal .product_brand_field {
  margin-top: 30px;
}
.mass_update_discounts_modal .product_brand_field .input_holder {
  margin: 0;
}
.mass_update_discounts_modal .input_holder {
  margin-top: 30px;
}
.mass_update_discounts_modal .form_buttons_group {
  margin-top: 30px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  line-height: 1.3;
  color: #333e48;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;
  position: relative;
  left: 0;
  font-family: "Segoe UI", "Open Sans", "Helvetica Neue", sans-serif;
}

button,
select,
textarea,
input {
  border: 0;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.show_apparence input::-webkit-outer-spin-button,
.show_apparence input::-webkit-inner-spin-button {
  -webkit-appearance: button;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  resize: none;
}

button {
  cursor: pointer;
}

ol,
li {
  list-style-type: none;
}

a {
  text-decoration: none;
  font-size: 16px;
  color: #0787ea;
}

.container {
  margin: 0 auto;
  padding: 0 0.9375rem;
  width: 100%;
}
@media (min-width: 544px) {
  .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 940px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1480px) {
  .container {
    max-width: 1430px;
  }
}
@media (min-width: 1800px) {
  .container {
    max-width: 1550px;
  }
}

.notification_style {
  padding: 1rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0 0.428rem 0.428rem 0;
  z-index: 99999;
  font-weight: 500;
  background: #fff;
  color: #6e6b7b;
  font-weight: 400;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  border-left: 3px solid transparent;
}
.notification_style.success {
  border-color: #28c76f;
}
.notification_style.success span {
  color: #28c76f;
}
.notification_style.danger {
  border-color: #ea5455;
}
.notification_style.danger span {
  color: #ea5455;
}
.notification_style span {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  color: #fff;
}
.notification_style span svg {
  width: 25px;
  height: 25px;
  font-size: 24px;
}

.error_msg {
  color: rgb(234, 84, 85);
  font-size: 14px;
}

@keyframes search_slidedown {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slidedown {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes sticky_slow_down {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes accordian {
  from {
    height: 0;
    padding: 0 20px;
    margin: 0;
  }
  to {
    height: 190px;
    padding: 20px;
    margin-top: 20px;
  }
}
@keyframes accordian_disable {
  from {
    height: 190px;
    padding: 20px;
    margin-top: 20px;
  }
  to {
    height: 0;
    padding: 0 20px;
    margin: 0;
  }
}
@keyframes accordian2 {
  from {
    height: 0;
    padding: 0 20px;
    margin: 0;
  }
  to {
    height: 270px;
    padding: 20px;
    margin-top: 20px;
  }
}
@keyframes accordian_disable2 {
  from {
    height: 270px;
    padding: 20px;
    margin-top: 20px;
  }
  to {
    height: 0;
    padding: 0 20px;
    margin: 0;
  }
}
@keyframes sidebar_dropdown {
  from {
    height: 0;
    overflow: hidden;
  }
  to {
    height: 210px;
    overflow: visible;
  }
}
@keyframes sidebar_dropdown_close {
  from {
    height: 210px;
  }
  to {
    height: 0;
  }
}
.cms_content {
  padding: 90px 20px 20px 290px;
  background: #f8f8f8;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;
  min-height: 100vh;
}
@media only screen and (max-width: 1400px) {
  .cms_content {
    padding-left: 260px;
  }
}
@media only screen and (max-width: 1200px) {
  .cms_content {
    padding: 90px 20px 20px 20px;
  }
}
@media only screen and (max-width: 500px) {
  .cms_content {
    padding: 75px 10px 10px 10px;
  }
}
.cms_content.active {
  padding-left: 100px;
}
@media only screen and (max-width: 1200px) {
  .cms_content.active {
    padding-left: 20px;
  }
}
.cms_content .cms_page .data_no_found {
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
}
.cms_content .box_holder {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 20px;
}
@media only screen and (max-width: 500px) {
  .cms_content .box_holder {
    padding: 15px;
  }
}

@media only screen and (max-width: 750px) {
  .product_page_wrap .table_helper .align_row:last-child {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .product_page_wrap .table_helper .align_row .table_helper_icons {
    margin-top: 20px;
  }
}

.table-products .header,
.table-products .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 60px 170px 1fr 250px 90px 90px 90px 90px 90px 90px 120px 100px;
}
@media only screen and (max-width: 1800px) {
  .table-products .header,
  .table-products .table_row {
    grid-template-columns: 50px 200px 1fr 250px 100px 100px 100px;
  }
}
@media only screen and (max-width: 1400px) {
  .table-products .header,
  .table-products .table_row {
    grid-template-columns: 50px 150px 1fr 200px 100px 100px;
  }
}
@media only screen and (max-width: 1000px) {
  .table-products .header,
  .table-products .table_row {
    grid-template-columns: 50px 150px 1fr 250px 100px;
  }
}
@media only screen and (max-width: 750px) {
  .table-products .header,
  .table-products .table_row {
    grid-template-columns: 50px 1fr 100px;
  }
}
@media only screen and (max-width: 500px) {
  .table-products .header,
  .table-products .table_row {
    grid-template-columns: 50px 1fr;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .header {
    padding-left: 45px !important;
  }
  .table-products .header .table_cell {
    display: none;
  }
  .table-products .header .table_cell:nth-child(1) {
    display: flex !important;
  }
  .table-products .header .table_cell:nth-child(2) {
    display: flex !important;
  }
  .table-products .header .table_cell:nth-child(3) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 750px) {
  .table-products .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .header .table_cell:nth-child(4) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-products .header .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .header .table_cell:nth-child(5) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-products .header .table_cell:nth-child(5) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .header .table_cell:nth-child(6) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-products .header .table_cell:nth-child(6) {
    display: none !important;
  }
}
.table-products .table_row {
  cursor: pointer;
}
.table-products .table_row .table_cell.p15-0 {
  height: 45px;
}
.table-products .table_row .table_cell.p15-0 a {
  height: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 8px 10px !important;
  }
  .table-products .table_row .expand_button {
    display: block;
  }
  .table-products .table_row .table_cell {
    display: none;
  }
  .table-products .table_row .table_cell:nth-child(2) {
    display: flex !important;
    width: 40px !important;
    margin: 0 10px !important;
  }
  .table-products .table_row .table_cell:nth-child(3) {
    display: flex !important;
    width: 200px !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-products .table_row .table_cell:nth-child(3) {
    width: 150px !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 750px) {
  .table-products .table_row .table_cell:nth-child(3) {
    width: unset !important;
    flex: 1;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .table_cell:nth-child(4) {
    display: flex !important;
    flex: 1;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 750px) {
  .table-products .table_row .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .table_cell:nth-child(5) {
    display: flex !important;
    width: 250px !important;
  }
  .table-products .table_row .table_cell:nth-child(5) p {
    padding-right: 10px;
    overflow: hidden;
    width: 90%;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-products .table_row .table_cell:nth-child(5) {
    width: 200px !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-products .table_row .table_cell:nth-child(5) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .table_cell:nth-child(6) {
    display: flex !important;
    width: 100px !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-products .table_row .table_cell:nth-child(6) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .table_cell:nth-child(7) {
    display: flex !important;
    width: 100px !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-products .table_row .table_cell:nth-child(7) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .product_table_icons {
    width: 100px;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 500px) {
  .table-products .table_row .product_table_icons {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper {
    display: block;
  }
  .table-products .table_row .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 120px;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 500px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 105px;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .linking_products_mobile .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .table-products .table_row .mobile_table_wrapper .linking_products_mobile .row.top {
    font-weight: bold;
    background: rgba(0, 0, 0, 0.05);
  }
  .table-products .table_row .mobile_table_wrapper .linking_products_mobile .row > div {
    padding: 5px;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 950px) {
  .table-products .table_row .mobile_table_wrapper .linking_products_mobile .row > div {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 600px) {
  .table-products .table_row .mobile_table_wrapper .linking_products_mobile .row > div {
    font-size: 10px;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 750px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(4) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(4) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(5) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(5) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(9) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(9) {
    display: grid;
  }
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(9) .row_content .table_cell {
    display: block !important;
    font-size: 12px;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(10) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 500px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(10) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(11) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 500px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(11) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(12) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 500px) {
  .table-products .table_row .mobile_table_wrapper .mobile_table_row:nth-child(12) {
    display: grid;
  }
}
.table-products .table_row .table_cell:last-child {
  overflow: unset !important;
}

.table-productAttributes .header,
.table-productAttributes .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 30px 30px 1fr 80px 50px 50px;
}
@media only screen and (max-width: 500px) {
  .table-productAttributes .header,
  .table-productAttributes .table_row {
    grid-template-columns: 30px 30px 1fr 50px 40px 40px;
  }
}
.table-productAttributes .header .table_cell:nth-child(1) {
  padding: 3px 0 0 8px;
}
.table-productAttributes .row_wrap:nth-child(even) {
  background: #f8f8f8;
}
.table-productAttributes .table_row {
  cursor: pointer;
}

.mass_update_desc_modal .MuiPaper-root {
  min-width: 600px;
  padding: 20px;
}
@media only screen and (max-width: 1000px) {
  .mass_update_desc_modal .MuiPaper-root {
    min-width: unset;
  }
}
.mass_update_desc_modal .MuiPaper-root .MuiDialogTitle-root {
  padding: 0;
}
.mass_update_desc_modal .MuiPaper-root .MuiDialogContent-root {
  padding: 20px 0;
}
.mass_update_desc_modal .MuiPaper-root .MuiDialogActions-root {
  padding: 0;
}

.mass_update_description {
  position: relative;
}
.mass_update_description .dropdown_wrap {
  position: absolute;
  top: calc(100% + 5px);
  width: 200px;
  right: 0;
  background: #fff;
  z-index: 2;
  border-radius: 5px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 750px) {
  .mass_update_description .dropdown_wrap {
    right: unset;
    left: 0;
  }
}
.mass_update_description .dropdown_wrap ul li {
  width: 100%;
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.mass_update_description .dropdown_wrap ul li:hover {
  background: #f8f8f8;
}

.discounts_fields {
  margin: 60px 0 !important;
}
@media only screen and (min-width: 1000px) {
  .discounts_fields {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

.product_table_icons {
  display: flex;
  align-items: center;
}
.product_table_icons > .tooltip_wrap:nth-child(2) {
  margin: 0 5px;
}
.product_table_icons .linking_modal_wrap_button {
  position: relative;
}
.product_table_icons .linking_modal_wrap_button .linking_product_modal_wrapper {
  position: absolute;
  right: 0;
  top: 100%;
  background: #fff;
  z-index: 2;
  border-radius: 5px;
  width: 800px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 1000px) {
  .product_table_icons .linking_modal_wrap_button .linking_product_modal_wrapper {
    width: 600px;
  }
}
@media only screen and (max-width: 750px) {
  .product_table_icons .linking_modal_wrap_button .linking_product_modal_wrapper {
    width: 400px;
  }
}
.product_table_icons .linking_modal_wrap_button .linking_product_modal_wrapper .linking_product_modal_content {
  width: 100%;
  height: 100%;
}
.product_table_icons .linking_modal_wrap_button .linking_product_modal_wrapper .linking_product_modal_content .row {
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  align-items: center;
}
.product_table_icons .linking_modal_wrap_button .linking_product_modal_wrapper .linking_product_modal_content .row > div {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding: 10px 15px;
}
@media only screen and (max-width: 750px) {
  .product_table_icons .linking_modal_wrap_button .linking_product_modal_wrapper .linking_product_modal_content .row > div {
    font-size: 12px;
  }
}
.product_table_icons .linking_modal_wrap_button .linking_product_modal_wrapper .linking_product_modal_content .row.top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.product_table_icons .linking_modal_wrap_button .linking_product_modal_wrapper .linking_product_modal_content .row.top > div {
  font-weight: bold;
}

.table-product_create_category_attribute .header,
.table-product_create_category_attribute .table_row {
  display: flex;
  align-items: center;
}
.table-product_create_category_attribute .holder:nth-child(even) {
  background: #f8f8f8;
}
.table-product_create_category_attribute .table_row {
  cursor: pointer;
}
.table-product_create_category_attribute .values_table {
  background: #f1f1f1;
  padding-left: 50px;
  position: relative;
}
.table-product_create_category_attribute .values_table::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 24px;
  left: 24px;
  width: 2px;
  background: #e7e3e3;
}
.table-product_create_category_attribute .values_table .table_row {
  background: #f1f1f1 !important;
  cursor: auto;
  position: relative;
}
.table-product_create_category_attribute .values_table .table_row::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  left: -24px;
  height: 2px;
  background: #e7e3e3;
}
.table-product_create_category_attribute .values_table .table_row .table_cell:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.product_attribute_table_values {
  background: #f1f1f1;
  padding: 0 14px 0 50px;
  position: relative;
}
.product_attribute_table_values::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 24px;
  left: 24px;
  width: 2px;
  background: #e7e3e3;
}
.product_attribute_table_values .table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f1f1;
  position: relative;
}
.product_attribute_table_values .table_row .product_attribute_table_values .table_row :hover {
  background: #7367f0 !important;
}
.product_attribute_table_values .table_row .product_attribute_table_values .table_row :hover .table_cell {
  color: #fff;
}
.product_attribute_table_values .table_row::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  left: -24px;
  height: 2px;
  background: #e7e3e3;
}
.product_attribute_table_values .table_row .table_cell:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.products_create_attribute_form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 0 20px;
}

.product_brand_field {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0 5px;
}
.product_brand_field .row {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0 10px;
}
.product_brand_field .row .input_holder {
  position: relative;
}
.product_brand_field .row .input_holder .tooltip_wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  color: rgb(234, 84, 85);
  z-index: 4;
  width: 24px;
  height: 24px;
}

.products_list_of_images {
  margin-top: -50px;
}
.products_list_of_images .dropzone_wrapper {
  width: 100%;
}
.products_list_of_images .dropzone_wrapper.main_photo .photo {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  position: relative;
  height: 250px;
}
@media only screen and (max-width: 1600px) {
  .products_list_of_images .dropzone_wrapper.main_photo .photo {
    height: 200px;
  }
}
.products_list_of_images .dropzone_wrapper.main_photo .photo svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(234, 84, 85);
  font-size: 1rem;
  cursor: pointer;
  font-size: 30px;
}
@media only screen and (max-width: 1600px) {
  .products_list_of_images .dropzone_wrapper.main_photo .photo svg {
    font-size: 24px;
  }
}
.products_list_of_images .dropzone_wrapper .input_file_holder {
  height: 100px;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  position: relative;
  cursor: pointer;
  margin: 20px 0;
}
.products_list_of_images .dropzone_wrapper .input_file_holder input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.products_list_of_images .dropzone_wrapper .input_file_holder span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}
.products_list_of_images .dropzone_wrapper .photos_grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
@media only screen and (max-width: 500px) {
  .products_list_of_images .dropzone_wrapper .photos_grid {
    grid-template-columns: 100%;
  }
}
.products_list_of_images .dropzone_wrapper .photos_grid .photo {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  height: 250px;
  position: relative;
}
@media only screen and (max-width: 1600px) {
  .products_list_of_images .dropzone_wrapper .photos_grid .photo {
    height: 200px;
  }
}
@media only screen and (max-width: 500px) {
  .products_list_of_images .dropzone_wrapper .photos_grid .photo {
    height: 170px;
  }
}
.products_list_of_images .dropzone_wrapper .photos_grid .photo svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(234, 84, 85);
  font-size: 1rem;
  cursor: pointer;
  font-size: 30px;
}
@media only screen and (max-width: 1600px) {
  .products_list_of_images .dropzone_wrapper .photos_grid .photo svg {
    font-size: 24px;
  }
}
.products_list_of_images .upload_photos_row {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 25% calc(75% - 20px);
  grid-gap: 0 20px;
}
@media only screen and (max-width: 1000px) {
  .products_list_of_images .upload_photos_row {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}

.update_product_page_index .product_name {
  font-weight: 500;
  font-size: 18px;
  border-radius: 10px;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  margin-bottom: 20px;
}
.update_product_page_index .box_holder {
  position: relative;
  padding-top: 60px;
}
.update_product_page_index .box_holder .main_nav {
  border-bottom: 1px solid #ddd;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.update_product_page_index .box_holder .main_nav a {
  display: flex;
  align-items: center;
  color: #333e48;
  margin-right: 20px;
  height: 100%;
}
.update_product_page_index .box_holder .main_nav a.active {
  color: #E10770;
  border-bottom: 1px solid #E10770;
}
.update_product_page_index .box_holder .form_holder {
  padding-top: 20px;
}
.update_product_page_index .box_holder .form_holder .form_group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
@media only screen and (max-width: 1000px) {
  .update_product_page_index .box_holder .form_holder .form_group {
    grid-template-columns: 100%;
  }
}
.update_product_page_index .box_holder .form_holder .form_group.discounts_fields {
  margin: 0 0 20px 0 !important;
}
.update_product_page_index .box_holder .form_holder .form_buttons_group.three_buttons {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 20px;
  align-items: center;
  justify-content: flex-end;
}
.update_product_page_index .box_holder .products_list_of_images {
  margin: 0px;
}
.update_product_page_index .box_holder .products_list_of_images .upload_photos_row {
  margin-top: 0;
}
.update_product_page_index .box_holder .products_create_attribute_form {
  margin-top: 20px;
}
.update_product_page_index .create_value_modal_inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.update_product_page_index .create_value_modal_inner .create_value_modal_outer {
  padding: 50px 20px;
  width: 600px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  justify-content: center;
}
.update_product_page_index .create_value_modal_inner .create_value_modal_outer .inline_update_form {
  position: static;
  top: unset;
  left: unset;
  height: unset;
  grid-template-columns: 300px auto;
  padding: 0;
  border: 0;
}
.update_product_page_index .create_value_modal_inner .create_value_modal_outer .inline_update_form .input_holder {
  width: 100% !important;
}
.update_product_page_index .create_value_modal_inner .create_value_modal_outer .add_value_dropdown {
  display: grid;
  grid-template-columns: 300px auto;
  align-items: center;
  grid-gap: 20px;
}
.update_product_page_index .skeleton_inner .discounts_row {
  display: grid;
  grid-template-columns: repeat(3, 1fr) !important;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1000px) {
  .update_product_page_index .skeleton_inner .discounts_row {
    grid-template-columns: 100% !important;
  }
}
.update_product_page_index .skeleton_inner .dropzones {
  display: grid;
  grid-template-columns: 25% calc(75% - 20px);
  grid-gap: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1000px) {
  .update_product_page_index .skeleton_inner .dropzones {
    grid-template-columns: 100% !important;
  }
}
.update_product_page_index .skeleton_inner .dropzones .dropzone {
  border-radius: 10px;
  width: 100%;
  height: 100px;
}
.update_product_page_index .skeleton_inner .attributes_rows .input {
  margin-bottom: 20px;
}

.cms_product_attributes_table .header {
  background: #e7e3e3;
}
.cms_product_attributes_table .table_row {
  position: relative;
  cursor: pointer;
}
.cms_product_attributes_table .content:nth-child(even) {
  background: #f8f8f8;
}
.cms_product_attributes_table .content :hover:not(.product_attribute_table_values) {
  background: #7367f0;
}
.cms_product_attributes_table .content :hover:not(.product_attribute_table_values) .table_cell {
  color: #fff;
}
.cms_product_attributes_table .header,
.cms_product_attributes_table .table_row {
  height: 50px;
  padding: 0 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 50px;
}
.cms_product_attributes_table .table_cell {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 1600px) {
  .cms_product_attributes_table .table_cell {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .cms_product_attributes_table .table_cell {
    font-size: 11px;
  }
}
.cms_product_attributes_table .product_attribute_table_values .table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pass_products_dialog .MuiDialog-paperScrollPaper {
  padding: 50px 20px 10px 20px;
  overflow: unset;
}
.pass_products_dialog .MuiDialog-paperScrollPaper .search_select_wrapper {
  width: 400px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 700px) {
  .pass_products_dialog .MuiDialog-paperScrollPaper .search_select_wrapper {
    width: 300px;
  }
}
@media only screen and (max-width: 400px) {
  .pass_products_dialog .MuiDialog-paperScrollPaper .search_select_wrapper {
    width: 260px;
  }
}
.pass_products_dialog .MuiDialog-paperScrollPaper button {
  margin-bottom: 10px;
  width: 100%;
}
.pass_products_dialog .list_of_attributes h3 {
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}
.pass_products_dialog .list_of_attributes ul li {
  background: #f8f8f8;
  border-bottom: 1px solid #fff;
  padding: 7px 10px;
  font-size: 14px;
}
.pass_products_dialog .list_of_attributes ul li:last-child {
  border-bottom: 0;
}

.table-ok-nok .header,
.table-ok-nok .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 10% 30% 20% 20% 20%;
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok .header,
  .table-ok-nok .table_row {
    grid-template-column: 10% 30% 20% 20% 20%;
  }
}
@media only screen and (max-width: 1400px) {
  .table-ok-nok .header,
  .table-ok-nok .table_row {
    grid-template-column: 10% 30% 20% 20% 20%;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ok-nok .header,
  .table-ok-nok .table_row {
    grid-template-columns: 50px 150px 1fr 250px 100px;
  }
}
@media only screen and (max-width: 750px) {
  .table-ok-nok .header,
  .table-ok-nok .table_row {
    grid-template-columns: 50px 1fr 100px;
  }
}
@media only screen and (max-width: 500px) {
  .table-ok-nok .header,
  .table-ok-nok .table_row {
    grid-template-columns: 50px 1fr;
  }
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok .header .table_cell {
    display: none;
  }
  .table-ok-nok .header .table_cell:nth-child(1) {
    display: flex !important;
  }
  .table-ok-nok .header .table_cell:nth-child(2) {
    display: flex !important;
  }
  .table-ok-nok .header .table_cell:nth-child(3) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 750px) {
  .table-ok-nok .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok .header .table_cell:nth-child(4) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-ok-nok .header .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok .header .table_cell:nth-child(5) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-ok-nok .header .table_cell:nth-child(5) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok .header .table_cell:nth-child(6) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-ok-nok .header .table_cell:nth-child(6) {
    display: none !important;
  }
}
.table-ok-nok .table_row {
  cursor: pointer;
}
.table-ok-nok .table_row .table_cell.p15-0 a {
  height: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok .table_row {
    height: unset !important;
    padding: 8px 0px !important;
  }
  .table-ok-nok .table_row .expand_button {
    display: block;
  }
}
.table-ok-nok .table_row .table_cell:last-child {
  overflow: unset !important;
}
.table-ok-nok .content {
  max-height: 350px;
  overflow: auto;
}

.table-ok-nokAttributes .header,
.table-ok-nokAttributes .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 10% 30% 20% 20% 20%;
}
@media only screen and (max-width: 500px) {
  .table-ok-nokAttributes .header,
  .table-ok-nokAttributes .table_row {
    grid-template-columns: 10% 30% 20% 20% 20%;
  }
}
.table-ok-nokAttributes .header .table_cell:nth-child(1) {
  padding: 3px 0 0 8px;
}
.table-ok-nokAttributes .row_wrap:nth-child(even) {
  background: #f8f8f8;
}
.table-ok-nokAttributes .table_row {
  cursor: pointer;
}

.ok-nok_attributes_table .header {
  background: #e7e3e3;
}
.ok-nok_attributes_table .table_row {
  position: relative;
  cursor: pointer;
}
.ok-nok_attributes_table .content:nth-child(even) {
  background: #f8f8f8;
}
.ok-nok_attributes_table .content :hover:not(.product_attribute_table_values) {
  background: #7367f0;
}
.ok-nok_attributes_table .content :hover:not(.product_attribute_table_values) .table_cell {
  color: #fff;
}
.ok-nok_attributes_table .header,
.ok-nok_attributes_table .table_row {
  height: 50px;
  padding: 0 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 50px;
}
.ok-nok_attributes_table .table_cell {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 1600px) {
  .ok-nok_attributes_table .table_cell {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .ok-nok_attributes_table .table_cell {
    font-size: 11px;
  }
}
.ok-nok_attributes_table .product_attribute_table_values .table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-ok-nok2 .header,
.table-ok-nok2 .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 40% 20% 20% 20%;
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok2 .header,
  .table-ok-nok2 .table_row {
    grid-template-columns: 40% 20% 20% 20%;
  }
}
@media only screen and (max-width: 1400px) {
  .table-ok-nok2 .header,
  .table-ok-nok2 .table_row {
    grid-template-columns: 40% 20% 20% 20%;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ok-nok2 .header,
  .table-ok-nok2 .table_row {
    grid-template-columns: 40% 20% 20% 20%;
  }
}
@media only screen and (max-width: 750px) {
  .table-ok-nok2 .header,
  .table-ok-nok2 .table_row {
    grid-template-columns: 40% 20% 20% 20%;
  }
}
@media only screen and (max-width: 500px) {
  .table-ok-nok2 .header,
  .table-ok-nok2 .table_row {
    grid-template-columns: 40% 20% 20% 20%;
  }
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok2 .header .table_cell {
    display: none;
  }
  .table-ok-nok2 .header .table_cell:nth-child(1) {
    display: flex !important;
  }
  .table-ok-nok2 .header .table_cell:nth-child(2) {
    display: flex !important;
  }
  .table-ok-nok2 .header .table_cell:nth-child(3) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 750px) {
  .table-ok-nok2 .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok2 .header .table_cell:nth-child(4) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-ok-nok2 .header .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok2 .header .table_cell:nth-child(5) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-ok-nok2 .header .table_cell:nth-child(5) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok2 .header .table_cell:nth-child(6) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-ok-nok2 .header .table_cell:nth-child(6) {
    display: none !important;
  }
}
.table-ok-nok2 .table_row {
  cursor: pointer;
}
.table-ok-nok2 .table_row .table_cell.p15-0 a {
  height: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1800px) {
  .table-ok-nok2 .table_row {
    height: unset !important;
    padding: 8px !important;
  }
  .table-ok-nok2 .table_row .expand_button {
    display: block;
  }
}
.table-ok-nok2 .table_row .table_cell:last-child {
  overflow: unset !important;
}
.table-ok-nok2 .content {
  max-height: 350px;
  overflow: auto;
}

.table-ok-nok2Attributes .header,
.table-ok-nok2Attributes .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 40% 20% 20% 20%;
}
@media only screen and (max-width: 500px) {
  .table-ok-nok2Attributes .header,
  .table-ok-nok2Attributes .table_row {
    grid-template-columns: 40% 20% 20% 20%;
  }
}
.table-ok-nok2Attributes .header .table_cell:nth-child(1) {
  padding: 3px 0 0 8px;
}
.table-ok-nok2Attributes .row_wrap:nth-child(even) {
  background: #f8f8f8;
}
.table-ok-nok2Attributes .table_row {
  cursor: pointer;
}

.ok-nok2_attributes_table .header {
  background: #e7e3e3;
}
.ok-nok2_attributes_table .table_row {
  position: relative;
  cursor: pointer;
}
.ok-nok2_attributes_table .content:nth-child(even) {
  background: #f8f8f8;
}
.ok-nok2_attributes_table .content :hover:not(.product_attribute_table_values) {
  background: #7367f0;
}
.ok-nok2_attributes_table .content :hover:not(.product_attribute_table_values) .table_cell {
  color: #fff;
}
.ok-nok2_attributes_table .header,
.ok-nok2_attributes_table .table_row {
  height: 50px;
  padding: 0 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 50px;
}
.ok-nok2_attributes_table .table_cell {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 1600px) {
  .ok-nok2_attributes_table .table_cell {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .ok-nok2_attributes_table .table_cell {
    font-size: 11px;
  }
}
.ok-nok2_attributes_table .product_attribute_table_values .table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 750px) {
  .product_page_wrap_eoffer .table_helper .align_row:last-child {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .product_page_wrap_eoffer .table_helper .align_row .table_helper_icons {
    margin-top: 20px;
  }
}

.table-eoffer .header,
.table-eoffer .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 50px 200px 1fr 250px 100px 100px;
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .header,
  .table-eoffer .table_row {
    grid-template-columns: 50px 200px 1fr 250px 100px 100px;
  }
}
@media only screen and (max-width: 1400px) {
  .table-eoffer .header,
  .table-eoffer .table_row {
    grid-template-columns: 50px 150px 1fr 200px 100px 100px;
  }
}
@media only screen and (max-width: 1000px) {
  .table-eoffer .header,
  .table-eoffer .table_row {
    grid-template-columns: 50px 150px 1fr 250px 100px;
  }
}
@media only screen and (max-width: 750px) {
  .table-eoffer .header,
  .table-eoffer .table_row {
    grid-template-columns: 50px 1fr 100px;
  }
}
@media only screen and (max-width: 500px) {
  .table-eoffer .header,
  .table-eoffer .table_row {
    grid-template-columns: 50px 1fr;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .header {
    padding-left: 45px !important;
  }
  .table-eoffer .header .table_cell {
    display: none;
  }
  .table-eoffer .header .table_cell:nth-child(1) {
    display: flex !important;
  }
  .table-eoffer .header .table_cell:nth-child(2) {
    display: flex !important;
  }
  .table-eoffer .header .table_cell:nth-child(3) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 750px) {
  .table-eoffer .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .header .table_cell:nth-child(4) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-eoffer .header .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .header .table_cell:nth-child(5) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-eoffer .header .table_cell:nth-child(5) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .header .table_cell:nth-child(6) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-eoffer .header .table_cell:nth-child(6) {
    display: none !important;
  }
}
.table-eoffer .table_row {
  cursor: pointer;
}
.table-eoffer .table_row .table_cell.p15-0 {
  height: 45px;
}
.table-eoffer .table_row .table_cell.p15-0 a {
  height: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 8px 10px !important;
  }
  .table-eoffer .table_row .expand_button {
    display: block;
  }
  .table-eoffer .table_row .table_cell {
    display: none;
  }
  .table-eoffer .table_row .table_cell:nth-child(2) {
    display: flex !important;
    width: 40px !important;
    margin: 0 10px !important;
  }
  .table-eoffer .table_row .table_cell:nth-child(3) {
    display: flex !important;
    width: 200px !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-eoffer .table_row .table_cell:nth-child(3) {
    width: 150px !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 750px) {
  .table-eoffer .table_row .table_cell:nth-child(3) {
    width: unset !important;
    flex: 1;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .table_cell:nth-child(4) {
    display: flex !important;
    flex: 1;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 750px) {
  .table-eoffer .table_row .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .table_cell:nth-child(5) {
    display: flex !important;
    width: 250px !important;
  }
  .table-eoffer .table_row .table_cell:nth-child(5) p {
    padding-right: 10px;
    overflow: hidden;
    width: 90%;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-eoffer .table_row .table_cell:nth-child(5) {
    width: 200px !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-eoffer .table_row .table_cell:nth-child(5) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .table_cell:nth-child(6) {
    display: flex !important;
    width: 100px !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1000px) {
  .table-eoffer .table_row .table_cell:nth-child(6) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .table_cell:nth-child(7) {
    display: flex !important;
    width: 100px !important;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-eoffer .table_row .table_cell:nth-child(7) {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .product_table_icons {
    width: 100px;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 500px) {
  .table-eoffer .table_row .product_table_icons {
    display: none !important;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper {
    display: block;
  }
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 120px;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 500px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 105px;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .linking_products_mobile .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .table-eoffer .table_row .mobile_table_wrapper .linking_products_mobile .row.top {
    font-weight: bold;
    background: rgba(0, 0, 0, 0.05);
  }
  .table-eoffer .table_row .mobile_table_wrapper .linking_products_mobile .row > div {
    padding: 5px;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 950px) {
  .table-eoffer .table_row .mobile_table_wrapper .linking_products_mobile .row > div {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 600px) {
  .table-eoffer .table_row .mobile_table_wrapper .linking_products_mobile .row > div {
    font-size: 10px;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 750px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(4) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(4) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(5) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1400px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(5) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(9) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(9) {
    display: grid;
  }
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(9) .row_content .table_cell {
    display: block !important;
    font-size: 12px;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(10) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 500px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(10) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(11) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 500px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(11) {
    display: grid;
  }
}
@media only screen and (max-width: 1800px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(12) {
    display: none;
  }
}
@media only screen and (max-width: 1800px) and (max-width: 500px) {
  .table-eoffer .table_row .mobile_table_wrapper .mobile_table_row:nth-child(12) {
    display: grid;
  }
}
.table-eoffer .table_row .table_cell:last-child {
  overflow: unset !important;
}

.table-users .header,
.table-users .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 400px 300px 1fr 70px;
}
@media only screen and (max-width: 1100px) {
  .table-users .header,
  .table-users .table_row {
    grid-template-columns: 1fr 1fr 200px 70px;
  }
}
@media only screen and (max-width: 900px) {
  .table-users .header,
  .table-users .table_row {
    grid-template-columns: 1fr 1fr 70px;
  }
}
@media only screen and (max-width: 750px) {
  .table-users .header,
  .table-users .table_row {
    grid-template-columns: 1fr 70px;
  }
}
@media only screen and (max-width: 500px) {
  .table-users .header,
  .table-users .table_row {
    grid-template-columns: 1fr 60px;
  }
}
@media only screen and (max-width: 900px) {
  .table-users .header {
    padding-left: 45px !important;
  }
  .table-users .header .table_cell {
    display: none;
  }
  .table-users .header .table_cell:first-child {
    display: flex !important;
  }
  .table-users .header .table_cell:nth-child(2) {
    display: flex !important;
  }
}
@media only screen and (max-width: 900px) and (max-width: 750px) {
  .table-users .header .table_cell:nth-child(2) {
    display: none !important;
  }
}
@media only screen and (max-width: 900px) {
  .table-users .header .table_cell:nth-child(4) {
    display: flex !important;
  }
}
@media only screen and (max-width: 900px) {
  .table-users .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 8px 10px !important;
  }
  .table-users .table_row .expand_button {
    display: block;
  }
  .table-users .table_row .table_cell {
    display: none;
  }
  .table-users .table_row .table_cell:nth-child(2) {
    margin-left: 10px;
    display: flex !important;
    flex: 1;
  }
  .table-users .table_row .table_cell:nth-child(2) a {
    width: 100% !important;
  }
  .table-users .table_row .table_cell:nth-child(3) {
    display: flex !important;
    flex: 1;
  }
}
@media only screen and (max-width: 900px) and (max-width: 750px) {
  .table-users .table_row .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 900px) {
  .table-users .table_row .table_cell:nth-child(5) {
    display: flex !important;
    width: 70px !important;
  }
}
@media only screen and (max-width: 900px) and (max-width: 500px) {
  .table-users .table_row .table_cell:nth-child(5) {
    width: 60px !important;
  }
}
@media only screen and (max-width: 900px) {
  .table-users .mobile_table_wrapper {
    display: block;
  }
  .table-users .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 900px) and (max-width: 750px) {
  .table-users .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}

.user_avatar_part .error_msg {
  text-align: center;
}

.user_avatar_section {
  display: flex;
  width: 190px;
  height: 190px;
  position: relative;
  border-radius: 8px;
  background: #f8f8f8;
}
@media only screen and (max-width: 1000px) {
  .user_avatar_section {
    width: 160px;
    height: 160px;
  }
}
.user_avatar_section .avatar {
  width: 100%;
  height: 100%;
}
.user_avatar_section:hover .photo {
  opacity: 1;
}
.user_avatar_section input[type=file] {
  font-size: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.user_avatar_section .photo {
  opacity: 0;
  transition: all 0.2s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
}
@media only screen and (max-width: 800px) {
  .user_avatar_section .photo {
    opacity: 1;
  }
}
.user_avatar_section .photo .trash {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  width: 40px;
  height: 40px;
}
@media only screen and (max-width: 800px) {
  .user_avatar_section .photo .trash {
    width: 30px;
    height: 30px;
    top: 3px;
    right: 3px;
  }
}
.user_avatar_section .photo .trash svg {
  color: rgb(234, 84, 85);
  font-size: 1.8rem;
}
@media only screen and (max-width: 800px) {
  .user_avatar_section .photo .trash svg {
    font-size: 1.5rem;
  }
}
.user_avatar_section svg {
  color: #fff;
  font-size: 2.8em;
}
@media only screen and (max-width: 800px) {
  .user_avatar_section svg {
    font-size: 2rem;
  }
}

.users_form_row {
  display: grid;
  grid-template-columns: 190px 1fr;
  grid-gap: 0 50px;
}
@media only screen and (max-width: 1000px) {
  .users_form_row {
    grid-template-columns: 160px 1fr;
    grid-gap: 0 30px;
  }
}
@media only screen and (max-width: 800px) {
  .users_form_row {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
  .users_form_row .form_group {
    grid-gap: 30px 0 !important;
  }
}
.users_form_row .form_group {
  grid-template-columns: 100% !important;
}

.create_user_page .dropdown_inner {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  position: relative;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}
.create_user_page .dropdown_inner.active .dropdown_outer svg {
  transform: rotate(180deg);
}
.create_user_page .dropdown_inner .dropdown_outer {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.create_user_page .dropdown_inner .dropdown_outer p {
  color: #333e48;
}
.create_user_page .dropdown_inner .dropdown_outer svg {
  transform: rotate(0);
  transition: all 0.2s ease;
}
.create_user_page .dropdown_inner .dropdown_items {
  position: absolute;
  left: 0;
  right: 0;
  top: 110%;
  background: #fff;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.create_user_page .dropdown_inner .dropdown_items li {
  padding: 5px 20px;
  font-size: 16px;
  cursor: pointer;
}
.create_user_page .dropdown_inner .dropdown_items li.active {
  color: #333e48;
  background: #f8f8f8;
}

.table-activityLog .header,
.table-activityLog .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 80px 300px 1fr 160px;
}
@media only screen and (max-width: 900px) {
  .table-activityLog .header,
  .table-activityLog .table_row {
    grid-template-columns: 80px 1fr 1fr 160px;
  }
}
@media only screen and (max-width: 800px) {
  .table-activityLog .header,
  .table-activityLog .table_row {
    grid-template-columns: 75px 150px 1fr;
  }
}
@media only screen and (max-width: 650px) {
  .table-activityLog .header,
  .table-activityLog .table_row {
    grid-template-columns: 75px 1fr;
  }
}
@media only screen and (max-width: 800px) {
  .table-activityLog .header {
    padding-left: 40px !important;
  }
  .table-activityLog .header .table_cell {
    display: none;
  }
  .table-activityLog .header .table_cell:nth-child(1) {
    display: flex !important;
  }
  .table-activityLog .header .table_cell:nth-child(2) {
    display: flex !important;
  }
  .table-activityLog .header .table_cell:nth-child(3) {
    display: flex !important;
  }
}
@media only screen and (max-width: 800px) and (max-width: 650px) {
  .table-activityLog .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
.table-activityLog .table_row {
  cursor: pointer;
}
@media only screen and (max-width: 800px) {
  .table-activityLog .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 8px 10px !important;
  }
  .table-activityLog .table_row .expand_button {
    display: block;
  }
  .table-activityLog .table_row .table_cell {
    display: none;
  }
  .table-activityLog .table_row .table_cell:nth-child(2) {
    display: flex !important;
    width: 40px !important;
    margin: 0 20px !important;
  }
  .table-activityLog .table_row .table_cell:nth-child(3) {
    width: 150px !important;
    display: flex !important;
  }
  .table-activityLog .table_row .table_cell:nth-child(4) {
    width: 250px !important;
    display: flex !important;
  }
}
@media only screen and (max-width: 800px) and (max-width: 650px) {
  .table-activityLog .table_row .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 800px) {
  .table-activityLog .table_row .mobile_table_wrapper {
    display: block;
  }
  .table-activityLog .table_row .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 70px;
  }
  .table-activityLog .table_row .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 800px) and (max-width: 650px) {
  .table-activityLog .table_row .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}
.table-activityLog .table_row .table_cell {
  overflow: unset !important;
}

.activity_log_preview_modal {
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activity_log_preview_modal .content_holder {
  width: 750px;
  max-height: 90%;
  background: #fff;
  position: relative;
  overflow-y: scroll;
  border-radius: 0.5em;
  padding: 40px 20px 20px 20px;
}
@media only screen and (max-width: 900px) {
  .activity_log_preview_modal .content_holder {
    width: 90%;
  }
}
@media only screen and (max-width: 600px) {
  .activity_log_preview_modal .content_holder {
    width: 95%;
    padding: 40px 10px 10px 10px;
  }
}
.activity_log_preview_modal .content_holder::-webkit-scrollbar {
  width: 0;
}
.activity_log_preview_modal .content_holder .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #8a8d90;
}
.activity_log_preview_modal .content_holder .item {
  display: grid;
  align-items: center;
  grid-template-columns: 170px 1fr;
  grid-gap: 0 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  color: #8a8d90;
  font-size: 12px;
  min-height: 45px;
}
@media only screen and (max-width: 600px) {
  .activity_log_preview_modal .content_holder .item {
    grid-template-columns: 140px 1fr;
    grid-gap: 0 10px;
  }
}
@media only screen and (max-width: 400px) {
  .activity_log_preview_modal .content_holder .item {
    grid-template-columns: 120px 1fr;
  }
}
.activity_log_preview_modal .content_holder .item .row {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 900px) {
  .activity_log_preview_modal .content_holder .item {
    padding: 0 10px;
  }
}
.activity_log_preview_modal .content_holder .item.b_0 span {
  border-right: 0;
}
.activity_log_preview_modal .content_holder .item:last-child {
  border-bottom: 0;
}
.activity_log_preview_modal .content_holder .item .avatar {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 8px;
}
.activity_log_preview_modal .content_holder .item a,
.activity_log_preview_modal .content_holder .item .link {
  cursor: pointer;
  color: #E10770;
  font-size: 12px;
  font-style: italic;
}
@media only screen and (max-width: 600px) {
  .activity_log_preview_modal .content_holder .item a,
  .activity_log_preview_modal .content_holder .item .link {
    font-size: 11px;
  }
}
@media only screen and (max-width: 350px) {
  .activity_log_preview_modal .content_holder .item a,
  .activity_log_preview_modal .content_holder .item .link {
    font-size: 10px;
  }
}
.activity_log_preview_modal .content_holder .item span {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 12px;
  font-weight: 600;
}
@media only screen and (max-width: 600px) {
  .activity_log_preview_modal .content_holder .item span {
    font-size: 11px;
  }
}
@media only screen and (max-width: 350px) {
  .activity_log_preview_modal .content_holder .item span {
    font-size: 10px;
  }
}
.activity_log_preview_modal .content_holder .item .right {
  width: 100%;
  padding: 5px 0;
}
.activity_log_preview_modal .content_holder .item .right .skeleton_inner {
  width: 100%;
}
.activity_log_preview_modal .content_holder .item .skeleton_inner {
  margin: 0;
  width: 100%;
}
.activity_log_preview_modal .content_holder .item .skeleton_inner .skeleton_wrapper {
  width: 40%;
  padding: 0;
}

.activity_log_dropdown_wrap {
  width: 250px;
  height: 40px;
  border: 1px solid #dae1e7;
  border-radius: 6px;
  position: relative;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
}
@media only screen and (max-width: 500px) {
  .activity_log_dropdown_wrap {
    width: calc(100% - 20px);
  }
}
.activity_log_dropdown_wrap .dropdown_holder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  cursor: pointer;
}
.activity_log_dropdown_wrap .dropdown_holder .active {
  color: #333e48;
}
.activity_log_dropdown_wrap .dropdown_holder span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activity_log_dropdown_wrap .dropdown_holder span svg {
  width: 20px;
  height: 20px;
}
.activity_log_dropdown_wrap ul.dropdown_items {
  position: absolute;
  left: 0;
  right: 0;
  top: 110%;
  background: #fff;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.activity_log_dropdown_wrap ul.dropdown_items li {
  padding: 8px 20px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  font-size: 13px;
  background: #fff;
  transition: all 0.2s ease;
}
.activity_log_dropdown_wrap ul.dropdown_items li:hover {
  background: #f8f8f8;
}
.activity_log_dropdown_wrap ul.dropdown_items li.active {
  background: #f8f8f8;
  color: #333e48;
}

@media only screen and (max-width: 850px) {
  .activty_log .table_helper .align_row:nth-child(1) {
    width: 100%;
    flex-direction: column;
    align-items: start;
  }
  .activty_log .table_helper .align_row:nth-child(1) .paginate {
    margin-bottom: 20px;
  }
}
.activty_log .table_helper .align_row:nth-child(1) .filters {
  display: grid;
  grid-template-columns: repeat(2, 250px);
  grid-gap: 0 20px;
}
@media only screen and (max-width: 850px) {
  .activty_log .table_helper .align_row:nth-child(1) .filters {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 700px) {
  .activty_log .table_helper .align_row:nth-child(1) .filters {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}
.activty_log .table_helper .align_row:nth-child(1) .filters .activity_log_dropdown_wrap {
  width: 100%;
}
.activty_log .table_helper .align_row:nth-child(1) .filters .search_select_holder {
  border: 1px solid #dae1e7;
  border-radius: 6px;
  position: relative;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
}
.activty_log .table_helper .align_row:nth-child(1) .filters .search_select_holder .root span.icon {
  font-size: 13px;
}
.activty_log .table_helper .align_row:nth-child(2) {
  display: none;
}

.suppliers_screen .table-suppliers .header,
.suppliers_screen .table-suppliers .table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.suppliers_screen .table-suppliers .header > .table_cell:last-child,
.suppliers_screen .table-suppliers .table_row > .table_cell:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 500px) {
  .suppliers_screen .table_helper .align_row .table_inline_form {
    width: calc(100% - 100px);
  }
  .suppliers_screen .table_helper .align_row .table_inline_form .input_holder {
    width: calc(100% - 65px);
  }
  .suppliers_screen .table_helper .align_row .table_inline_form button {
    margin-left: 10px;
  }
}

.brands_screen .table-brands .header,
.brands_screen .table-brands .table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.brands_screen .table-brands .header > .table_cell:last-child,
.brands_screen .table-brands .table_row > .table_cell:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 500px) {
  .brands_screen .table_helper .align_row .table_inline_form {
    width: calc(100% - 100px);
  }
  .brands_screen .table_helper .align_row .table_inline_form .input_holder {
    width: calc(100% - 65px);
  }
  .brands_screen .table_helper .align_row .table_inline_form button {
    margin-left: 10px;
  }
}

.attributes_screen .table-attributes .header,
.attributes_screen .table-attributes .table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.attributes_screen .table-attributes .header > .table_cell:last-child,
.attributes_screen .table-attributes .table_row > .table_cell:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 500px) {
  .attributes_screen .table_helper .align_row .table_inline_form {
    width: calc(100% - 100px);
  }
  .attributes_screen .table_helper .align_row .table_inline_form .input_holder {
    width: calc(100% - 65px);
  }
  .attributes_screen .table_helper .align_row .table_inline_form button {
    margin-left: 10px;
  }
}

.table-authDiscounts .header,
.table-authDiscounts .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 100px 100px 100px 70px;
}
@media only screen and (max-width: 1400px) {
  .table-authDiscounts .header,
  .table-authDiscounts .table_row {
    grid-template-columns: 1fr 1fr 1fr 1fr 70px 70px 70px 70px;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .header,
  .table-authDiscounts .table_row {
    grid-template-columns: 1fr 1fr 1fr 1fr 70px 70px;
  }
}
@media only screen and (max-width: 1000px) {
  .table-authDiscounts .header,
  .table-authDiscounts .table_row {
    grid-template-columns: 200px 1fr 70px 70px;
  }
}
@media only screen and (max-width: 800px) {
  .table-authDiscounts .header,
  .table-authDiscounts .table_row {
    grid-template-columns: 130px 1fr 70px 70px;
  }
}
@media only screen and (max-width: 650px) {
  .table-authDiscounts .header,
  .table-authDiscounts .table_row {
    grid-template-columns: 1fr 70px 70px;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .header {
    padding-left: 45px !important;
  }
  .table-authDiscounts .header .table_cell {
    display: none;
  }
  .table-authDiscounts .header .table_cell:first-child {
    display: flex !important;
  }
  .table-authDiscounts .header .table_cell:nth-child(2) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 650px) {
  .table-authDiscounts .header .table_cell:nth-child(2) {
    display: none !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .header .table_cell:nth-child(3) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-authDiscounts .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .header .table_cell:nth-child(4) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-authDiscounts .header .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .header .table_cell:nth-child(7) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 8px 10px !important;
  }
  .table-authDiscounts .table_row .expand_button {
    display: block;
  }
  .table-authDiscounts .table_row .table_cell {
    display: none;
    padding-left: 5px;
  }
  .table-authDiscounts .table_row .table_cell:nth-child(2) {
    display: flex !important;
    flex: 1;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-authDiscounts .table_row .table_cell:nth-child(2) {
    flex: unset !important;
    width: 200px !important;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 800px) {
  .table-authDiscounts .table_row .table_cell:nth-child(2) {
    width: 130px !important;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 650px) {
  .table-authDiscounts .table_row .table_cell:nth-child(2) {
    flex: 1 !important;
    width: auto !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .table_row .table_cell:nth-child(3) {
    display: flex !important;
    flex: 1;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 650px) {
  .table-authDiscounts .table_row .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .table_row .table_cell:nth-child(4) {
    display: flex !important;
    flex: 1;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-authDiscounts .table_row .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .table_row .table_cell:nth-child(5) {
    display: flex !important;
    flex: 1;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-authDiscounts .table_row .table_cell:nth-child(5) {
    display: none !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .table_row .table_cell:nth-child(8) {
    display: flex !important;
    width: 70px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .table_row .table_cell:nth-child(9) {
    display: flex !important;
    width: 70px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .mobile_table_wrapper {
    display: block;
  }
  .table-authDiscounts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 70px;
  }
  .table-authDiscounts .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 650px) {
  .table-authDiscounts .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-authDiscounts .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: grid;
  }
}
@media only screen and (max-width: 1200px) {
  .table-authDiscounts .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: none;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-authDiscounts .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: grid;
  }
}

.table-ourSuppliers .header,
.table-ourSuppliers .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 25% 25% 25% 25%;
}
@media only screen and (max-width: 1400px) {
  .table-ourSuppliers .header,
  .table-ourSuppliers .table_row {
    grid-template-columns: 25% 25% 25% 25%;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliers .header,
  .table-ourSuppliers .table_row {
    grid-template-columns: 25% 25% 25% 25%;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ourSuppliers .header,
  .table-ourSuppliers .table_row {
    grid-template-columns: 25% 25% 25% 25%;
  }
}
@media only screen and (max-width: 800px) {
  .table-ourSuppliers .header,
  .table-ourSuppliers .table_row {
    grid-template-columns: 25% 25% 25% 25%;
  }
}
@media only screen and (max-width: 650px) {
  .table-ourSuppliers .header,
  .table-ourSuppliers .table_row {
    grid-template-columns: 25% 25% 25% 25%;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliers .header .table_cell {
    width: 25%;
    display: flex !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliers .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 8px 10px !important;
  }
  .table-ourSuppliers .table_row .expand_button {
    display: block;
  }
  .table-ourSuppliers .table_row .table_cell {
    flex: 1;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-ourSuppliers .table_row .table_cell {
    width: 25%;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 800px) {
  .table-ourSuppliers .table_row .table_cell {
    width: 25%;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 650px) {
  .table-ourSuppliers .table_row .table_cell {
    width: 25%;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliers .mobile_table_wrapper {
    display: block;
  }
  .table-ourSuppliers .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 70px;
  }
  .table-ourSuppliers .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 650px) {
  .table-ourSuppliers .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliers .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-ourSuppliers .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: grid;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliers .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: none;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-ourSuppliers .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: grid;
  }
}

.table-ourSuppliersProducts .header,
.table-ourSuppliersProducts .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 20% 20% 20% 20% 20%;
}
@media only screen and (max-width: 1400px) {
  .table-ourSuppliersProducts .header,
  .table-ourSuppliersProducts .table_row {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliersProducts .header,
  .table-ourSuppliersProducts .table_row {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ourSuppliersProducts .header,
  .table-ourSuppliersProducts .table_row {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
}
@media only screen and (max-width: 800px) {
  .table-ourSuppliersProducts .header,
  .table-ourSuppliersProducts .table_row {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
}
@media only screen and (max-width: 650px) {
  .table-ourSuppliersProducts .header,
  .table-ourSuppliersProducts .table_row {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliersProducts .header .table_cell {
    width: 25%;
    display: flex !important;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliersProducts .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 8px 10px !important;
  }
  .table-ourSuppliersProducts .table_row .expand_button {
    display: block;
  }
  .table-ourSuppliersProducts .table_row .table_cell {
    flex: 1;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-ourSuppliersProducts .table_row .table_cell {
    width: 25%;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 800px) {
  .table-ourSuppliersProducts .table_row .table_cell {
    width: 25%;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 650px) {
  .table-ourSuppliersProducts .table_row .table_cell {
    width: 25%;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliersProducts .mobile_table_wrapper {
    display: block;
  }
  .table-ourSuppliersProducts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 70px;
  }
  .table-ourSuppliersProducts .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 650px) {
  .table-ourSuppliersProducts .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliersProducts .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-ourSuppliersProducts .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: grid;
  }
}
@media only screen and (max-width: 1200px) {
  .table-ourSuppliersProducts .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: none;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 1000px) {
  .table-ourSuppliersProducts .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: grid;
  }
}

.table-webMargins .header,
.table-webMargins .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 150px 150px 150px 70px;
}
@media only screen and (max-width: 1400px) {
  .table-webMargins .header,
  .table-webMargins .table_row {
    grid-template-columns: 1fr 1fr 1fr 100px 100px 100px 70px;
  }
}
@media only screen and (max-width: 1000px) {
  .table-webMargins .header,
  .table-webMargins .table_row {
    grid-template-columns: 1fr 1fr 1fr 70px;
  }
}
@media only screen and (max-width: 850px) {
  .table-webMargins .header,
  .table-webMargins .table_row {
    grid-template-columns: 1fr 1fr 70px;
  }
}
@media only screen and (max-width: 700px) {
  .table-webMargins .header,
  .table-webMargins .table_row {
    grid-template-columns: 1fr 70px;
  }
}
@media only screen and (max-width: 1000px) {
  .table-webMargins .header {
    padding-left: 45px !important;
  }
  .table-webMargins .header .table_cell {
    display: none;
  }
  .table-webMargins .header .table_cell:first-child {
    display: flex !important;
  }
  .table-webMargins .header .table_cell:nth-child(2) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 700px) {
  .table-webMargins .header .table_cell:nth-child(2) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-webMargins .header .table_cell:nth-child(3) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 850px) {
  .table-webMargins .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-webMargins .header .table_cell:nth-child(7) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-webMargins .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 15px 10px !important;
  }
  .table-webMargins .table_row .expand_button {
    display: block;
  }
  .table-webMargins .table_row .table_cell {
    display: none;
  }
  .table-webMargins .table_row .table_cell:nth-child(2) {
    margin-left: 10px;
    display: flex !important;
    flex: 1;
  }
  .table-webMargins .table_row .table_cell:nth-child(2) a {
    width: 100% !important;
  }
  .table-webMargins .table_row .table_cell:nth-child(3) {
    display: flex !important;
    flex: 1;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 700px) {
  .table-webMargins .table_row .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-webMargins .table_row .table_cell:nth-child(4) {
    display: flex !important;
    flex: 1;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 850px) {
  .table-webMargins .table_row .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-webMargins .table_row .table_cell:nth-child(8) {
    display: flex !important;
    width: 70px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-webMargins .mobile_table_wrapper {
    display: block;
  }
  .table-webMargins .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 70px;
  }
  .table-webMargins .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 700px) {
  .table-webMargins .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}
@media only screen and (max-width: 1000px) {
  .table-webMargins .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 850px) {
  .table-webMargins .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: grid;
  }
}

.table-recommendedPrices .header,
.table-recommendedPrices .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 70px;
}
@media only screen and (max-width: 800px) {
  .table-recommendedPrices .header,
  .table-recommendedPrices .table_row {
    grid-template-columns: 1fr 1fr 70px;
  }
}
@media only screen and (max-width: 650px) {
  .table-recommendedPrices .header,
  .table-recommendedPrices .table_row {
    grid-template-columns: 1fr 70px;
  }
}
@media only screen and (max-width: 800px) {
  .table-recommendedPrices .header {
    padding-left: 45px !important;
  }
  .table-recommendedPrices .header .table_cell {
    display: none;
  }
  .table-recommendedPrices .header .table_cell:first-child {
    display: flex !important;
  }
  .table-recommendedPrices .header .table_cell:nth-child(2) {
    display: flex !important;
  }
}
@media only screen and (max-width: 800px) and (max-width: 650px) {
  .table-recommendedPrices .header .table_cell:nth-child(2) {
    display: none !important;
  }
}
@media only screen and (max-width: 800px) {
  .table-recommendedPrices .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 800px) {
  .table-recommendedPrices .header .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 800px) {
  .table-recommendedPrices .header .table_cell:nth-child(5) {
    display: flex !important;
  }
}
@media only screen and (max-width: 800px) {
  .table-recommendedPrices .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 15px 10px !important;
  }
  .table-recommendedPrices .table_row .expand_button {
    display: block;
  }
  .table-recommendedPrices .table_row .table_cell {
    display: none;
  }
  .table-recommendedPrices .table_row .table_cell:nth-child(2) {
    margin-left: 10px;
    display: flex !important;
    flex: 1;
  }
  .table-recommendedPrices .table_row .table_cell:nth-child(2) a {
    width: 100% !important;
  }
  .table-recommendedPrices .table_row .table_cell:nth-child(3) {
    display: flex !important;
    flex: 1;
  }
}
@media only screen and (max-width: 800px) and (max-width: 650px) {
  .table-recommendedPrices .table_row .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 800px) {
  .table-recommendedPrices .table_row .table_cell:nth-child(4) {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .table-recommendedPrices .table_row .table_cell:nth-child(5) {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .table-recommendedPrices .table_row .table_cell:nth-child(6) {
    display: flex !important;
    width: 70px !important;
  }
}
@media only screen and (max-width: 800px) {
  .table-recommendedPrices .mobile_table_wrapper {
    display: block;
  }
  .table-recommendedPrices .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 70px;
  }
  .table-recommendedPrices .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 800px) and (max-width: 650px) {
  .table-recommendedPrices .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}

.linking_categories_screen_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 30px;
}
@media only screen and (max-width: 1000px) {
  .linking_categories_screen_wrapper {
    grid-template-columns: 100%;
    grid-gap: 30px 0;
  }
}
.linking_categories_screen_wrapper .dropdowns_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 20px;
  align-items: center;
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .linking_categories_screen_wrapper .dropdowns_wrapper {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}
.linking_categories_screen_wrapper .dropdowns_wrapper .search_select_wrapper {
  width: 100% !important;
  margin: 0;
}

.linking_categories_screen .search_select_wrapper.no-scroll .search_select_holder .root p {
  color: #333e48;
}
@media only screen and (max-width: 950px) {
  .linking_categories_screen .table_helper {
    flex-direction: column;
  }
  .linking_categories_screen .table_helper .align_row:first-child {
    order: 2;
  }
}
@media only screen and (max-width: 950px) and (max-width: 700px) {
  .linking_categories_screen .table_helper .align_row:first-child {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .linking_categories_screen .table_helper .align_row:first-child .search_select_wrapper {
    margin: 20px 20px 0 0;
  }
}
@media only screen and (max-width: 950px) and (max-width: 700px) and (max-width: 600px) {
  .linking_categories_screen .table_helper .align_row:first-child .search_select_wrapper {
    margin: 0;
  }
}
@media only screen and (max-width: 950px) {
  .linking_categories_screen .table_helper .align_row:last-child {
    order: 1;
  }
}
@media only screen and (max-width: 950px) {
  .linking_categories_screen .table_helper .align_row {
    width: 100%;
  }
  .linking_categories_screen .table_helper .align_row .search_holder {
    margin: 0 0 20px 0;
  }
}
@media only screen and (max-width: 950px) and (max-width: 750px) {
  .linking_categories_screen .table_helper .align_row .search_holder {
    margin: 0;
  }
}

.table-linkingCategories .header,
.table-linkingCategories .table_row {
  display: grid;
  grid-template-columns: 1fr 250px 100px;
  align-items: center;
}
@media only screen and (max-width: 1450px) {
  .table-linkingCategories .header,
  .table-linkingCategories .table_row {
    grid-template-columns: 1fr 150px 100px;
  }
}
@media only screen and (max-width: 1000px) {
  .table-linkingCategories .header,
  .table-linkingCategories .table_row {
    grid-template-columns: 1fr 250px 100px;
  }
}
@media only screen and (max-width: 600px) {
  .table-linkingCategories .header,
  .table-linkingCategories .table_row {
    grid-template-columns: 1fr 100px 100px;
  }
}
.table-linkingCategories .header > .table_cell:last-child,
.table-linkingCategories .table_row > .table_cell:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: unset !important;
}
.table-linkingCategories .header .select_form,
.table-linkingCategories .table_row .select_form {
  width: 350px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 850px) {
  .table-linkingCategories .header .select_form,
  .table-linkingCategories .table_row .select_form {
    display: none;
  }
}
.table-linkingCategories .header .select_form .search_select_wrapper,
.table-linkingCategories .table_row .select_form .search_select_wrapper {
  margin-right: 10px;
}
.table-linkingCategories .header .select_form .tooltip_wrap .title,
.table-linkingCategories .table_row .select_form .tooltip_wrap .title {
  left: -130px;
}
.table-linkingCategories .header .tooltip_wrap,
.table-linkingCategories .table_row .tooltip_wrap {
  margin-right: 5px;
}
.table-linkingCategories .header .tooltip_wrap .icon,
.table-linkingCategories .table_row .tooltip_wrap .icon {
  color: rgb(234, 84, 85);
  cursor: pointer;
}
.table-linkingCategories .modal_holder {
  position: relative;
  width: 30px;
  height: 30px;
}
.table-linkingCategories .modal_holder .tooltip_wrap {
  z-index: unset;
}
.table-linkingCategories .modal_holder .tooltip_wrap .icon {
  z-index: unset;
}
.table-linkingCategories .modal_holder .connected_categories_modal {
  position: absolute;
  top: 110%;
  right: 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #333e48;
  z-index: 5;
  overflow-y: scroll;
  max-height: 290px;
}
@media only screen and (max-width: 500px) {
  .table-linkingCategories .modal_holder .connected_categories_modal {
    height: 110px;
  }
}
@media only screen and (min-width: 500px) {
  .table-linkingCategories .modal_holder .connected_categories_modal::-webkit-scrollbar {
    width: 7px;
  }
  .table-linkingCategories .modal_holder .connected_categories_modal::-webkit-scrollbar-track {
    background: transparent;
  }
  .table-linkingCategories .modal_holder .connected_categories_modal::-webkit-scrollbar-thumb {
    background: rgb(212, 212, 212);
    border-radius: 100px;
  }
}
@media only screen and (max-width: 650px) {
  .table-linkingCategories .modal_holder .connected_categories_modal {
    top: 103%;
  }
}
.table-linkingCategories .modal_holder .connected_categories_modal .align_row {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
@media only screen and (max-width: 650px) {
  .table-linkingCategories .modal_holder .connected_categories_modal .align_row {
    padding: 3px 10px;
  }
}
@media only screen and (max-width: 650px) {
  .table-linkingCategories .modal_holder .connected_categories_modal .align_row > div {
    white-space: normal !important;
    width: 150px;
  }
}
.table-linkingCategories .modal_holder .connected_categories_modal .align_row:hover {
  background: #f8f8f8;
}
.table-linkingCategories .modal_holder .connected_categories_modal .align_row button {
  margin-left: 10px;
}

.search_select_wrapper.table_header_select {
  width: 220px;
}
.search_select_wrapper.table_header_select .root p {
  font-size: 13px;
}

.search_select_wrapper.no-scroll .search_select_holder .dropdown_holder .dropdown_content ul {
  margin: 0;
}
.search_select_wrapper.no-scroll .root p {
  font-size: 13px;
}

.mobile_connect_form_holder {
  display: none;
}
@media only screen and (max-width: 850px) {
  .mobile_connect_form_holder {
    display: flex;
    align-items: center;
  }
}
.mobile_connect_form_holder .custom_modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile_connect_form_holder .custom_modal_wrapper .custom_modal_holder {
  background: #f8f8f8;
  padding: 30px 50px;
  width: 70%;
  height: 300px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 650px) {
  .mobile_connect_form_holder .custom_modal_wrapper .custom_modal_holder {
    width: 90%;
    padding: 20px;
  }
}
.mobile_connect_form_holder .custom_modal_wrapper .custom_modal_holder .close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.mobile_connect_form_holder .custom_modal_wrapper .custom_modal_holder .search_select_wrapper .search_select_holder {
  background: #f8f8f8;
}
.mobile_connect_form_holder .custom_modal_wrapper .custom_modal_holder h2 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 20px;
  font-size: 20px;
}
.mobile_connect_form_holder .custom_modal_wrapper .custom_modal_holder form {
  display: block;
  width: 100%;
}
.mobile_connect_form_holder .custom_modal_wrapper .custom_modal_holder form .buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 0 20px;
  margin-top: 20px;
}

.our_categories_table .our_table button {
  margin: 0 0 20px auto;
}
@media only screen and (max-width: 1000px) {
  .our_categories_table .our_table button {
    margin-left: 0;
  }
}
.our_categories_table .our_table .table_header {
  background: #e7e3e3;
  height: 50px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.our_categories_table .our_table .table_header p {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: unset;
}
@media only screen and (max-width: 1600px) {
  .our_categories_table .our_table .table_header p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .our_categories_table .our_table .table_header p {
    font-size: 11px;
  }
}
.our_categories_table .table .sub {
  background: rgb(241, 241, 241);
  padding-left: 20px;
  position: relative;
}
.our_categories_table .table .sub.level_3, .our_categories_table .table .sub.level_5 {
  background: #fff;
}
.our_categories_table .table .sub.level_3 .table_row_wrap:nth-child(even), .our_categories_table .table .sub.level_5 .table_row_wrap:nth-child(even) {
  background: #fff;
}
.our_categories_table .table .sub::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 24px;
  left: 9px;
  width: 2px;
  background: #e7e3e3;
}
.our_categories_table .table .sub .table_row_wrap:nth-child(even) {
  background: rgb(241, 241, 241);
}
.our_categories_table .table .sub .table_row_wrap .table_row::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  left: -9px;
  height: 2px;
  background: #e7e3e3;
}
.our_categories_table .table .table_row_wrap {
  cursor: pointer;
  position: relative;
}
.our_categories_table .table .table_row_wrap:nth-child(even) {
  background: #f8f8f8;
}
.our_categories_table .table .table_row_wrap .table_row {
  position: relative;
  height: 50px;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 30px 1fr 40px;
  align-items: center;
}
.our_categories_table .table .table_row_wrap .table_row .table_cell {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: unset;
}
@media only screen and (max-width: 1600px) {
  .our_categories_table .table .table_row_wrap .table_row .table_cell {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .our_categories_table .table .table_row_wrap .table_row .table_cell {
    font-size: 11px;
  }
}

.supplier_categories_table .table_helper {
  position: relative;
}
.supplier_categories_table .table_helper .dropdowns_wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 20px);
}
.supplier_categories_table .table {
  margin-top: 80px !important;
}
@media only screen and (max-width: 500px) {
  .supplier_categories_table .table {
    margin-top: 140px !important;
  }
}

.table-linkingProductsOurProducts .content {
  height: 250px;
  overflow-y: scroll;
}
.table-linkingProductsOurProducts .content::-webkit-scrollbar {
  width: 10px;
}
.table-linkingProductsOurProducts .content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.table-linkingProductsOurProducts .content::-webkit-scrollbar-thumb {
  background: #dadada;
}
.table-linkingProductsOurProducts .content::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}
.table-linkingProductsOurProducts .header,
.table-linkingProductsOurProducts .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 150px 1fr 3fr 90px 30px;
}
@media only screen and (max-width: 950px) {
  .table-linkingProductsOurProducts .header,
  .table-linkingProductsOurProducts .table_row {
    grid-template-columns: 1fr 1fr 90px 30px;
  }
}
@media only screen and (max-width: 750px) {
  .table-linkingProductsOurProducts .header,
  .table-linkingProductsOurProducts .table_row {
    grid-template-columns: 1fr 90px 30px;
  }
}
@media only screen and (max-width: 950px) {
  .table-linkingProductsOurProducts .header {
    padding-left: 45px !important;
  }
  .table-linkingProductsOurProducts .header .table_cell {
    display: none;
  }
  .table-linkingProductsOurProducts .header .table_cell:first-child {
    display: flex !important;
  }
  .table-linkingProductsOurProducts .header .table_cell:nth-child(2) {
    display: flex !important;
  }
}
@media only screen and (max-width: 950px) and (max-width: 750px) {
  .table-linkingProductsOurProducts .header .table_cell:nth-child(2) {
    display: none !important;
  }
}
@media only screen and (max-width: 950px) {
  .table-linkingProductsOurProducts .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 950px) {
  .table-linkingProductsOurProducts .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 12px 10px !important;
  }
  .table-linkingProductsOurProducts .table_row .expand_button {
    display: block;
  }
  .table-linkingProductsOurProducts .table_row .table_cell {
    display: none;
  }
  .table-linkingProductsOurProducts .table_row .table_cell:nth-child(2) {
    margin-left: 10px;
    display: flex !important;
    flex: 1;
  }
  .table-linkingProductsOurProducts .table_row .table_cell:nth-child(2) a {
    width: 100% !important;
  }
  .table-linkingProductsOurProducts .table_row .table_cell:nth-child(3) {
    display: flex !important;
    flex: 1;
  }
}
@media only screen and (max-width: 950px) and (max-width: 750px) {
  .table-linkingProductsOurProducts .table_row .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 950px) {
  .table-linkingProductsOurProducts .table_row .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 950px) {
  .table-linkingProductsOurProducts .table_row .product_table_icons {
    width: 90px !important;
    display: flex !important;
  }
}
@media only screen and (max-width: 950px) {
  .table-linkingProductsOurProducts .mobile_table_wrapper {
    display: block;
  }
  .table-linkingProductsOurProducts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 70px;
  }
  .table-linkingProductsOurProducts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .linking_products_mobile .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .table-linkingProductsOurProducts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .linking_products_mobile .row.top {
    font-weight: bold;
    background: rgba(0, 0, 0, 0.05);
  }
  .table-linkingProductsOurProducts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .linking_products_mobile .row > div {
    padding: 5px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 950px) and (max-width: 600px) {
  .table-linkingProductsOurProducts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .linking_products_mobile .row > div {
    font-size: 10px;
  }
}
@media only screen and (max-width: 950px) {
  .table-linkingProductsOurProducts .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 950px) and (max-width: 750px) {
  .table-linkingProductsOurProducts .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}
@media only screen and (max-width: 950px) {
  .table-linkingProductsOurProducts .product_table_icons {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .table-linkingProductsOurProducts .linking_product_modal_wrapper {
    width: 600px !important;
  }
}
@media only screen and (max-width: 750px) {
  .table-linkingProductsOurProducts .linking_product_modal_wrapper {
    width: 400px !important;
  }
}
@media only screen and (max-width: 550px) {
  .table-linkingProductsOurProducts .linking_product_modal_wrapper {
    width: 250px !important;
  }
}

.linking_products_modal .MuiPaper-root {
  padding: 30px;
}
.linking_products_modal .MuiPaper-root .MuiDialogTitle-root {
  padding: 0 0 20px 0;
}
.linking_products_modal .MuiPaper-root .MuiDialogTitle-root h2 {
  text-align: center;
}
.linking_products_modal .MuiPaper-root .MuiDialogContent-root {
  padding: 20px 0 0 0;
}
.linking_products_modal .MuiPaper-root .buttons_holder {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 20px;
  margin-top: 30px;
}

.table-groupedProducts {
  margin-bottom: 60px;
}
.table-groupedProducts .header,
.table-groupedProducts .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 150px 1fr 3fr 100px 60px;
}
@media only screen and (max-width: 1200px) {
  .table-groupedProducts .header,
  .table-groupedProducts .table_row {
    grid-template-columns: 120px 1fr 1fr 70px 60px;
  }
}
@media only screen and (max-width: 950px) {
  .table-groupedProducts .header,
  .table-groupedProducts .table_row {
    grid-template-columns: 150px 200px;
  }
}
.table-groupedProducts .header .table_cell:last-child,
.table-groupedProducts .table_row .table_cell:last-child {
  overflow: unset !important;
}
@media only screen and (max-width: 950px) {
  .table-groupedProducts .header {
    padding-left: 45px !important;
  }
  .table-groupedProducts .header .table_cell {
    display: none;
  }
  .table-groupedProducts .header .table_cell:first-child {
    display: flex !important;
  }
  .table-groupedProducts .header .table_cell:nth-child(2) {
    display: flex !important;
  }
}
@media only screen and (max-width: 950px) and (max-width: 650px) {
  .table-groupedProducts .header .table_cell:nth-child(2) {
    display: none !important;
  }
}
.table-groupedProducts .table_row .table_cell:nth-child(6) {
  overflow: unset !important;
}
@media only screen and (max-width: 950px) {
  .table-groupedProducts .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 12px 10px !important;
  }
  .table-groupedProducts .table_row .expand_button {
    display: block;
  }
  .table-groupedProducts .table_row .table_cell {
    display: none;
  }
  .table-groupedProducts .table_row .table_cell:nth-child(2) {
    margin-left: 10px;
    display: flex !important;
    width: 150px !important;
  }
  .table-groupedProducts .table_row .table_cell:nth-child(2) a {
    width: 100% !important;
  }
  .table-groupedProducts .table_row .table_cell:nth-child(3) {
    display: flex !important;
    width: 200px !important;
  }
}
@media only screen and (max-width: 950px) and (max-width: 650px) {
  .table-groupedProducts .table_row .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 950px) {
  .table-groupedProducts .table_row .table_cell:nth-child(6) {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex !important;
    width: 35px !important;
    height: 30px !important;
  }
  .table-groupedProducts .table_row .table_cell:nth-child(6) .linking_modal_wrap_button {
    display: none !important;
  }
}
@media only screen and (max-width: 950px) {
  .table-groupedProducts .mobile_table_wrapper {
    display: block;
  }
  .table-groupedProducts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 70px;
  }
  .table-groupedProducts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .linking_products_mobile .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .table-groupedProducts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .linking_products_mobile .row.top {
    font-weight: bold;
    background: rgba(0, 0, 0, 0.05);
  }
  .table-groupedProducts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .linking_products_mobile .row > div {
    padding: 5px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 950px) and (max-width: 600px) {
  .table-groupedProducts .mobile_table_wrapper .mobile_table_holder .mobile_table_row .linking_products_mobile .row > div {
    font-size: 10px;
  }
}
@media only screen and (max-width: 950px) {
  .table-groupedProducts .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 950px) and (max-width: 650px) {
  .table-groupedProducts .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}
.table-groupedProducts .content {
  height: 250px;
  overflow-y: scroll;
  position: relative;
}
.table-groupedProducts .content::-webkit-scrollbar {
  width: 10px;
}
.table-groupedProducts .content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.table-groupedProducts .content::-webkit-scrollbar-thumb {
  background: #dadada;
}
.table-groupedProducts .content::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}
.table-groupedProducts .content .no_result {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  font-size: 18px;
  font-style: italic;
  color: #333e48;
}
@media only screen and (max-width: 500px) {
  .table-groupedProducts .content .no_result {
    font-size: 14px;
  }
}
.table-groupedProducts .pagination_nav {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
@media only screen and (max-width: 1200px) {
  .table-groupedProducts .linking_product_modal_wrapper {
    width: 600px !important;
  }
}
@media only screen and (max-width: 750px) {
  .table-groupedProducts .linking_product_modal_wrapper {
    width: 400px !important;
  }
}
@media only screen and (max-width: 550px) {
  .table-groupedProducts .linking_product_modal_wrapper {
    width: 250px !important;
  }
}

.multi_columns {
  margin-top: 20px;
  position: relative;
}
.multi_columns .table_helper p {
  text-transform: capitalize;
  font-size: 18px;
  color: #333e48;
  font-weight: 300;
}
@media only screen and (max-width: 750px) {
  .multi_columns .table_helper p {
    margin-bottom: 15px;
  }
}

.linking_product_filters {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1100px) {
  .linking_product_filters {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
@media only screen and (max-width: 650px) {
  .linking_product_filters {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}
.linking_product_filters .search_select_wrapper {
  width: 100%;
  font-size: 13px;
}
.linking_product_filters .search_select_wrapper label {
  display: none !important;
}
.linking_product_filters .search_holder {
  width: 100%;
  height: 40px;
  position: relative;
}
.linking_product_filters .search_holder svg {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.5);
}
.linking_product_filters .search_holder input {
  width: 100%;
  height: 40px;
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 13px;
  padding: 0 20px 0 40px;
  background: transparent;
  color: #333e48;
  border-radius: 6px;
}
.linking_product_filters .search_holder input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.linking_product_create_product_modal_inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  padding: 30px 10%;
}
@media only screen and (max-width: 1400px) {
  .linking_product_create_product_modal_inner {
    padding: 30px 5%;
  }
}
@media only screen and (max-width: 700px) {
  .linking_product_create_product_modal_inner {
    padding: 15px;
  }
}
.linking_product_create_product_modal_inner .close_icon {
  position: absolute;
  top: 30px;
  right: 50px;
  width: 40px;
  height: 40px;
  color: rgb(234, 84, 85);
  cursor: pointer;
  z-index: 2;
}
@media only screen and (max-width: 1400px) {
  .linking_product_create_product_modal_inner .close_icon {
    top: 10px;
    right: 20px;
    width: 30px;
    height: 30px;
  }
}
@media only screen and (max-width: 1000px) {
  .linking_product_create_product_modal_inner .close_icon {
    display: none;
  }
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  border-radius: 10px;
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder {
  width: 100%;
  height: 100%;
  padding: 50px 20px;
  overflow-y: scroll;
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder::-webkit-scrollbar {
  width: 0;
}
@media only screen and (max-width: 700px) {
  .linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder {
    padding: 30px 20px;
  }
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .form_group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
@media only screen and (max-width: 1000px) {
  .linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .form_group {
    grid-template-columns: 100%;
    grid-gap: 40px 0;
  }
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 25% calc(75% - 20px);
  grid-gap: 0 20px;
}
@media only screen and (max-width: 1000px) {
  .linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper {
  width: 100%;
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper.main_photo .photo {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  position: relative;
  height: 250px;
}
@media only screen and (max-width: 1600px) {
  .linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper.main_photo .photo {
    height: 200px;
  }
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper.main_photo .photo svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(234, 84, 85);
  font-size: 1rem;
  cursor: pointer;
  font-size: 30px;
}
@media only screen and (max-width: 1600px) {
  .linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper.main_photo .photo svg {
    font-size: 24px;
  }
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper .input_file_holder {
  height: 100px;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  position: relative;
  cursor: pointer;
  margin: 20px 0;
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper .input_file_holder input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper .input_file_holder span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper .photos_grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
@media only screen and (max-width: 500px) {
  .linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper .photos_grid {
    grid-template-columns: 100%;
  }
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper .photos_grid .photo {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  height: 250px;
  position: relative;
}
@media only screen and (max-width: 1600px) {
  .linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper .photos_grid .photo {
    height: 200px;
  }
}
@media only screen and (max-width: 500px) {
  .linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper .photos_grid .photo {
    height: 170px;
  }
}
.linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper .photos_grid .photo svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(234, 84, 85);
  font-size: 1rem;
  cursor: pointer;
  font-size: 30px;
}
@media only screen and (max-width: 1600px) {
  .linking_product_create_product_modal_inner .linking_product_create_product_modal_outer .form_holder .upload_photos_row .dropzone_wrapper .photos_grid .photo svg {
    font-size: 24px;
  }
}

.list_of_selected_items_outer {
  margin-bottom: 20px;
}
.list_of_selected_items_outer .list_of_selected_items_inner .our_product {
  margin-bottom: 20px;
}
.list_of_selected_items_outer .list_of_selected_items_inner .item {
  background: #f8f8f8;
  border-bottom: 1px solid #fff;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 200px 1fr auto;
  font-size: 13px;
  padding: 15px;
}
@media only screen and (max-width: 700px) {
  .list_of_selected_items_outer .list_of_selected_items_inner .item {
    grid-template-columns: 120px 1fr auto;
    font-size: 10px;
    padding: 10px 5px;
  }
}
.list_of_selected_items_outer .list_of_selected_items_inner .item div {
  display: flex;
  align-items: center;
}
.list_of_selected_items_outer .list_of_selected_items_inner .item div a {
  width: 24px;
  height: 24px;
}
.list_of_selected_items_outer .list_of_selected_items_inner .item svg {
  color: rgb(234, 84, 85);
  font-size: 24px;
  cursor: pointer;
}
.list_of_selected_items_outer .list_of_selected_items_inner .item svg.create {
  color: rgb(86, 201, 58);
}
.list_of_selected_items_outer .list_of_selected_items_inner .item svg.edit {
  color: #E10770;
}
@media only screen and (max-width: 600px) {
  .list_of_selected_items_outer .list_of_selected_items_inner .item svg {
    font-size: 18px;
  }
}
.list_of_selected_items_outer .list_of_selected_items_inner .buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.upload_pricelist {
  width: 100%;
}
.upload_pricelist .grid_x2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 30px;
}
@media only screen and (max-width: 1100px) {
  .upload_pricelist .grid_x2 {
    grid-template-columns: 100%;
    grid-gap: 30px 0;
  }
}
.upload_pricelist form {
  width: 100%;
}
.upload_pricelist form .left .dropzone_holder {
  width: 100%;
  height: 250px;
  border: 2px dashed #ddd;
  position: relative;
  padding: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .upload_pricelist form .left .dropzone_holder {
    height: 200px;
  }
}
.upload_pricelist form .left .dropzone_holder input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.upload_pricelist form .left .dropzone_holder .util {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upload_pricelist form .left .dropzone_holder .util svg {
  color: #E10770;
  font-size: 5rem;
}
@media only screen and (max-width: 700px) {
  .upload_pricelist form .left .dropzone_holder .util svg {
    font-size: 4rem;
  }
}
.upload_pricelist form .left .dropzone_holder .util p {
  margin-top: 10px;
  color: #333e48;
  font-size: 20px;
  text-align: center;
}
@media only screen and (max-width: 700px) {
  .upload_pricelist form .left .dropzone_holder .util p {
    font-size: 17px;
  }
}
.upload_pricelist form .left .error_msg {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
}
@media only screen and (max-width: 700px) {
  .upload_pricelist form .left .error_msg {
    font-size: 14px;
  }
}
.upload_pricelist form .print_uploaded_file {
  margin-top: 20px;
}
.upload_pricelist form .print_uploaded_file > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-radius: 8px;
  background: #f8f8f8;
  margin-bottom: 10px;
  font-size: 14px;
}
.upload_pricelist form .right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.upload_pricelist form .right .radio_group.MuiFormGroup-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: unset;
  justify-content: center;
}
@media only screen and (max-width: 700px) {
  .upload_pricelist form .right .radio_group.MuiFormGroup-root {
    flex-direction: column;
  }
}
.upload_pricelist form .right .radio_group.MuiFormGroup-root .MuiRadio-colorSecondary.Mui-checked {
  color: #E10770;
}
.upload_pricelist form .right button {
  margin-top: 80px;
}
.upload_pricelist form .right .form_checkbox {
  position: absolute;
}
@media only screen and (max-width: 700px) {
  .upload_pricelist form .right .form_checkbox label {
    font-size: 12px;
  }
}

.table-categories .header,
.table-categories .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr 100px 70px 100px 100px 100px 100px 100px 70px;
}
@media only screen and (max-width: 1450px) {
  .table-categories .header,
  .table-categories .table_row {
    grid-template-columns: 40px 30px 1fr 80px 80px 80px 80px 80px 70px;
  }
}
@media only screen and (max-width: 1250px) {
  .table-categories .header,
  .table-categories .table_row {
    grid-template-columns: 40px 30px 1fr 80px 80px 80px 70px;
  }
}
@media only screen and (max-width: 870px) {
  .table-categories .header,
  .table-categories .table_row {
    grid-template-columns: 40px 30px 1fr 60px 60px 70px;
  }
}
@media only screen and (max-width: 750px) {
  .table-categories .header,
  .table-categories .table_row {
    grid-template-columns: 40px 30px 1fr 70px;
  }
}
@media only screen and (max-width: 1450px) {
  .table-categories .header {
    padding-left: 45px !important;
  }
  .table-categories .header .table_cell:last-child {
    display: none !important;
  }
  .table-categories .header .table_cell:nth-child(10) {
    display: flex !important;
  }
  .table-categories .header .table_cell:nth-child(9) {
    display: none !important;
  }
  .table-categories .header .table_cell:nth-child(8) {
    display: none !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1250px) {
  .table-categories .header .table_cell:nth-child(7) {
    display: none !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1250px) {
  .table-categories .header .table_cell:nth-child(6) {
    display: none !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 870px) {
  .table-categories .header .table_cell:nth-child(5) {
    display: none !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 750px) {
  .table-categories .header .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 750px) {
  .table-categories .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
.table-categories .table_row {
  cursor: pointer;
}
.table-categories .table_row .table_cell:nth-child(3) {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}
@media only screen and (max-width: 1450px) {
  .table-categories .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 8px 10px !important;
  }
  .table-categories .table_row .table_cell:nth-child(11) {
    width: 70px !important;
  }
  .table-categories .table_row .table_cell:nth-child(10) {
    display: none !important;
  }
  .table-categories .table_row .table_cell:nth-child(9) {
    display: none !important;
  }
  .table-categories .table_row .table_cell:nth-child(8) {
    width: 80px !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1250px) {
  .table-categories .table_row .table_cell:nth-child(8) {
    display: none !important;
  }
}
@media only screen and (max-width: 1450px) {
  .table-categories .table_row .table_cell:nth-child(7) {
    width: 80px !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1250px) {
  .table-categories .table_row .table_cell:nth-child(7) {
    display: none !important;
  }
}
@media only screen and (max-width: 1450px) {
  .table-categories .table_row .table_cell:nth-child(6) {
    width: 80px !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 870px) {
  .table-categories .table_row .table_cell:nth-child(6) {
    display: none !important;
  }
}
@media only screen and (max-width: 1450px) {
  .table-categories .table_row .table_cell:nth-child(5) {
    width: 80px !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 870px) {
  .table-categories .table_row .table_cell:nth-child(5) {
    width: 60px !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 750px) {
  .table-categories .table_row .table_cell:nth-child(5) {
    display: none !important;
  }
}
@media only screen and (max-width: 1450px) {
  .table-categories .table_row .table_cell:nth-child(4) {
    width: 80px !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 870px) {
  .table-categories .table_row .table_cell:nth-child(4) {
    width: 60px !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 750px) {
  .table-categories .table_row .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1450px) {
  .table-categories .table_row .table_cell:nth-child(3) {
    flex: 1;
  }
}
@media only screen and (max-width: 1450px) {
  .table-categories .table_row .table_cell:nth-child(2) {
    width: 30px !important;
    margin: 0 10px;
  }
}
@media only screen and (max-width: 1450px) {
  .table-categories .table_row .expand_button {
    display: block !important;
  }
}
@media only screen and (max-width: 1450px) {
  .table-categories .table_row .mobile_table_wrapper {
    display: block;
  }
  .table-categories .table_row .mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
    min-width: 90px;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1450px) {
  .table-categories .table_row .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1450px) and (max-width: 750px) {
  .table-categories .table_row .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1450px) {
  .table-categories .table_row .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1450px) and (max-width: 750px) {
  .table-categories .table_row .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: grid;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1450px) {
  .table-categories .table_row .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: none;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1450px) and (max-width: 870px) {
  .table-categories .table_row .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: grid;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1450px) {
  .table-categories .table_row .mobile_table_wrapper .mobile_table_row:nth-child(4) {
    display: none;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1450px) and (max-width: 1250px) {
  .table-categories .table_row .mobile_table_wrapper .mobile_table_row:nth-child(4) {
    display: grid;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1450px) {
  .table-categories .table_row .mobile_table_wrapper .mobile_table_row:nth-child(5) {
    display: none;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 1450px) and (max-width: 1250px) {
  .table-categories .table_row .mobile_table_wrapper .mobile_table_row:nth-child(5) {
    display: grid;
  }
}
.table-categories .content .level_1:nth-child(even) {
  background: #f8f8f8;
}
.table-categories .subcategories_wrapper.level_2 {
  background: rgb(241, 241, 241);
  padding-left: 50px;
  position: relative;
}
.table-categories .subcategories_wrapper.level_2::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 23px;
  left: 24px;
  width: 2px;
  background: #e7e3e3;
}
.table-categories .subcategories_wrapper.level_2 .table_row {
  position: relative;
}
@media only screen and (max-width: 1450px) {
  .table-categories .subcategories_wrapper.level_2 .table_row {
    padding-left: 0 !important;
  }
}
.table-categories .subcategories_wrapper.level_2 .table_row::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  left: -24px;
  height: 2px;
  background: #e7e3e3;
}
@media only screen and (max-width: 1450px) {
  .table-categories .subcategories_wrapper.level_2 .table_row .table_cell:nth-child(3) {
    width: 360px !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 650px) {
  .table-categories .subcategories_wrapper.level_2 .table_row .table_cell:nth-child(3) {
    width: calc(100% - 110px) !important;
  }
}
.table-categories .subcategories_wrapper.level_3 {
  padding-left: 50px;
  background: #fff;
  position: relative;
}
.table-categories .subcategories_wrapper.level_3::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 29px;
  left: 24px;
  width: 2px;
  background: #e7e3e3;
}
.table-categories .subcategories_wrapper.level_3 .table_row {
  position: relative;
}
@media only screen and (max-width: 1450px) {
  .table-categories .subcategories_wrapper.level_3 .table_row {
    padding-left: 0 !important;
  }
}
.table-categories .subcategories_wrapper.level_3 .table_row::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  left: -24px;
  height: 2px;
  background: #e7e3e3;
}
@media only screen and (max-width: 1450px) {
  .table-categories .subcategories_wrapper.level_3 .table_row .table_cell:nth-child(3) {
    width: 360px !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 650px) {
  .table-categories .subcategories_wrapper.level_3 .table_row .table_cell:nth-child(3) {
    width: calc(100% - 110px) !important;
  }
}
.table-categories .subcategories_wrapper.level_4 {
  padding-left: 50px;
  background: #f8f8f8;
}
.table-categories .subcategories_wrapper.level_4::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 29px;
  left: 24px;
  width: 2px;
  background: #e7e3e3;
}
.table-categories .subcategories_wrapper.level_4 .table_row {
  position: relative;
}
@media only screen and (max-width: 1450px) {
  .table-categories .subcategories_wrapper.level_4 .table_row {
    padding-left: 0 !important;
  }
}
.table-categories .subcategories_wrapper.level_4 .table_row::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  left: -24px;
  height: 2px;
  background: #e7e3e3;
}
@media only screen and (max-width: 1450px) {
  .table-categories .subcategories_wrapper.level_4 .table_row .table_cell:nth-child(3) {
    width: 360px !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 650px) {
  .table-categories .subcategories_wrapper.level_4 .table_row .table_cell:nth-child(3) {
    width: calc(100% - 110px) !important;
  }
}
.table-categories .subcategories_wrapper.level_5 {
  padding-left: 50px;
  background: #fff;
}
.table-categories .subcategories_wrapper.level_5::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 29px;
  left: 24px;
  width: 2px;
  background: #e7e3e3;
}
.table-categories .subcategories_wrapper.level_5 .table_row {
  position: relative;
}
@media only screen and (max-width: 1450px) {
  .table-categories .subcategories_wrapper.level_5 .table_row {
    padding-left: 0 !important;
  }
}
.table-categories .subcategories_wrapper.level_5 .table_row::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  left: -24px;
  height: 2px;
  background: #e7e3e3;
}
@media only screen and (max-width: 1450px) {
  .table-categories .subcategories_wrapper.level_5 .table_row .table_cell:nth-child(3) {
    width: 360px !important;
  }
}
@media only screen and (max-width: 1450px) and (max-width: 650px) {
  .table-categories .subcategories_wrapper.level_5 .table_row .table_cell:nth-child(3) {
    width: calc(100% - 110px) !important;
  }
}

.no_result.categoty_attributes {
  margin-top: 20px;
}

.category_create_attribut_form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 60px auto;
  grid-gap: 0 10px;
}
.category_create_attribut_form .form_field label {
  font-size: 12px;
}
@media only screen and (max-width: 600px) {
  .category_create_attribut_form {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
  .category_create_attribut_form button {
    width: 150px;
  }
}
.category_create_attribut_form .search_select_holder {
  font-size: 12px;
}

.table_categories_attributes .header .table_cell:nth-child(1) {
  padding-left: 8px;
}
.table_categories_attributes .header,
.table_categories_attributes .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 30px 30px 1fr 80px 80px 100px;
}
@media only screen and (max-width: 700px) {
  .table_categories_attributes .header,
  .table_categories_attributes .table_row {
    grid-template-columns: 30px 30px 1fr 35px 45px 100px;
    padding: 0 !important;
  }
}
.table_categories_attributes .row {
  position: relative;
  cursor: pointer;
}
.table_categories_attributes .row:nth-child(even) {
  background: #f8f8f8;
}
.table_categories_attributes .table_category_attribute_values {
  background: #f1f1f1;
  padding-left: 50px;
  position: relative;
}
@media only screen and (max-width: 700px) {
  .table_categories_attributes .table_category_attribute_values {
    padding-left: 20px;
  }
}
.table_categories_attributes .table_category_attribute_values::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 24px;
  left: 24px;
  width: 2px;
  background: #e7e3e3;
}
@media only screen and (max-width: 700px) {
  .table_categories_attributes .table_category_attribute_values::before {
    left: 14px;
  }
}
.table_categories_attributes .table_category_attribute_values .table_row {
  background: #f1f1f1 !important;
  position: relative;
  grid-template-columns: 50px 1fr 80px 110px 70px;
}
@media only screen and (max-width: 700px) {
  .table_categories_attributes .table_category_attribute_values .table_row {
    grid-template-columns: 30px 1fr 35px 75px 70px;
    padding: 0 !important;
  }
}
.table_categories_attributes .table_category_attribute_values .table_row::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  left: -24px;
  height: 2px;
  background: #e7e3e3;
}
@media only screen and (max-width: 700px) {
  .table_categories_attributes .table_category_attribute_values .table_row::before {
    width: 7px;
    left: -5px;
  }
}

.edit_category_screen .category_name {
  margin-bottom: 20px;
  font-size: 18px;
  padding: 15px 20px;
  font-weight: 500;
}
.edit_category_screen .inline_update_form .search_select_wrapper {
  width: auto;
  margin-right: 20px;
}
.edit_category_screen .inline_update_form .add_value_dropdown {
  display: flex;
  align-items: center;
}

.attribute_value_modal_inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.attribute_value_modal_inner .attribute_value_modal_outer {
  background: #fff;
  border-radius: 10px;
  padding: 50px 30px;
  display: flex;
}
.attribute_value_modal_inner .attribute_value_modal_outer .MuiSwitch-root {
  margin-left: 50px;
}
.attribute_value_modal_inner .attribute_value_modal_outer .add_value_form {
  display: grid;
  grid-template-columns: 300px auto;
  grid-gap: 20px;
}
.attribute_value_modal_inner .attribute_value_modal_outer .add_value_dropdown {
  display: grid;
  grid-template-columns: 300px auto;
  grid-gap: 20px;
}
.attribute_value_modal_inner .attribute_value_modal_outer .search_select_wrapper {
  width: 300px;
}

.table-orders .header,
.table-orders .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 40px 250px 1fr 90px 90px 190px;
}
@media only screen and (max-width: 1350px) {
  .table-orders .header,
  .table-orders .table_row {
    grid-template-columns: 40px 220px 1fr 80px 80px 160px;
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .header,
  .table-orders .table_row {
    grid-template-columns: 1fr;
  }
}
.table-orders .header .table_cell:nth-child(1) a,
.table-orders .table_row .table_cell:nth-child(1) a {
  color: #E10770;
}
.table-orders .header .table_cell:last-child,
.table-orders .table_row .table_cell:last-child {
  position: relative;
  overflow: unset !important;
}
.table-orders .header .table_cell:last-child .tooltip_wrap,
.table-orders .table_row .table_cell:last-child .tooltip_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-orders .header .table_cell:last-child .status_chip,
.table-orders .table_row .table_cell:last-child .status_chip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 100px;
}
@media only screen and (max-width: 1350px) {
  .table-orders .header .table_cell:last-child .status_chip,
  .table-orders .table_row .table_cell:last-child .status_chip {
    font-size: 11px;
  }
}
.table-orders .header .table_cell:last-child .status_chip.success,
.table-orders .table_row .table_cell:last-child .status_chip.success {
  background: rgba(40, 199, 111, 0.15);
  color: rgb(40, 199, 111);
}
.table-orders .header .table_cell:last-child .status_chip.danger,
.table-orders .table_row .table_cell:last-child .status_chip.danger {
  background: rgba(234, 84, 85, 0.15);
  color: rgb(234, 84, 85);
}
.table-orders .header .table_cell:last-child .status_chip.primary,
.table-orders .table_row .table_cell:last-child .status_chip.primary {
  background: rgba(115, 103, 240, 0.15);
  color: rgb(115, 103, 240);
}
.table-orders .header .table_cell:last-child .status_chip.warning,
.table-orders .table_row .table_cell:last-child .status_chip.warning {
  background: rgba(255, 159, 67, 0.15);
  color: rgb(255, 159, 67);
}
.table-orders .header .table_cell:last-child .status_chip.info,
.table-orders .table_row .table_cell:last-child .status_chip.info {
  background: rgba(28, 231, 255, 0.2901960784);
  color: #0077d6;
}
.table-orders .header .table_cell:last-child .status_chip.secondary,
.table-orders .table_row .table_cell:last-child .status_chip.secondary {
  background: rgba(158, 158, 158, 0.2196078431);
  color: rgba(0, 0, 0, 0.7607843137);
}
@media only screen and (max-width: 1000px) {
  .table-orders .header {
    padding-left: 45px !important;
    grid-template-columns: 250px 450px 100px;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 960px) {
  .table-orders .header {
    grid-template-columns: 250px 450px;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 860px) {
  .table-orders .header {
    grid-template-columns: 200px 400px;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 770px) {
  .table-orders .header {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 500px) {
  .table-orders .header {
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .header .table_cell {
    display: none;
  }
  .table-orders .header .table_cell:nth-child(2) {
    display: flex !important;
  }
  .table-orders .header .table_cell:nth-child(3) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 770px) {
  .table-orders .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .header .table_cell:nth-child(4) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 960px) {
  .table-orders .header .table_cell:nth-child(4) {
    display: none !important;
  }
}
.table-orders .table_row {
  cursor: pointer;
}
@media only screen and (max-width: 1000px) {
  .table-orders .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 10px !important;
  }
  .table-orders .table_row .expand_button {
    display: block;
  }
  .table-orders .table_row .table_cell {
    display: none;
  }
  .table-orders .table_row .table_cell:nth-child(3) {
    width: 250px !important;
    display: flex !important;
    flex-direction: column;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 860px) {
  .table-orders .table_row .table_cell:nth-child(3) {
    width: 200px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .table_row .table_cell:nth-child(4) {
    width: 450px !important;
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 860px) {
  .table-orders .table_row .table_cell:nth-child(4) {
    width: 400px !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 770px) {
  .table-orders .table_row .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .table_row .table_cell:nth-child(5) {
    width: 100px !important;
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 960px) {
  .table-orders .table_row .table_cell:nth-child(5) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .mobile_table_wrapper {
    display: block;
  }
  .table-orders .mobile_table_wrapper .status_chip {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 100px;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 1350px) {
  .table-orders .mobile_table_wrapper .status_chip {
    font-size: 11px;
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .mobile_table_wrapper .status_chip.success {
    background: rgba(40, 199, 111, 0.15);
    color: rgb(40, 199, 111);
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .mobile_table_wrapper .status_chip.danger {
    background: rgba(234, 84, 85, 0.15);
    color: rgb(234, 84, 85);
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .mobile_table_wrapper .status_chip.primary {
    background: rgba(115, 103, 240, 0.15);
    color: rgb(115, 103, 240);
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .mobile_table_wrapper .status_chip.warning {
    background: rgba(255, 159, 67, 0.15);
    color: rgb(255, 159, 67);
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .mobile_table_wrapper .status_chip.info {
    background: rgba(28, 231, 255, 0.2901960784);
    color: #0077d6;
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .mobile_table_wrapper .status_chip.secondary {
    background: rgba(158, 158, 158, 0.2196078431);
    color: rgba(0, 0, 0, 0.7607843137);
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 1000px) {
  .table-orders .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 960px) {
  .table-orders .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: grid;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 770px) {
  .table-orders .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}

.single_order_page_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media only screen and (max-width: 1000px) {
  .single_order_page_wrap {
    display: flex;
    flex-direction: column;
  }
}
.single_order_page_wrap h2.title {
  font-weight: 300;
  font-size: 20px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.single_order_page_wrap .top_part {
  width: calc(100% - 340px);
}
@media only screen and (max-width: 1000px) {
  .single_order_page_wrap .top_part {
    width: 100%;
  }
}
.single_order_page_wrap .top_part .details_wrap .item_group {
  display: flex;
  align-items: center;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 160px 1fr;
}
@media only screen and (max-width: 600px) {
  .single_order_page_wrap .top_part .details_wrap .item_group {
    grid-template-columns: 120px 1fr;
  }
}
.single_order_page_wrap .top_part .details_wrap .item_group span {
  font-weight: 500;
  color: #3a3a3a;
  margin-right: 5px;
  font-size: 14px;
}
@media only screen and (max-width: 700px) {
  .single_order_page_wrap .top_part .details_wrap .item_group span {
    font-size: 12px;
  }
}
.single_order_page_wrap .top_part .details_wrap .item_group p {
  font-weight: 300;
  font-size: 14px;
  word-break: break-all;
}
@media only screen and (max-width: 700px) {
  .single_order_page_wrap .top_part .details_wrap .item_group p {
    font-size: 12px;
  }
}
.single_order_page_wrap .top_part .details_wrap .item_group a {
  color: #E10770;
  font-weight: 300;
  font-size: 14px;
  word-break: break-all;
}
@media only screen and (max-width: 700px) {
  .single_order_page_wrap .top_part .details_wrap .item_group a {
    font-size: 12px;
  }
}
.single_order_page_wrap .top_part .selects_group {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1450px) {
  .single_order_page_wrap .top_part .selects_group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  .single_order_page_wrap .top_part .selects_group button {
    width: 100px;
  }
}
@media only screen and (max-width: 850px) {
  .single_order_page_wrap .top_part .selects_group {
    grid-template-columns: 100%;
  }
}
.single_order_page_wrap .top_part .selects_group span {
  color: #fff !important;
  margin: 0 !important;
}
.single_order_page_wrap .top_part .selects_group .search_select_wrapper {
  width: 250px;
  margin-right: 20px;
}
@media only screen and (max-width: 1800px) {
  .single_order_page_wrap .top_part .selects_group .search_select_wrapper {
    width: 200px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 1600px) {
  .single_order_page_wrap .top_part .selects_group .search_select_wrapper {
    width: 150px;
  }
}
@media only screen and (max-width: 1450px) {
  .single_order_page_wrap .top_part .selects_group .search_select_wrapper {
    width: 100%;
    margin: 0;
  }
}
.single_order_page_wrap .top_part .selects_group .search_select_wrapper span {
  color: rgba(0, 0, 0, 0.4) !important;
}
.single_order_page_wrap .top_part .selects_group .search_select_wrapper label {
  display: none;
}
.single_order_page_wrap .top_part .selects_group .search_select_wrapper .search_select_holder .root {
  padding: 0 10px;
}
.single_order_page_wrap .top_part .selects_group .search_select_wrapper .search_select_holder .root p span {
  color: #333e48 !important;
}
.single_order_page_wrap .top_part .selects_group .dropdown_inner {
  width: 250px;
  margin-right: 20px;
  height: 40px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  font-size: 14px;
}
@media only screen and (max-width: 1800px) {
  .single_order_page_wrap .top_part .selects_group .dropdown_inner {
    width: 200px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 1600px) {
  .single_order_page_wrap .top_part .selects_group .dropdown_inner {
    width: 150px;
  }
}
@media only screen and (max-width: 1450px) {
  .single_order_page_wrap .top_part .selects_group .dropdown_inner {
    width: 100%;
    margin: 0;
  }
}
.single_order_page_wrap .top_part .selects_group .dropdown_inner.active {
  border-color: #7367f0;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.single_order_page_wrap .top_part .selects_group .dropdown_inner.active .dropdown_outer svg {
  transform: rotate(180deg);
}
.single_order_page_wrap .top_part .selects_group .dropdown_inner .dropdown_outer {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 20px;
  grid-gap: 10px;
  padding: 0 10px;
  cursor: pointer;
}
.single_order_page_wrap .top_part .selects_group .dropdown_inner .dropdown_outer p.active {
  color: #333e48;
}
.single_order_page_wrap .top_part .selects_group .dropdown_inner .dropdown_outer p {
  font-weight: 400;
}
@media only screen and (max-width: 1800px) {
  .single_order_page_wrap .top_part .selects_group .dropdown_inner .dropdown_outer p {
    font-size: 12px;
  }
}
.single_order_page_wrap .top_part .selects_group .dropdown_inner .dropdown_outer svg {
  transform: rotate(0);
  transition: all 0.2s ease;
}
.single_order_page_wrap .top_part .selects_group .dropdown_inner .dropdown_items {
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  overflow: hidden;
}
.single_order_page_wrap .top_part .selects_group .dropdown_inner .dropdown_items li {
  padding: 5px 10px;
  cursor: pointer;
}
@media only screen and (max-width: 1800px) {
  .single_order_page_wrap .top_part .selects_group .dropdown_inner .dropdown_items li {
    font-size: 12px;
  }
}
.single_order_page_wrap .top_part .selects_group .dropdown_inner .dropdown_items li.active {
  background: #f8f8f8;
  color: #333e48;
}

.table_orders_items_products {
  margin-top: 20px;
  width: 100%;
}
@media only screen and (max-width: 500px) {
  .table_orders_items_products {
    margin-top: 10px;
  }
}
.table_orders_items_products .header,
.table_orders_items_products .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 100px 100px 100px;
}
@media only screen and (max-width: 1200px) {
  .table_orders_items_products .header,
  .table_orders_items_products .table_row {
    grid-template-columns: 1fr 60px 60px 60px;
  }
}
@media only screen and (max-width: 850px) {
  .table_orders_items_products .header,
  .table_orders_items_products .table_row {
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 850px) {
  .table_orders_items_products .header {
    padding-left: 45px !important;
  }
}
@media only screen and (max-width: 850px) and (max-width: 500px) {
  .table_orders_items_products .header {
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 850px) {
  .table_orders_items_products .header .table_cell {
    display: none;
  }
  .table_orders_items_products .header .table_cell:nth-child(1) {
    display: flex !important;
  }
}
.table_orders_items_products .table_row {
  height: 60px !important;
}
@media only screen and (max-width: 850px) {
  .table_orders_items_products .table_row {
    height: unset !important;
    padding: 10px !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 10px !important;
  }
  .table_orders_items_products .table_row .expand_button {
    display: block;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 850px) and (max-width: 850px) {
  .table_orders_items_products .table_row .table_cell {
    display: none;
  }
}
.table_orders_items_products .table_row .table_cell:nth-child(1) {
  display: flex;
  align-items: center;
}
.table_orders_items_products .table_row .table_cell:nth-child(1) img {
  max-height: 45px;
  margin-right: 10px;
  border-radius: 4px;
}
@media only screen and (max-width: 850px) {
  .table_orders_items_products .table_row .table_cell:nth-child(1) img {
    max-height: 35px;
  }
}
@media only screen and (max-width: 500px) {
  .table_orders_items_products .table_row .table_cell:nth-child(1) img {
    max-height: 30px;
  }
}
@media only screen and (max-width: 350px) {
  .table_orders_items_products .table_row .table_cell:nth-child(1) img {
    max-height: 20px;
  }
}
.table_orders_items_products .table_row .table_cell:nth-child(1) .product_info a {
  color: #E10770;
  margin-bottom: 3px;
  display: block;
}
.table_orders_items_products .table_row .table_cell:nth-child(1) .product_info span {
  font-weight: 500;
}
@media only screen and (max-width: 850px) {
  .table_orders_items_products .table_row .mobile_table_wrapper {
    display: block;
  }
}
.table_orders_items_products .total_result_print {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}
.table_orders_items_products .total_result_print strong {
  margin-left: 5px;
}
.table_orders_items_products .photoPlaceholder {
  width: 40px;
  height: 40px;
  opacity: 0.3;
}
@media only screen and (max-width: 850px) {
  .table_orders_items_products .photoPlaceholder {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (max-width: 350px) {
  .table_orders_items_products .photoPlaceholder {
    width: 20px;
    height: 20px;
  }
}

.order_notes_box {
  width: 320px;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
}
@media only screen and (max-width: 1000px) {
  .order_notes_box {
    width: 100%;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .order_notes_box {
    margin-top: 10px;
  }
}
.order_notes_box .note {
  margin: 20px 0;
  width: 100%;
  font-size: 13px;
  background: rgba(255, 186, 0, 0.15);
  padding: 10px;
  border-radius: 6px;
  color: rgb(255, 186, 0);
  font-weight: 300;
  position: relative;
}
.order_notes_box .note .bottom {
  margin-top: 10px;
  color: #b9b9b9;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order_notes_box form.form {
  width: 100%;
  position: relative;
}
.order_notes_box form.form h4 {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 300;
}
.order_notes_box form.form textarea {
  width: 100%;
  height: 120px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 15px;
  color: #333e48;
  transition: all 0.2s ease;
}
.order_notes_box form.form textarea:focus {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  border-color: #E10770;
}
.order_notes_box .buttons_row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.order_notes_box .is_empty {
  margin: 30px 0;
  font-weight: 300;
  font-size: 16px;
}
.order_notes_box .type_of_note_dropdown {
  margin-bottom: 20px;
  background: #fff;
  width: 100%;
  height: 40px;
  position: relative;
  border-radius: 5px;
  color: #333e48;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}
.order_notes_box .type_of_note_dropdown.active {
  border-color: #7367f0;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.order_notes_box .type_of_note_dropdown.active .root span {
  transform: translateY(-50%) rotate(180deg);
}
.order_notes_box .type_of_note_dropdown .root {
  padding: 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.order_notes_box .type_of_note_dropdown .root span {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(0);
  transition: all 0.2s ease;
}
.order_notes_box .type_of_note_dropdown .root span svg {
  font-size: 20px;
}
.order_notes_box .type_of_note_dropdown .dropdown_holder {
  transition: all 0.2s ease;
  z-index: 2;
  position: absolute;
  top: calc(100% + 5px);
  left: -1px;
  right: -1px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
}
.order_notes_box .type_of_note_dropdown .dropdown_holder ul {
  width: 100%;
  height: 100%;
}
.order_notes_box .type_of_note_dropdown .dropdown_holder ul li {
  font-size: 13px;
  padding: 7px 10px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.4);
  transition: all 0.2s ease;
}
.order_notes_box .type_of_note_dropdown .dropdown_holder ul li:hover {
  background: #f8f8f8;
  cursor: pointer;
}
.order_notes_box .type_of_note_dropdown .dropdown_holder ul li.active {
  color: #333e48;
}
.order_notes_box button.button {
  margin-left: auto;
}

.orders_status_list {
  margin-top: 20px;
}
.orders_status_list.divider {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}
.orders_status_list .MuiChip-outlined {
  margin: 0 5px 5px 0;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 12px;
}
@media only screen and (max-width: 500px) {
  .orders_status_list .MuiChip-outlined {
    font-size: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .orders_cms_page .table_helper .align_row:nth-child(1) {
    flex-direction: column;
    align-items: start;
  }
}
.orders_cms_page .date_ranger_filters {
  position: relative;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 500px) {
  .orders_cms_page .date_ranger_filters {
    margin-top: 20px;
    flex-direction: column;
    align-items: start;
  }
}
.orders_cms_page .date_ranger_filters .MuiChip-clickable {
  margin-left: 20px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 12px;
}
@media only screen and (max-width: 500px) {
  .orders_cms_page .date_ranger_filters .MuiChip-clickable {
    font-size: 10px;
    margin: 20px 0 0 0;
  }
}
.orders_cms_page .date_ranger_filters .MuiChip-clickable svg {
  font-size: 16px;
}
.orders_cms_page .date_ranger_filters .dropdown_inner {
  position: absolute;
  left: 0;
  top: 120%;
  z-index: 99;
  background: #fff;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px 0;
}
.orders_cms_page .date_ranger_filters .dropdown_inner .DateRangePicker__CalendarSelection {
  background-color: #E10770;
  border-color: #E10770;
}
.orders_cms_page .date_ranger_filters .dropdown_inner .DateRangePicker__Date--is-highlighted div {
  border-color: #E10770 !important;
}

@media only screen and (max-width: 720px) {
  .discount_types_screen .align_row {
    flex-direction: column;
    align-items: flex-start;
  }
  .discount_types_screen .align_row .table_inline_form {
    grid-template-columns: 100%;
    margin-top: 20px;
  }
  .discount_types_screen .align_row .table_inline_form .input_holder {
    width: 100% !important;
  }
}
.discount_types_screen .discounts_type_form {
  width: auto;
  display: grid;
  grid-template-columns: 250px 300px auto auto auto auto;
  grid-gap: 20px;
}
@media only screen and (max-width: 1000px) {
  .discount_types_screen .discounts_type_form {
    grid-template-columns: 200px 250px auto auto auto auto;
  }
}
@media only screen and (max-width: 1000px) {
  .discount_types_screen .discounts_type_form {
    grid-template-columns: 150px 250px auto auto auto auto;
  }
}
.discount_types_screen .discounts_type_form button {
  margin: 0;
}
@media only screen and (max-width: 720px) {
  .discount_types_screen .discounts_type_form button {
    width: 150px;
  }
}
.discount_types_screen .table-discountTypes .header,
.discount_types_screen .table-discountTypes .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 400px 1fr 120px;
}
@media only screen and (max-width: 600px) {
  .discount_types_screen .table-discountTypes .header,
  .discount_types_screen .table-discountTypes .table_row {
    grid-template-columns: 1fr 70px 120px;
  }
}
.discount_types_screen .table-discountTypes .header > .table_cell:last-child,
.discount_types_screen .table-discountTypes .table_row > .table_cell:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 500px) {
  .discount_types_screen .table_helper .align_row .table_inline_form {
    width: calc(100% - 100px);
  }
  .discount_types_screen .table_helper .align_row .table_inline_form .input_holder {
    width: calc(100% - 65px);
  }
  .discount_types_screen .table_helper .align_row .table_inline_form button {
    margin-left: 10px;
  }
}
.discount_types_screen .inline_update_form {
  display: flex;
}
.discount_types_screen .inline_update_form .input_holder {
  width: 200px;
}
.discount_types_screen .inline_update_form label {
  display: none;
}
.discount_types_screen .inline_update_form .product_brand_field {
  width: 300px;
}
@media only screen and (max-width: 850px) {
  .discount_types_screen .inline_update_form .product_brand_field {
    width: 250px;
  }
}
@media only screen and (max-width: 650px) {
  .discount_types_screen .inline_update_form .product_brand_field {
    width: 100%;
  }
}

@media only screen and (max-width: 720px) {
  .catalog_stickers_screen .align_row {
    flex-direction: column;
    align-items: flex-start;
  }
  .catalog_stickers_screen .align_row .table_inline_form {
    grid-template-columns: 100%;
    margin-top: 20px;
  }
  .catalog_stickers_screen .align_row .table_inline_form .input_holder {
    width: 100% !important;
  }
}
.catalog_stickers_screen .catalog_sticker_form {
  width: auto;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  grid-gap: 20px;
}
@media only screen and (max-width: 1000px) {
  .catalog_stickers_screen .catalog_sticker_form {
    grid-template-columns: auto auto auto auto auto auto auto;
  }
}
@media only screen and (max-width: 1000px) {
  .catalog_stickers_screen .catalog_sticker_form {
    grid-template-columns: auto auto auto auto auto auto auto;
  }
}
.catalog_stickers_screen .catalog_sticker_form button {
  margin: 0;
  text-transform: capitalize;
}
@media only screen and (max-width: 720px) {
  .catalog_stickers_screen .catalog_sticker_form button {
    width: 150px;
  }
}
.catalog_stickers_screen .table-catalogStickers .header,
.catalog_stickers_screen .table-catalogStickers .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 400px auto 130px 200px 130px 130px 120px;
}
@media only screen and (max-width: 600px) {
  .catalog_stickers_screen .table-catalogStickers .header,
  .catalog_stickers_screen .table-catalogStickers .table_row {
    grid-template-columns: 1fr 70px 130px 200px 130px 130px 120px;
  }
}
.catalog_stickers_screen .table-catalogStickers .header > .table_cell:last-child,
.catalog_stickers_screen .table-catalogStickers .table_row > .table_cell:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 500px) {
  .catalog_stickers_screen .table_helper .align_row .table_inline_form {
    width: calc(100% - 100px);
  }
  .catalog_stickers_screen .table_helper .align_row .table_inline_form .input_holder {
    width: calc(100% - 65px);
  }
  .catalog_stickers_screen .table_helper .align_row .table_inline_form button {
    margin-left: 10px;
  }
}
.catalog_stickers_screen .inline_update_form {
  display: flex;
  justify-content: space-between;
}
.catalog_stickers_screen .inline_update_form .input_holder {
  width: 200px;
}
.catalog_stickers_screen .inline_update_form label {
  display: none;
}
.catalog_stickers_screen .inline_update_form .product_brand_field {
  width: 300px;
}
@media only screen and (max-width: 850px) {
  .catalog_stickers_screen .inline_update_form .product_brand_field {
    width: 250px;
  }
}
@media only screen and (max-width: 650px) {
  .catalog_stickers_screen .inline_update_form .product_brand_field {
    width: 100%;
  }
}

.table-invoices .header,
.table-invoices .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 40px 250px 1fr 170px 100px;
}
@media only screen and (max-width: 1350px) {
  .table-invoices .header,
  .table-invoices .table_row {
    grid-template-columns: 40px 250px 1fr 170px 100px;
  }
}
@media only screen and (max-width: 1000px) {
  .table-invoices .header,
  .table-invoices .table_row {
    grid-template-columns: 1fr;
  }
}
.table-invoices .header .table_cell:nth-child(1) a,
.table-invoices .table_row .table_cell:nth-child(1) a {
  color: #E10770;
}
.table-invoices .header .table_cell:last-child,
.table-invoices .table_row .table_cell:last-child {
  position: relative;
  overflow: unset !important;
}
@media only screen and (max-width: 1000px) {
  .table-invoices .header {
    padding-left: 45px !important;
    grid-template-columns: 250px 450px 100px;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 960px) {
  .table-invoices .header {
    grid-template-columns: 250px 450px;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 860px) {
  .table-invoices .header {
    grid-template-columns: 200px 400px;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 770px) {
  .table-invoices .header {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 500px) {
  .table-invoices .header {
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 1000px) {
  .table-invoices .header .table_cell {
    display: none;
  }
  .table-invoices .header .table_cell:nth-child(2) {
    display: flex !important;
  }
  .table-invoices .header .table_cell:nth-child(3) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 770px) {
  .table-invoices .header .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-invoices .header .table_cell:nth-child(4) {
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 960px) {
  .table-invoices .header .table_cell:nth-child(4) {
    display: none !important;
  }
}
.table-invoices .table_row {
  cursor: pointer;
}
@media only screen and (max-width: 1000px) {
  .table-invoices .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 10px !important;
  }
  .table-invoices .table_row .expand_button {
    display: block;
  }
  .table-invoices .table_row .table_cell {
    display: none;
  }
  .table-invoices .table_row .table_cell:nth-child(3) {
    width: 250px !important;
    display: flex !important;
    flex-direction: column;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 860px) {
  .table-invoices .table_row .table_cell:nth-child(3) {
    width: 200px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-invoices .table_row .table_cell:nth-child(4) {
    width: 450px !important;
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 860px) {
  .table-invoices .table_row .table_cell:nth-child(4) {
    width: 400px !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 770px) {
  .table-invoices .table_row .table_cell:nth-child(4) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-invoices .table_row .table_cell:nth-child(5) {
    width: 100px !important;
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 960px) {
  .table-invoices .table_row .table_cell:nth-child(5) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-invoices .mobile_table_wrapper {
    display: block;
  }
  .table-invoices .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
  .table-invoices .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 770px) {
  .table-invoices .mobile_table_wrapper .mobile_table_row:nth-child(2) {
    display: grid;
  }
}
@media only screen and (max-width: 1000px) {
  .table-invoices .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: none;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 960px) {
  .table-invoices .mobile_table_wrapper .mobile_table_row:nth-child(3) {
    display: grid;
  }
}

.invoices_general_details_wrapper .invoices_general_details_holder .top_part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.invoices_general_details_wrapper .invoices_general_details_holder .top_part h2 {
  font-weight: 300;
  font-size: 20px;
}
@media only screen and (max-width: 700px) {
  .invoices_general_details_wrapper .invoices_general_details_holder .top_part h2 {
    font-size: 18px;
  }
}
.invoices_general_details_wrapper .invoices_general_details_holder .is_connected {
  border-bottom: 1px solid #f1f1f1;
  padding: 10px 0 20px 0;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 18px;
}
@media only screen and (max-width: 700px) {
  .invoices_general_details_wrapper .invoices_general_details_holder .is_connected {
    font-size: 14px;
  }
}
.invoices_general_details_wrapper .invoices_general_details_holder .is_connected a {
  color: #E10770;
  font-weight: 300;
  font-size: 18px;
}
@media only screen and (max-width: 700px) {
  .invoices_general_details_wrapper .invoices_general_details_holder .is_connected a {
    font-size: 14px;
  }
}
.invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 50px;
}
@media only screen and (max-width: 1400px) {
  .invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content {
    grid-template-columns: 100%;
    grid-gap: 50px 0;
  }
}
.invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .invoice_details,
.invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .internal_details {
  width: 100%;
}
.invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .invoice_details .detail_item,
.invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .internal_details .detail_item {
  width: 100%;
  display: grid;
  grid-template-columns: 130px 1fr;
  align-items: flex-start;
  padding: 10px 0;
  grid-gap: 0 10px;
}
@media only screen and (max-width: 500px) {
  .invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .invoice_details .detail_item,
  .invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .internal_details .detail_item {
    grid-template-columns: 110px 1fr;
  }
}
.invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .invoice_details .detail_item .label,
.invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .internal_details .detail_item .label {
  font-weight: 500;
  color: #3a3a3a;
  margin-right: 5px;
  font-size: 14px;
  line-height: 1.6;
}
@media only screen and (max-width: 500px) {
  .invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .invoice_details .detail_item .label,
  .invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .internal_details .detail_item .label {
    font-size: 12px;
  }
}
.invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .invoice_details .detail_item .value,
.invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .internal_details .detail_item .value {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.6;
}
@media only screen and (max-width: 1400px) {
  .invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .invoice_details .detail_item .value,
  .invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .internal_details .detail_item .value {
    word-break: break-all;
  }
}
@media only screen and (max-width: 500px) {
  .invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .invoice_details .detail_item .value,
  .invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .internal_details .detail_item .value {
    font-size: 12px;
  }
}
.invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .internal_details .detail_item {
  grid-template-columns: 120px 1fr;
}
@media only screen and (max-width: 1400px) {
  .invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .internal_details .detail_item {
    grid-template-columns: 130px 1fr;
  }
}
@media only screen and (max-width: 500px) {
  .invoices_general_details_wrapper .invoices_general_details_holder .invoices_general_details_content .internal_details .detail_item {
    grid-template-columns: 110px 1fr;
  }
}

.ivoices_info_internal_details .detail_item {
  display: grid;
  grid-template-columns: 130px 1fr;
  height: 60px;
  grid-gap: 0 10px;
  align-items: center;
  font-size: 14px;
  line-height: 1.6;
  transition: all 0.2s ease;
}
.ivoices_info_internal_details .detail_item:hover .content_holder .edit {
  opacity: 1;
}
@media only screen and (max-width: 750px) {
  .ivoices_info_internal_details .detail_item {
    font-size: 12px;
    grid-template-columns: 70px 1fr;
  }
}
.ivoices_info_internal_details .detail_item .value {
  font-weight: 300;
}
.ivoices_info_internal_details .detail_item .content_holder {
  display: flex;
  align-items: center;
}
.ivoices_info_internal_details .detail_item .content_holder button {
  margin-left: 5px;
}
.ivoices_info_internal_details .detail_item .content_holder .edit {
  transition: all 0.2s ease;
  opacity: 0;
}
@media only screen and (max-width: 600px) {
  .ivoices_info_internal_details .detail_item .content_holder .edit {
    opacity: 1;
  }
}
.ivoices_info_internal_details .detail_item input {
  width: 500px;
  font-size: 13px;
  border-radius: 5px;
  padding: 10px 20px;
  background: transparent;
  color: #333e48;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 900px) {
  .ivoices_info_internal_details .detail_item input {
    width: calc(100% - 50px);
    font-size: 12px;
    padding: 10px;
  }
}
.ivoices_info_internal_details .detail_item .dropdown {
  width: 300px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 0 10px;
  position: relative;
  font-size: 14px;
  font-weight: 400;
}
@media only screen and (max-width: 500px) {
  .ivoices_info_internal_details .detail_item .dropdown {
    width: 100%;
    height: 38px;
    font-size: 12px;
  }
}
.ivoices_info_internal_details .detail_item .dropdown .dropdown_selected {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #333e48;
}
.ivoices_info_internal_details .detail_item .dropdown .dropdown_selected svg {
  color: rgba(0, 0, 0, 0.4);
}
.ivoices_info_internal_details .detail_item .dropdown .dropdown_items {
  position: absolute;
  left: 0;
  right: 0;
  top: 110%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  z-index: 2;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}
.ivoices_info_internal_details .detail_item .dropdown .dropdown_items li {
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.ivoices_info_internal_details .detail_item .dropdown .dropdown_items li:hover {
  background: #f8f8f8;
}
.ivoices_info_internal_details .detail_item .dropdown .dropdown_items li.active {
  color: #333e48;
  background: #f8f8f8;
}

.table-invoices_emails .header,
.table-invoices_emails .table_row {
  display: grid;
  grid-template-columns: 1fr 60px 70px;
  align-items: center;
}
@media only screen and (max-width: 500px) {
  .table-invoices_emails .header,
  .table-invoices_emails .table_row {
    grid-template-columns: 1fr 50px 70px;
  }
}
@media only screen and (max-width: 500px) {
  .table-invoices_emails .table_helper .align_row .table_inline_form {
    width: calc(100% - 100px);
  }
  .table-invoices_emails .table_helper .align_row .table_inline_form .input_holder {
    width: calc(100% - 65px);
  }
  .table-invoices_emails .table_helper .align_row .table_inline_form button {
    margin-left: 10px;
  }
}

.table-invoices_banks .header,
.table-invoices_banks .table_row {
  display: grid;
  grid-template-columns: 400px 1fr 60px 70px;
  align-items: center;
}
@media only screen and (max-width: 1000px) {
  .table-invoices_banks .header,
  .table-invoices_banks .table_row {
    grid-template-columns: 200px 1fr 50px 70px;
  }
}
@media only screen and (max-width: 700px) {
  .table-invoices_banks .header,
  .table-invoices_banks .table_row {
    grid-template-columns: 1fr 1fr 50px 70px;
  }
}

@media only screen and (max-width: 700px) {
  .invoices_banks_page .table_helper .align_row {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0 !important;
  }
}
.invoices_banks_page .table_helper .align_row .table_inline_form_with_two_inputs {
  width: 100% !important;
}
@media only screen and (max-width: 700px) {
  .invoices_banks_page .table_helper .align_row .table_inline_form_with_two_inputs {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 70px;
    grid-gap: 0 10px;
  }
}
@media only screen and (max-width: 500px) {
  .invoices_banks_page .table_helper .align_row .table_inline_form_with_two_inputs {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}
.invoices_banks_page .table_helper .align_row .table_inline_form_with_two_inputs .input_holder {
  margin-right: 20px;
}
@media only screen and (max-width: 700px) {
  .invoices_banks_page .table_helper .align_row .table_inline_form_with_two_inputs .input_holder {
    margin: 0;
  }
}
.invoices_banks_page .table_helper .align_row .table_inline_form_with_two_inputs .input_holder:nth-child(2) {
  margin-right: 0;
}
@media only screen and (max-width: 700px) {
  .invoices_banks_page .table_helper .align_row .table_inline_form_with_two_inputs .button {
    margin: 0;
  }
}
@media only screen and (max-width: 500px) {
  .invoices_banks_page .table_helper .align_row .table_inline_form_with_two_inputs .button {
    width: 100px;
  }
}
.invoices_banks_page .inline_update_form {
  display: grid;
  grid-template-columns: 220px 1fr 60px;
}
@media only screen and (max-width: 800px) {
  .invoices_banks_page .inline_update_form {
    grid-template-columns: 160px 1fr 60px;
    grid-gap: 0 10px;
  }
}
@media only screen and (max-width: 500px) {
  .invoices_banks_page .inline_update_form {
    grid-template-columns: 100px 1fr 60px;
  }
}
@media only screen and (max-width: 350px) {
  .invoices_banks_page .inline_update_form {
    grid-template-columns: 90px 1fr 60px;
    grid-gap: 0 5px;
  }
}
@media only screen and (max-width: 800px) {
  .invoices_banks_page .inline_update_form .input_holder {
    width: 160px !important;
  }
}
@media only screen and (max-width: 500px) {
  .invoices_banks_page .inline_update_form .input_holder {
    width: 100px !important;
  }
}
@media only screen and (max-width: 350px) {
  .invoices_banks_page .inline_update_form .input_holder {
    width: 90px !important;
  }
  .invoices_banks_page .inline_update_form .input_holder input {
    font-size: 12px;
    padding: 0 5px;
  }
}

.important_tooltip {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 38px;
}

.cms_banners_page .table-banners .header,
.cms_banners_page .table-banners .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 70px 300px 1fr 70px;
}
@media only screen and (max-width: 700px) {
  .cms_banners_page .table-banners .header,
  .cms_banners_page .table-banners .table_row {
    grid-template-columns: 70px 90px 1fr 70px;
  }
}
.cms_banners_page .skeleton_inner .skeleton_content {
  grid-template-columns: 300px 1fr 30px 30px;
}
@media only screen and (max-width: 700px) {
  .cms_banners_page .skeleton_inner .skeleton_content {
    grid-template-columns: 100px 1fr 30px 30px;
  }
}
@media only screen and (max-width: 900px) {
  .cms_banners_page .table_helper {
    flex-direction: column;
    align-items: start !important;
  }
  .cms_banners_page .table_helper .align_row:nth-child(1) {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 900px) and (max-width: 750px) {
  .cms_banners_page .table_helper .align_row:nth-child(1) {
    margin-bottom: 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0;
  }
}
@media only screen and (max-width: 900px) and (max-width: 550px) {
  .cms_banners_page .table_helper .align_row:nth-child(1) {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}
@media only screen and (max-width: 900px) and (max-width: 750px) {
  .cms_banners_page .table_helper .align_row:nth-child(2) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0 20px;
  }
}
.cms_banners_page .table_helper .left_side {
  display: flex;
  align-items: center;
}
.cms_banners_page .table_helper .left_side .positions_dropdown_inner {
  width: 250px;
}
@media only screen and (max-width: 750px) {
  .cms_banners_page .table_helper .left_side .positions_dropdown_inner {
    width: 100%;
  }
}
.cms_banners_page .positions_dropdown_inner {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  position: relative;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
}
.cms_banners_page .positions_dropdown_inner.active .positions_dropdown_outer svg {
  transform: rotate(180deg);
}
.cms_banners_page .positions_dropdown_inner .positions_dropdown_outer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  cursor: pointer;
}
.cms_banners_page .positions_dropdown_inner .positions_dropdown_outer svg {
  transition: all 0.2s ease;
}
.cms_banners_page .positions_dropdown_inner .positions_dropdown_outer span {
  color: #333e48;
}
.cms_banners_page .positions_dropdown_inner .positions_dropdown_items_wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 110%;
  background: #fff;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.cms_banners_page .positions_dropdown_inner .positions_dropdown_items_wrap .positions_dropdown_item {
  padding: 8px 20px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  font-size: 13px;
  background: #fff;
  transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.cms_banners_page .positions_dropdown_inner .positions_dropdown_items_wrap .positions_dropdown_item:hover {
  background: #f8f8f8;
}
.cms_banners_page .positions_dropdown_inner .positions_dropdown_items_wrap .positions_dropdown_item.active {
  color: #333e48;
  background: #f8f8f8;
}
.cms_banners_page .position_field {
  position: relative;
  height: 40px;
}
.cms_banners_page .position_field p {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  transform: translate(-10px, -35px);
  font-size: 0.7rem;
}
.cms_banners_page .dropzone_wrapper {
  margin-bottom: 50px;
}
.cms_banners_page .dropzone_wrapper .input_file_holder {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  height: 200px !important;
  position: relative;
}
.cms_banners_page .dropzone_wrapper .input_file_holder:hover .placeholder {
  display: flex;
}
.cms_banners_page .dropzone_wrapper .input_file_holder:hover .text {
  opacity: 0;
}
.cms_banners_page .dropzone_wrapper .input_file_holder .placeholder {
  position: absolute;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  transition: all 0.2s ease;
}
.cms_banners_page .dropzone_wrapper .input_file_holder .placeholder svg {
  color: #fff;
  width: 40px;
  height: 40px;
}
.cms_banners_page .dropzone_wrapper .input_file_holder .text {
  transition: all 0.2s ease;
}
.cms_banners_page .dropzone_wrapper .input_file_holder .delete_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  color: rgb(234, 84, 85);
  width: 30px;
  height: 30px;
}

.cms_home_screen .paginate {
  display: none !important;
}
.cms_home_screen .align_row {
  width: 100%;
}
.cms_home_screen .products_dropdown {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .cms_home_screen .products_dropdown {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0 10px;
  }
}
.cms_home_screen .products_dropdown .search_select_wrapper {
  width: 300px;
  margin-right: 20px;
}
@media only screen and (max-width: 600px) {
  .cms_home_screen .products_dropdown .search_select_wrapper {
    width: 100%;
    margin: 0;
  }
  .cms_home_screen .products_dropdown .search_select_wrapper .search_select_holder .root p {
    font-size: 10px;
  }
}
.cms_home_screen .home_screen_products_table_inner .header {
  background: #e7e3e3;
  height: 50px;
  padding: 0 10px;
}
.cms_home_screen .home_screen_products_table_inner .table_row {
  position: relative;
  height: 50px;
  padding: 0 10px;
}
.cms_home_screen .home_screen_products_table_inner .table_cell {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 1600px) {
  .cms_home_screen .home_screen_products_table_inner .table_cell {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .cms_home_screen .home_screen_products_table_inner .table_cell {
    font-size: 11px;
  }
}
.cms_home_screen .home_screen_products_table_inner .home_screen_products_table_outer > div > div:nth-child(even) {
  background: #f8f8f8;
}
.cms_home_screen .home_screen_products_table_inner .header,
.cms_home_screen .home_screen_products_table_inner .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 35px 200px 1fr 80px 40px;
}
@media only screen and (max-width: 900px) {
  .cms_home_screen .home_screen_products_table_inner .header,
  .cms_home_screen .home_screen_products_table_inner .table_row {
    grid-template-columns: 35px 150px 1fr 80px 40px;
  }
}
@media only screen and (max-width: 500px) {
  .cms_home_screen .home_screen_products_table_inner .header,
  .cms_home_screen .home_screen_products_table_inner .table_row {
    grid-template-columns: 35px 100px 1fr 30px 30px;
  }
}
.cms_home_screen .home_screen_categories_table_inner .header {
  background: #e7e3e3;
  height: 50px;
  padding: 0 10px;
}
.cms_home_screen .home_screen_categories_table_inner .table_row {
  position: relative;
  height: 50px;
  padding: 0 10px;
}
.cms_home_screen .home_screen_categories_table_inner .table_cell {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 1600px) {
  .cms_home_screen .home_screen_categories_table_inner .table_cell {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .cms_home_screen .home_screen_categories_table_inner .table_cell {
    font-size: 11px;
  }
}
.cms_home_screen .home_screen_categories_table_inner .home_screen_categories_table_outer > div > div:nth-child(even) {
  background: #f8f8f8;
}
.cms_home_screen .home_screen_categories_table_inner .header,
.cms_home_screen .home_screen_categories_table_inner .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 35px 1fr 80px 70px;
}
@media only screen and (max-width: 500px) {
  .cms_home_screen .home_screen_categories_table_inner .header,
  .cms_home_screen .home_screen_categories_table_inner .table_row {
    grid-template-columns: 35px 1fr 30px 70px;
  }
}
.cms_home_screen .skeleton_inner .skeleton_content {
  grid-template-columns: 35px 200px 1fr 80px 40px;
}
@media only screen and (max-width: 900px) {
  .cms_home_screen .skeleton_inner .skeleton_content {
    grid-template-columns: 35px 150px 1fr 80px 40px;
  }
}
@media only screen and (max-width: 500px) {
  .cms_home_screen .skeleton_inner .skeleton_content {
    grid-template-columns: 35px 100px 1fr 30px 30px;
  }
}
.cms_home_screen .pagination_nav {
  display: none !important;
}

.home_screen_categories_modal_inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_screen_categories_modal_inner .home_screen_categories_modal_outer {
  background: #fff;
  width: 350px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  position: relative;
  padding: 30px;
}
@media only screen and (max-width: 550px) {
  .home_screen_categories_modal_inner .home_screen_categories_modal_outer {
    width: 90%;
  }
}
.home_screen_categories_modal_inner .home_screen_categories_modal_outer .cancel_icon {
  position: absolute;
  top: -30px;
  right: -30px;
  width: 35px;
  height: 35px;
  color: rgb(234, 84, 85);
  cursor: pointer;
}
@media only screen and (max-width: 550px) {
  .home_screen_categories_modal_inner .home_screen_categories_modal_outer .cancel_icon {
    display: none;
  }
}
.home_screen_categories_modal_inner .home_screen_categories_modal_outer .dropzone_wrapper {
  width: 100%;
}
.home_screen_categories_modal_inner .home_screen_categories_modal_outer .dropzone_wrapper .input_file_holder {
  width: 200px;
  height: 200px;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  position: relative;
  cursor: pointer;
  margin: 0 auto 30px auto;
}
.home_screen_categories_modal_inner .home_screen_categories_modal_outer .dropzone_wrapper .input_file_holder input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.home_screen_categories_modal_inner .home_screen_categories_modal_outer .dropzone_wrapper .input_file_holder span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}
.home_screen_categories_modal_inner .home_screen_categories_modal_outer .dropzone_wrapper .photo {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  position: relative;
  margin-bottom: 20px;
}
.home_screen_categories_modal_inner .home_screen_categories_modal_outer .dropzone_wrapper .photo svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(234, 84, 85);
  font-size: 1rem;
  cursor: pointer;
  font-size: 24px;
}
.home_screen_categories_modal_inner .home_screen_categories_modal_outer .buttons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cms_home_screen_sections .paginate {
  display: none !important;
}
.cms_home_screen_sections .pagination_nav {
  display: none !important;
}
.cms_home_screen_sections .skeleton_inner .skeleton_content {
  grid-template-columns: 35px 1fr 25px 25px;
}
.cms_home_screen_sections .home_screen_sections_table_inner .header {
  background: #e7e3e3;
  height: 50px;
  padding: 0 10px;
}
.cms_home_screen_sections .home_screen_sections_table_inner .table_row {
  position: relative;
  height: 50px;
  padding: 0 10px;
}
.cms_home_screen_sections .home_screen_sections_table_inner .table_cell,
.cms_home_screen_sections .home_screen_sections_table_inner a {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 1600px) {
  .cms_home_screen_sections .home_screen_sections_table_inner .table_cell,
  .cms_home_screen_sections .home_screen_sections_table_inner a {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .cms_home_screen_sections .home_screen_sections_table_inner .table_cell,
  .cms_home_screen_sections .home_screen_sections_table_inner a {
    font-size: 11px;
  }
}
.cms_home_screen_sections .home_screen_sections_table_inner .home_screen_sections_table_outer > div > div:nth-child(even) {
  background: #f8f8f8;
}
.cms_home_screen_sections .home_screen_sections_table_inner .header,
.cms_home_screen_sections .home_screen_sections_table_inner .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 35px 1fr 80px 70px;
}
@media only screen and (max-width: 500px) {
  .cms_home_screen_sections .home_screen_sections_table_inner .header,
  .cms_home_screen_sections .home_screen_sections_table_inner .table_row {
    grid-template-columns: 35px 1fr 35px 70px;
  }
}

.home_screen_sections .list_of_updated_items {
  margin-top: 20px;
  background: #f8f8f8;
  border-radius: 8px;
}
.home_screen_sections .list_of_updated_items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 10px 20px;
  border-bottom: 1px solid #fff;
}
.home_screen_sections .list_of_updated_items .item:last-child {
  border-bottom: 0;
}
.home_screen_sections .list_of_updated_items .item svg {
  color: rgb(234, 84, 85);
  cursor: pointer;
}

.cms_sections_show_section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 30px;
}
@media only screen and (max-width: 1200px) {
  .cms_sections_show_section {
    grid-template-columns: 100%;
    grid-gap: 0;
  }
}
.cms_sections_show_section .cms_page {
  margin-bottom: 20px;
}
.cms_sections_show_section .cms_page:nth-child(2) .data_no_found {
  display: none !important;
}
.cms_sections_show_section .skeleton_inner .skeleton_content {
  grid-template-columns: 35px 1fr 25px 25px;
}
.cms_sections_show_section .home_screen_products_table_inner .header {
  background: #e7e3e3;
  height: 50px;
  padding: 0 10px;
}
.cms_sections_show_section .home_screen_products_table_inner .table_row {
  position: relative;
  height: 50px;
  padding: 0 10px;
}
.cms_sections_show_section .home_screen_products_table_inner .table_cell {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 1600px) {
  .cms_sections_show_section .home_screen_products_table_inner .table_cell {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .cms_sections_show_section .home_screen_products_table_inner .table_cell {
    font-size: 11px;
  }
}
.cms_sections_show_section .home_screen_products_table_inner .home_screen_products_table_outer > div > div:nth-child(even) {
  background: #f8f8f8;
}
.cms_sections_show_section .home_screen_products_table_inner .header,
.cms_sections_show_section .home_screen_products_table_inner .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 35px 170px 1fr 80px 40px;
}
@media only screen and (max-width: 1400px) {
  .cms_sections_show_section .home_screen_products_table_inner .header,
  .cms_sections_show_section .home_screen_products_table_inner .table_row {
    grid-template-columns: 35px 120px 1fr 40px 30px;
  }
}
@media only screen and (max-width: 900px) {
  .cms_sections_show_section .home_screen_products_table_inner .header,
  .cms_sections_show_section .home_screen_products_table_inner .table_row {
    grid-template-columns: 35px 150px 1fr 80px 40px;
  }
}
@media only screen and (max-width: 500px) {
  .cms_sections_show_section .home_screen_products_table_inner .header,
  .cms_sections_show_section .home_screen_products_table_inner .table_row {
    grid-template-columns: 35px 100px 1fr 30px 30px;
  }
}
.cms_sections_show_section .home_screen_categories_table_inner .header {
  background: #e7e3e3;
  height: 50px;
  padding: 0 10px;
}
.cms_sections_show_section .home_screen_categories_table_inner .table_row {
  position: relative;
  height: 50px;
  padding: 0 10px;
}
.cms_sections_show_section .home_screen_categories_table_inner .table_cell {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 1600px) {
  .cms_sections_show_section .home_screen_categories_table_inner .table_cell {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .cms_sections_show_section .home_screen_categories_table_inner .table_cell {
    font-size: 11px;
  }
}
.cms_sections_show_section .home_screen_categories_table_inner .home_screen_categories_table_outer > div > div:nth-child(even) {
  background: #f8f8f8;
}
.cms_sections_show_section .home_screen_categories_table_inner .header,
.cms_sections_show_section .home_screen_categories_table_inner .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 35px 1fr 80px 40px;
}
@media only screen and (max-width: 1400px) {
  .cms_sections_show_section .home_screen_categories_table_inner .header,
  .cms_sections_show_section .home_screen_categories_table_inner .table_row {
    grid-template-columns: 35px 1fr 40px 30px;
  }
}
@media only screen and (max-width: 500px) {
  .cms_sections_show_section .home_screen_categories_table_inner .header,
  .cms_sections_show_section .home_screen_categories_table_inner .table_row {
    grid-template-columns: 35px 1fr 30px 40px;
  }
}
.cms_sections_show_section .products_dropdown {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .cms_sections_show_section .products_dropdown .search_select_wrapper .search_select_holder .root p {
    font-size: 12px;
  }
}
.cms_sections_show_section .error_msg.custom {
  margin: -10px 0 10px 0;
}

.cms_sections_show_section_box {
  margin-bottom: 30px;
}
.cms_sections_show_section_box .update_name_form {
  padding: 20px 0 20px 0;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0 20px;
  position: relative;
}
.cms_sections_show_section_box .update_name_form .error_msg {
  position: absolute;
  left: 0;
  bottom: -10px;
}

.top_custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 800px) {
  .top_custom {
    flex-direction: column;
    align-items: flex-start;
  }
  .top_custom .products_dropdown {
    width: 100%;
    justify-content: space-between;
  }
  .top_custom .change_device_dropdown_outer {
    margin: 20px auto 0 0;
  }
}
@media only screen and (max-width: 800px) and (max-width: 500px) {
  .top_custom .change_device_dropdown_outer {
    width: 100%;
  }
}

.cms_home_screen_sections .align_row {
  width: 100%;
}

.cms_page.cms_news_inner .table_row p {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.cms_page.cms_news_inner .header,
.cms_page.cms_news_inner .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 300px 1fr 70px;
}
@media only screen and (max-width: 1000px) {
  .cms_page.cms_news_inner .header,
  .cms_page.cms_news_inner .table_row {
    grid-template-columns: 200px 1fr 70px;
  }
}
@media only screen and (max-width: 600px) {
  .cms_page.cms_news_inner .header,
  .cms_page.cms_news_inner .table_row {
    grid-template-columns: 100px 1fr 70px;
  }
}
.cms_page.cms_news_inner .skeleton_inner .header {
  grid-template-columns: 100%;
}
.cms_page.cms_news_inner .skeleton_inner .skeleton_content {
  grid-template-columns: 300px 1fr 30px 30px;
}
@media only screen and (max-width: 1000px) {
  .cms_page.cms_news_inner .skeleton_inner .skeleton_content {
    grid-template-columns: 200px 1fr 30px 30px;
  }
}
@media only screen and (max-width: 600px) {
  .cms_page.cms_news_inner .skeleton_inner .skeleton_content {
    grid-template-columns: 100px 1fr 30px 30px;
  }
}

.add_cms_news_inner .form_holder {
  padding-top: 20px;
}
.add_cms_news_inner .input_file_holder {
  height: 200px !important;
  margin: 40px 0 !important;
}
.add_cms_news_inner .input_file_holder .photo {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
.add_cms_news_inner .area_field {
  height: 170px;
}

.cms_auth_page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 999999;
}
.cms_auth_page .container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  background-color: #2B2A28;
}
.cms_auth_page .container .auth_form_holder {
  background-color: #fff;
  width: 370px;
  border-radius: 20px;
  padding: 70px 30px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
@media only screen and (max-width: 450px) {
  .cms_auth_page .container .auth_form_holder {
    width: 90%;
    padding: 50px 20px;
  }
}
.cms_auth_page .container .auth_form_holder .title {
  margin-bottom: 70px;
}
@media only screen and (max-width: 450px) {
  .cms_auth_page .container .auth_form_holder .title {
    margin-bottom: 40px;
  }
}
.cms_auth_page .container .auth_form_holder .title h2 {
  font-weight: bold;
  font-size: 34px;
}
@media only screen and (max-width: 450px) {
  .cms_auth_page .container .auth_form_holder .title h2 {
    font-size: 26px;
  }
}
.cms_auth_page .container .auth_form_holder form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.cms_auth_page .container .auth_form_holder form .input_holder {
  width: 100%;
  margin-bottom: 30px;
}
.cms_auth_page .container .auth_form_holder form .input_holder .form_field input {
  border-radius: 30px;
}
.cms_auth_page .container .auth_form_holder form .input_holder .form_field input:focus {
  border-color: #E10770;
}
.cms_auth_page .container .auth_form_holder form .input_holder .form_field input:focus + label {
  color: #E10770;
}
.cms_auth_page .container .auth_form_holder form .error_msg {
  margin-top: 10px;
}
.cms_auth_page .container .auth_form_holder form button {
  margin-top: 50px;
  border-radius: 30px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  background: linear-gradient(to right, #E10770, #b84d80);
  width: 60%;
  height: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.2s ease;
}
.cms_auth_page .container .auth_form_holder form button:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 450px) {
  .cms_auth_page .container .auth_form_holder form button {
    margin-top: 30px;
  }
}

.google_icon button {
  background-color: #cf4332 !important;
  color: white !important;
  font-size: large !important;
}
.google_icon button div {
  margin-right: 0 !important;
  background-color: #cf4332 !important;
  border-right: 1px solid white;
}
.google_icon button span {
  padding: 0 40px !important;
}

.authorization_screen_wrapper {
  display: grid;
  grid-template-columns: 440px calc(100% - 460px);
}
@media only screen and (max-width: 1450px) {
  .authorization_screen_wrapper {
    grid-template-columns: 100%;
  }
}
.authorization_screen_wrapper .navigation_title {
  margin-bottom: 20px;
}
@media only screen and (max-width: 800px) {
  .authorization_screen_wrapper .navigation_title.last {
    margin-top: 20px;
  }
}
.authorization_screen_wrapper .right_side {
  width: 100%;
  display: grid;
  grid-gap: 0 20px;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 20px;
}
@media only screen and (max-width: 1450px) {
  .authorization_screen_wrapper .right_side {
    margin: 20px 0 0 0;
  }
}
@media only screen and (max-width: 800px) {
  .authorization_screen_wrapper .right_side {
    grid-template-columns: 100%;
  }
}
.authorization_screen_wrapper .authorization_screen .table-authorization .header,
.authorization_screen_wrapper .authorization_screen .table-authorization .table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.authorization_screen_wrapper .authorization_screen .table-authorization .header > .table_cell:last-child,
.authorization_screen_wrapper .authorization_screen .table-authorization .table_row > .table_cell:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.authorization_screen_wrapper .authorization_screen .table-authorization .table_row {
  cursor: pointer;
}
.authorization_screen_wrapper .authorization_screen .table-authorization .table_row.active {
  background: linear-gradient(146deg, #E10770, rgba(225, 7, 112, 0.3), #E10770) !important;
}
.authorization_screen_wrapper .authorization_screen .table-authorization .table_row.active .table_cell {
  color: #fff;
}
.authorization_screen_wrapper .authorization_screen .table-authorization .table_row.active button.icon_button {
  color: #fff;
}
.authorization_screen_wrapper .authorization_screen .table-authorization .table_row.active .inline_update_form button.icon_button {
  color: #b9b9b9;
}
@media only screen and (max-width: 500px) {
  .authorization_screen_wrapper .authorization_screen .table_helper .align_row .table_inline_form {
    width: calc(100% - 100px);
  }
  .authorization_screen_wrapper .authorization_screen .table_helper .align_row .table_inline_form .input_holder {
    width: calc(100% - 65px);
  }
  .authorization_screen_wrapper .authorization_screen .table_helper .align_row .table_inline_form button {
    margin-left: 10px;
  }
}
.authorization_screen_wrapper .permissions_inner div {
  font-size: 14px;
  background: #f8f8f8;
  border-bottom: 1px solid #fff;
  padding: 10px;
  cursor: pointer;
}
.authorization_screen_wrapper .permissions_inner div.active {
  background: linear-gradient(146deg, #E10770, rgba(225, 7, 112, 0.3), #E10770) !important;
  color: #fff;
}
@media only screen and (max-width: 800px) {
  .authorization_screen_wrapper .permissions_inner div {
    font-size: 12px;
  }
}
@media only screen and (max-width: 800px) {
  .authorization_screen_wrapper .preview_inner {
    margin-top: 20px;
  }
}
.authorization_screen_wrapper .preview_inner.box_holder {
  padding-top: 0;
}
.authorization_screen_wrapper .preview_inner h2 {
  font-weight: 500;
  font-size: 20px;
  padding: 30px 0;
  text-align: center;
}
@media only screen and (max-width: 800px) {
  .authorization_screen_wrapper .preview_inner h2 {
    font-size: 16px;
    padding: 20px 0;
  }
}
.authorization_screen_wrapper .preview_inner .preview_box:last-child {
  margin-bottom: 0;
}
.authorization_screen_wrapper .preview_inner .preview_box .item {
  font-size: 14px;
  background: #f8f8f8;
  border-bottom: 1px solid #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
@media only screen and (max-width: 800px) {
  .authorization_screen_wrapper .preview_inner .preview_box .item {
    font-size: 12px;
  }
}
.authorization_screen_wrapper .preview_inner .preview_box .item svg {
  color: rgb(86, 201, 58);
}
@media only screen and (max-width: 800px) {
  .authorization_screen_wrapper .preview_inner .preview_box .item svg {
    font-size: 16px;
  }
}
.authorization_screen_wrapper .preview_inner .preview_box.role .item svg {
  color: rgb(234, 84, 85);
}
.authorization_screen_wrapper .preview_inner .no_result {
  font-size: 14px;
  background: #f8f8f8;
  border-bottom: 1px solid #fff;
  padding: 10px;
  justify-content: flex-start;
}
@media only screen and (max-width: 800px) {
  .authorization_screen_wrapper .preview_inner .no_result {
    font-size: 12px;
  }
}

.cms_newsletter .skeleton_inner .skeleton_content {
  grid-template-columns: 200px 1fr 25px;
}
@media only screen and (max-width: 900px) {
  .cms_newsletter .skeleton_inner .skeleton_content {
    grid-template-columns: 150px 1fr 25px;
  }
}
@media only screen and (max-width: 500px) {
  .cms_newsletter .skeleton_inner .skeleton_content {
    grid-template-columns: 100px 1fr 25px;
  }
}
.cms_newsletter .top_custom .products_dropdown {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .cms_newsletter .top_custom .products_dropdown {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0 10px;
  }
}
.cms_newsletter .top_custom .products_dropdown .search_select_wrapper {
  width: 250px;
  margin-right: 20px;
}
@media only screen and (max-width: 600px) {
  .cms_newsletter .top_custom .products_dropdown .search_select_wrapper {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .cms_newsletter .align_row {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0 !important;
  }
  .cms_newsletter .align_row .top_custom {
    margin: 20px 0 0 0;
  }
}
.cms_newsletter .table-newsletter .header,
.cms_newsletter .table-newsletter .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 300px 1fr 35px;
}
@media only screen and (max-width: 700px) {
  .cms_newsletter .table-newsletter .header,
  .cms_newsletter .table-newsletter .table_row {
    grid-template-columns: 110px 1fr 35px;
  }
}

.table-ratings .header,
.table-ratings .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 250px 300px 100px 1fr 130px 70px;
}
@media only screen and (max-width: 1500px) {
  .table-ratings .header,
  .table-ratings .table_row {
    grid-template-columns: 200px 250px 100px 1fr 120px 70px;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ratings .header,
  .table-ratings .table_row {
    grid-template-columns: 200px 250px 1fr 70px;
  }
}
@media only screen and (max-width: 800px) {
  .table-ratings .header,
  .table-ratings .table_row {
    grid-template-columns: 1fr 1fr 70px;
  }
}
.table-ratings .header .table_cell .align_row,
.table-ratings .table_row .table_cell .align_row {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 0 10px;
}
.table-ratings .header .table_cell .ticket,
.table-ratings .table_row .table_cell .ticket {
  background: rgba(234, 84, 85, 0.15);
  color: #ea5455;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 100px;
}
.table-ratings .header .table_cell .ticket.active,
.table-ratings .table_row .table_cell .ticket.active {
  background: rgba(40, 199, 111, 0.15);
  color: #28c76f;
}
.table-ratings .table_row {
  cursor: pointer;
}
@media only screen and (max-width: 1000px) {
  .table-ratings .header {
    padding-left: 45px !important;
  }
  .table-ratings .header .table_cell {
    display: none;
  }
  .table-ratings .header .table_cell:nth-child(1) {
    display: flex !important;
    width: 200px !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 800px) {
  .table-ratings .header .table_cell:nth-child(1) {
    flex: 1 !important;
    width: auto !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ratings .header .table_cell:nth-child(2) {
    display: flex !important;
    width: 250px !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 800px) {
  .table-ratings .header .table_cell:nth-child(2) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ratings .header .table_cell:nth-child(3) {
    display: flex !important;
    flex: 1 !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ratings .header .table_cell:nth-child(6) {
    width: 70px !important;
    display: flex !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ratings .table_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: unset !important;
    padding: 8px 10px !important;
  }
  .table-ratings .table_row .expand_button {
    display: block;
  }
  .table-ratings .table_row .table_cell {
    display: none;
  }
  .table-ratings .table_row .table_cell:nth-child(1) {
    display: flex !important;
    width: 250px !important;
  }
  .table-ratings .table_row .table_cell:nth-child(2) {
    margin-left: 10px;
    display: flex !important;
    width: 200px !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 800px) {
  .table-ratings .table_row .table_cell:nth-child(2) {
    flex: 1 !important;
    width: auto !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ratings .table_row .table_cell:nth-child(3) {
    display: flex !important;
    width: 250px !important;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 800px) {
  .table-ratings .table_row .table_cell:nth-child(3) {
    display: none !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ratings .table_row .table_cell:nth-child(4) {
    display: flex !important;
    flex: 1 !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ratings .table_row .table_cell:nth-child(7) {
    display: flex !important;
    width: 70px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .table-ratings .mobile_table_wrapper {
    display: block;
  }
  .table-ratings .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: none;
  }
}
@media only screen and (max-width: 1000px) and (max-width: 800px) {
  .table-ratings .mobile_table_wrapper .mobile_table_row:nth-child(1) {
    display: grid;
  }
}

.show_rating_page .item_row {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  grid-gap: 0 10px;
  padding: 10px 0;
}
.show_rating_page .item_row .label {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .show_rating_page .item_row .label {
    font-size: 12px;
  }
}
.show_rating_page .item_row .content {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .show_rating_page .item_row .content {
    font-size: 12px;
  }
}
.show_rating_page .item_row .content .avatar {
  margin-right: 10px;
}
.show_rating_page .item_row .content .ticket {
  background: rgba(234, 84, 85, 0.15);
  color: #ea5455;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 100px;
}
.show_rating_page .item_row .content .ticket.active {
  background: rgba(40, 199, 111, 0.15);
  color: #28c76f;
}
.show_rating_page .item_row .content a {
  color: #E10770;
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .show_rating_page .item_row .content a {
    font-size: 12px;
  }
}
.show_rating_page .buttons {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: end;
  grid-gap: 10px;
}

.questions_and_answers_cms_page .table_helper .search_holder {
  display: none;
}
.questions_and_answers_cms_page .table-questions_and_answers .header,
.questions_and_answers_cms_page .table-questions_and_answers .table_row {
  display: grid;
  align-items: center;
  grid-template-columns: 200px 1fr 120px 35px;
}
@media only screen and (max-width: 900px) {
  .questions_and_answers_cms_page .table-questions_and_answers .header,
  .questions_and_answers_cms_page .table-questions_and_answers .table_row {
    grid-template-columns: 100px 1fr 120px 35px;
  }
}
@media only screen and (max-width: 500px) {
  .questions_and_answers_cms_page .table-questions_and_answers .header,
  .questions_and_answers_cms_page .table-questions_and_answers .table_row {
    grid-template-columns: 70px 1fr 100px 35px;
  }
}
.questions_and_answers_cms_page .table-questions_and_answers .header .table_cell .ticket,
.questions_and_answers_cms_page .table-questions_and_answers .table_row .table_cell .ticket {
  background: rgba(234, 84, 85, 0.15);
  color: #ea5455;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 100px;
}
.questions_and_answers_cms_page .table-questions_and_answers .header .table_cell .ticket.active,
.questions_and_answers_cms_page .table-questions_and_answers .table_row .table_cell .ticket.active {
  background: rgba(40, 199, 111, 0.15);
  color: #28c76f;
}
@media only screen and (max-width: 900px) {
  .questions_and_answers_cms_page .table-questions_and_answers .header .table_cell .ticket,
  .questions_and_answers_cms_page .table-questions_and_answers .table_row .table_cell .ticket {
    font-size: 10px;
  }
}
.questions_and_answers_cms_page .table-questions_and_answers .table_row {
  cursor: pointer;
}

.show_questions_and_answer_page .item_row {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-gap: 0 10px;
  padding: 10px 0;
}
@media only screen and (max-width: 600px) {
  .show_questions_and_answer_page .item_row {
    grid-template-columns: 80px 1fr;
  }
}
@media only screen and (max-width: 600px) {
  .show_questions_and_answer_page .item_row .label {
    font-size: 12px;
  }
}
.show_questions_and_answer_page .item_row .content {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .show_questions_and_answer_page .item_row .content {
    font-size: 12px;
  }
}
.show_questions_and_answer_page .item_row .content .ticket {
  background: rgba(234, 84, 85, 0.15);
  color: #ea5455;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 100px;
}
.show_questions_and_answer_page .item_row .content .ticket.active {
  background: rgba(40, 199, 111, 0.15);
  color: #28c76f;
}
.show_questions_and_answer_page .item_row .content a {
  color: #E10770;
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .show_questions_and_answer_page .item_row .content a {
    font-size: 12px;
  }
}
.show_questions_and_answer_page form .area_field {
  margin: 0;
}
.show_questions_and_answer_page form label {
  display: none;
}
.show_questions_and_answer_page form button {
  margin-top: 20px;
}
.show_questions_and_answer_page .buttons {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: end;
  grid-gap: 10px;
}

.products_export .box_holder {
  position: relative;
}
.products_export .box_holder .edit_btn {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.products_export .table_helper .table_helper_left {
  display: grid;
  grid-template-columns: 300px auto;
  grid-gap: 20px;
}
@media only screen and (max-width: 1000px) {
  .products_export .table_helper .table_helper_left {
    grid-template-columns: 200px auto;
  }
  .products_export .table_helper .table_helper_left .search_select_wrapper .search_select_holder .root p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 750px) {
  .products_export .table_helper .table_helper_left {
    grid-template-columns: 1fr auto;
  }
}
.products_export .table_helper .table_helper_left .search_select_wrapper .search_select_holder .dropdown_holder .dropdown_content ul li {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.products_export .table_helper .table_helper_left .search_select_wrapper .search_select_holder .dropdown_holder .dropdown_content ul li button.icon_button {
  width: 20px;
  height: 20px;
}
@media only screen and (max-width: 750px) {
  .products_export .table_helper .align_row:nth-child(1) {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20px;
  }
}
.products_export .table .table_header {
  background: #e7e3e3;
  height: 50px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.products_export .table .table_header p {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: unset;
}
@media only screen and (max-width: 1600px) {
  .products_export .table .table_header p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .products_export .table .table_header p {
    font-size: 11px;
  }
}
.products_export .table .table_row_wrap {
  cursor: pointer;
  position: relative;
}
.products_export .table .table_row_wrap:nth-child(even) {
  background: #f8f8f8;
}
.products_export .table .table_row_wrap .sub {
  background: rgb(241, 241, 241);
  padding-left: 20px;
  position: relative;
}
.products_export .table .table_row_wrap .sub.level_3, .products_export .table .table_row_wrap .sub.level_5 {
  background: #fff;
}
.products_export .table .table_row_wrap .sub.level_3 .table_row_wrap:nth-child(even), .products_export .table .table_row_wrap .sub.level_5 .table_row_wrap:nth-child(even) {
  background: #fff;
}
.products_export .table .table_row_wrap .sub::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 24px;
  left: 9px;
  width: 2px;
  background: #e7e3e3;
}
.products_export .table .table_row_wrap .sub .table_row_wrap:nth-child(even) {
  background: rgb(241, 241, 241);
}
.products_export .table .table_row_wrap .sub .table_row_wrap .table_row::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  left: -9px;
  height: 2px;
  background: #e7e3e3;
}
.products_export .table .table_row_wrap .table_row {
  position: relative;
  height: 50px;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 30px 1fr 40px;
  align-items: center;
}
.products_export .table .table_row_wrap .table_row .table_cell {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: unset;
}
@media only screen and (max-width: 1600px) {
  .products_export .table .table_row_wrap .table_row .table_cell {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .products_export .table .table_row_wrap .table_row .table_cell {
    font-size: 11px;
  }
}

.products_export_add_client_page_inner .products_export_add_client_page_outer .box_holder {
  padding: 40px 20px;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table {
  margin: 20px 0;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table button {
  display: none;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .table_header {
  background: #e7e3e3;
  height: 50px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .table_header p {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: unset;
}
@media only screen and (max-width: 1600px) {
  .products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .table_header p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .table_header p {
    font-size: 11px;
  }
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .sub {
  background: rgb(241, 241, 241);
  padding-left: 20px;
  position: relative;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .sub.level_3, .products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .sub.level_5 {
  background: #fff;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .sub.level_3 .table_row_wrap:nth-child(even), .products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .sub.level_5 .table_row_wrap:nth-child(even) {
  background: #fff;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .sub::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 24px;
  left: 9px;
  width: 2px;
  background: #e7e3e3;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .sub .table_row_wrap:nth-child(even) {
  background: rgb(241, 241, 241);
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .sub .table_row_wrap .table_row::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  left: -9px;
  height: 2px;
  background: #e7e3e3;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .table_row_wrap {
  cursor: pointer;
  position: relative;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .table_row_wrap:nth-child(even) {
  background: #f8f8f8;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .table_row_wrap .table_row {
  position: relative;
  height: 50px;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 30px 1fr 40px;
  align-items: center;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .table_row_wrap .table_row .table_cell {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: unset;
}
@media only screen and (max-width: 1600px) {
  .products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .table_row_wrap .table_row .table_cell {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .products_export_add_client_page_inner .products_export_add_client_page_outer .our_categories_table .table_row_wrap .table_row .table_cell {
    font-size: 11px;
  }
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.products_export_add_client_page_inner .products_export_add_client_page_outer .buttons button.default {
  margin-right: 20px;
}

.modal_inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_inner .modal_outer {
  margin: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
}
.modal_inner .modal_outer form {
  min-width: 400px;
  padding-top: 20px;
}
@media only screen and (max-width: 500px) {
  .modal_inner .modal_outer form {
    min-width: 90%;
  }
}
.modal_inner .modal_outer form button {
  margin-top: 20px;
}

.products_import_page_inner {
  margin-bottom: 20px;
}
.products_import_page_inner .import_btn {
  margin: 0 0 20px auto;
}
.products_import_page_inner .modal_outer {
  padding: 30px;
}
.products_import_page_inner .modal_outer h2 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 26px;
}
.products_import_page_inner .modal_outer .buttons {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  grid-gap: 20px;
}
.products_import_page_inner .products_import_page_outer {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
}
.products_import_page_inner .products_import_page_outer .tabs_inner .tabs_outer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 1px solid #ddd;
  align-items: center;
}
.products_import_page_inner .products_import_page_outer .tabs_inner .tabs_outer a {
  position: relative;
  padding: 15px;
  text-align: center;
  color: #a4a4a4;
  display: block;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 700px) {
  .products_import_page_inner .products_import_page_outer .tabs_inner .tabs_outer a {
    font-size: 12px;
    padding: 10px 5px;
  }
}
.products_import_page_inner .products_import_page_outer .tabs_inner .tabs_outer a.active {
  color: #E10770;
}
.products_import_page_inner .products_import_page_outer .tabs_inner .tabs_outer a.active::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 1px;
  background: #E10770;
}
.products_import_page_inner .products_import_page_outer .products_import_page_content {
  padding: 30px 20px;
}
.products_import_page_inner .products_import_page_outer .products_import_page_content .category_to_category_tab form .inputs_group {
  display: grid;
  grid-template-columns: 1fr 30px 1fr auto;
  grid-gap: 20px;
}
@media only screen and (max-width: 900px) {
  .products_import_page_inner .products_import_page_outer .products_import_page_content .category_to_category_tab form .inputs_group {
    grid-template-columns: 100%;
  }
}
.products_import_page_inner .products_import_page_outer .products_import_page_content .category_to_category_tab form .arrow {
  width: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 31px;
  color: rgb(86, 201, 58);
}
@media only screen and (max-width: 900px) {
  .products_import_page_inner .products_import_page_outer .products_import_page_content .category_to_category_tab form .arrow {
    margin: 0 auto;
    transform: rotate(90deg);
  }
}

.list_of_skus .dropdown_inner {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  position: relative;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}
.list_of_skus .dropdown_inner.active .dropdown_outer svg {
  transform: rotate(180deg);
}
.list_of_skus .dropdown_inner .dropdown_outer {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.list_of_skus .dropdown_inner .dropdown_outer p {
  color: #333e48;
}
.list_of_skus .dropdown_inner .dropdown_outer svg {
  transform: rotate(0);
  transition: all 0.2s ease;
}
.list_of_skus .dropdown_inner .dropdown_items {
  position: absolute;
  left: 0;
  right: 0;
  top: 110%;
  background: #fff;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.list_of_skus .dropdown_inner .dropdown_items li {
  padding: 8px 20px;
  font-size: 14px;
  cursor: pointer;
}
.list_of_skus .dropdown_inner .dropdown_items li.active {
  color: #333e48;
  background: #f8f8f8;
}
.list_of_skus .items {
  margin-top: 20px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
}
@media only screen and (max-width: 1500px) {
  .list_of_skus .items {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media only screen and (max-width: 1200px) {
  .list_of_skus .items {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 1000px) {
  .list_of_skus .items {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 700px) {
  .list_of_skus .items {
    grid-template-columns: repeat(2, 1fr);
  }
}
.list_of_skus .items li {
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 14px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 24px;
  grid-gap: 10px;
  background: #f8f8f8;
}

.products_import_category_to_category_tab .list_of_categories {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 10px 20px;
}
.products_import_category_to_category_tab .list_of_categories .item {
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 30px 1fr;
  grid-gap: 20px;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}
@media only screen and (max-width: 900px) {
  .products_import_category_to_category_tab .list_of_categories .item {
    grid-template-columns: 100%;
  }
}
.products_import_category_to_category_tab .list_of_categories .item:last-child {
  border-bottom: 0;
}
.products_import_category_to_category_tab .list_of_categories .item .arrow {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 31px;
  color: rgb(86, 201, 58);
  margin-left: -50px;
}
@media only screen and (max-width: 1400px) {
  .products_import_category_to_category_tab .list_of_categories .item .arrow {
    margin-left: -40px;
  }
}
@media only screen and (max-width: 900px) {
  .products_import_category_to_category_tab .list_of_categories .item .arrow {
    margin: 0 auto;
    transform: rotate(90deg);
  }
}
.products_import_category_to_category_tab .list_of_categories .item .right {
  margin-left: -50px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 30px;
}
@media only screen and (max-width: 1400px) {
  .products_import_category_to_category_tab .list_of_categories .item .right {
    margin-left: -40px;
  }
}
@media only screen and (max-width: 900px) {
  .products_import_category_to_category_tab .list_of_categories .item .right {
    margin: 0;
  }
}

.cms_page.actions_page .dropzones {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin: 20px 0;
}
@media only screen and (max-width: 900px) {
  .cms_page.actions_page .dropzones {
    grid-template-columns: 100%;
    grid-gap: 20px;
  }
}
.cms_page.actions_page .dropzone_wrapper {
  width: 100%;
}
.cms_page.actions_page .dropzone_wrapper .input_file_holder {
  height: 150px;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  position: relative;
  cursor: pointer;
}
.cms_page.actions_page .dropzone_wrapper .input_file_holder input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.cms_page.actions_page .dropzone_wrapper .input_file_holder span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}
.cms_page.actions_page .dropzone_wrapper .input_file_holder .photo {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
.cms_page.actions_page .dropzone_wrapper .input_file_holder .photo svg {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(234, 84, 85);
  font-size: 20px;
  z-index: 1;
}
.cms_page.actions_page .box_holder {
  padding: 40px 20px 30px 20px;
}
.cms_page.actions_page .box_holder .filter_dropdowns {
  display: grid;
  grid-template-columns: 300px 300px 100px;
  grid-gap: 20px;
}
@media only screen and (max-width: 900px) {
  .cms_page.actions_page .box_holder .filter_dropdowns {
    grid-template-columns: 100%;
    grid-gap: 30px;
  }
}
.cms_page.actions_page .box_holder form {
  margin-top: 50px;
}
.cms_page.actions_page .box_holder .update_action_form .form_row {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;
}

.catalogsPage {
  background-color: #fff;
  border-radius: 25px;
  padding: 20px;
}
.catalogsPage .data {
  margin: 20px 0;
}
.catalogsPage .data .catalog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  margin: 20px 0;
  border-radius: 15px;
  padding: 10px;
}
.catalogsPage .data .catalog p {
  font-size: 18px;
}
.catalogsPage .data .catalog svg {
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.createCatalogPage {
  background-color: #fff;
  padding: 20px;
  border-radius: 25px;
}
.createCatalogPage .fileLabel {
  border: 1px dashed black;
  padding: 30px;
  display: block;
  width: 20%;
  margin: 10px 0;
  text-align: center;
}
.createCatalogPage input[type=file] {
  color: transparent;
  text-indent: -9999px;
  position: absolute;
}

.cms_nav_bar_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #2B2A28;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  z-index: 5;
  padding: 0.8rem 20px 0.8rem 300px;
}
@media only screen and (max-width: 1200px) {
  .cms_nav_bar_wrapper {
    padding: 10px 20px;
    height: 65px;
  }
}
.cms_nav_bar_wrapper.active {
  padding-left: 100px;
}
@media only screen and (max-width: 1200px) {
  .cms_nav_bar_wrapper.active {
    padding-left: 20px;
  }
}
.cms_nav_bar_wrapper .cms_nav_bar_holder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 1200px) {
  .cms_nav_bar_wrapper .cms_nav_bar_holder {
    justify-content: space-between;
  }
}
.cms_nav_bar_wrapper .cms_nav_bar_holder .menu_bar {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .cms_nav_bar_wrapper .cms_nav_bar_holder .menu_bar {
    display: block;
  }
}
.cms_nav_bar_wrapper .cms_nav_bar_holder .info {
  display: flex;
  align-items: center;
  position: relative;
}
.cms_nav_bar_wrapper .cms_nav_bar_holder .info h3 {
  font-weight: 400;
  color: #f5f5f5;
  text-transform: capitalize;
  font-size: 16px;
}
@media only screen and (max-width: 1200px) {
  .cms_nav_bar_wrapper .cms_nav_bar_holder .info h3 {
    font-size: 14px;
  }
}
.cms_nav_bar_wrapper .cms_nav_bar_holder .info .avatar {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  margin-left: 8px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
@media only screen and (max-width: 1200px) {
  .cms_nav_bar_wrapper .cms_nav_bar_holder .info .avatar {
    width: 35px;
    height: 35px;
  }
}
.cms_nav_bar_wrapper .cms_nav_bar_holder ul.dropdown_menu {
  position: absolute;
  width: 160px;
  right: 0;
  top: 120%;
  background: #fff;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
}
.cms_nav_bar_wrapper .cms_nav_bar_holder ul.dropdown_menu li button {
  background: transparent;
  color: #626262;
  width: 100%;
  justify-content: flex-start;
  box-shadow: unset;
  text-transform: capitalize;
}
.cms_nav_bar_wrapper .cms_nav_bar_holder ul.dropdown_menu li button:hover {
  background: #E10770;
  color: #fff;
}
.cms_nav_bar_wrapper .cms_nav_bar_holder ul.dropdown_menu li button svg {
  margin-right: 5px;
}
.cms_nav_bar_wrapper .notifications_select {
  position: relative;
  margin-right: 20px;
}
.cms_nav_bar_wrapper .notifications_select .icon_button {
  width: auto;
  height: auto;
}
.cms_nav_bar_wrapper .notifications_select .icon_button .bell_icon {
  color: #6e6b7b;
  font-size: 22px;
}
.cms_nav_bar_wrapper .notifications_select .root_icon {
  position: relative;
  cursor: pointer;
}
.cms_nav_bar_wrapper .notifications_select .root_icon span.length {
  width: 20px;
  height: 20px;
  background: rgb(234, 84, 85);
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
}
.cms_nav_bar_wrapper .notifications_select .select_inner {
  position: absolute;
  z-index: 9;
  top: 100%;
  right: 0;
  background: #fff;
  box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
  border-radius: 0.358rem;
  border: 1px solid rgba(34, 41, 47, 0.05);
  width: 28rem;
}
@media only screen and (max-width: 900px) {
  .cms_nav_bar_wrapper .notifications_select .select_inner {
    width: 350px;
    right: -200px;
  }
}
@media only screen and (max-width: 500px) {
  .cms_nav_bar_wrapper .notifications_select .select_inner {
    width: 300px;
    right: -210px;
  }
}
.cms_nav_bar_wrapper .notifications_select .select_inner .detail {
  padding: 1.22rem 1.28rem;
  border-bottom: 1px solid #ebe9f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .detail h2 {
  color: #5e5873;
  font-size: 17px;
  font-weight: 500;
}
@media only screen and (max-width: 500px) {
  .cms_nav_bar_wrapper .notifications_select .select_inner .detail h2 {
    font-size: 15px;
  }
}
.cms_nav_bar_wrapper .notifications_select .select_inner .detail .read_all {
  font-size: 13px;
  cursor: pointer;
  color: #E10770;
  text-decoration: underline;
  transition: all 0.2s ease;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .detail .read_all:hover {
  opacity: 0.8;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .detail span {
  background: rgba(115, 103, 240, 0.12);
  color: #FF00A1;
  border-radius: 10rem;
  padding: 0.3rem 0.5rem;
  font-size: 12px;
  text-align: center;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items {
  height: 300px;
  overflow-y: scroll;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items::-webkit-scrollbar {
  width: 7px;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items::-webkit-scrollbar-thumb {
  background: rgb(212, 212, 212);
  border-radius: 50px;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items::-webkit-scrollbar-thumb:hover {
  background: rgb(177, 177, 177);
}
@media only screen and (max-width: 500px) {
  .cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items {
    height: 280px;
  }
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item {
  border-bottom: 1px solid #ebe9f1;
  width: 100%;
  display: grid;
  grid-template-columns: 35px 1fr;
  align-items: center;
  grid-gap: 0 15px;
  padding: 10px 15px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item.active::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #E10770;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item.active .row .title {
  color: #000;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item.active .row .message {
  color: #000;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item:last-child {
  border-bottom: 0;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item:hover {
  background: #f8f8f8;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .show_more_wrapper {
  position: absolute;
  right: 30px;
  width: 30px;
  height: 30px;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .show_more_wrapper .icon {
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  color: grey;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .show_more_wrapper .show_more {
  width: 30px;
  height: 30px;
  position: relative;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .show_more_wrapper .show_more .dropdown {
  position: absolute;
  right: 35px;
  top: 0;
  width: 200px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  border-radius: 6px;
  overflow: hidden;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .show_more_wrapper .show_more .dropdown .row {
  padding: 10px;
  font-size: 14px;
  transition: all 0.2s ease;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .show_more_wrapper .show_more .dropdown .row:hover {
  background: #f8f8f8;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(234, 84, 85, 0.12);
  color: #ea5455;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .icon svg {
  width: 70%;
  height: 70%;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .icon.success {
  background: rgba(40, 199, 111, 0.12);
  color: #28c76f;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .row .title {
  color: #6e6b7b;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
}
@media only screen and (max-width: 500px) {
  .cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .row .title {
    font-size: 13px;
  }
}
.cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .row .message {
  color: #b9b9c3;
  font-weight: 300;
  font-size: 12px;
  height: 15px;
  overflow: hidden;
}
@media only screen and (max-width: 500px) {
  .cms_nav_bar_wrapper .notifications_select .select_inner .select_outer .items .item .row .message {
    font-size: 11px;
  }
}
.cms_nav_bar_wrapper .notifications_select .select_inner .empty {
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
}
.cms_nav_bar_wrapper .notifications_select .select_inner .bottom_part {
  border-top: 1px solid #ebe9f1;
  padding: 1.22rem 1.28rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cms_nav_bar_wrapper .language_dropdown_wrap {
  margin-right: 20px;
  position: relative;
}
.cms_nav_bar_wrapper .language_dropdown_wrap .root {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.cms_nav_bar_wrapper .language_dropdown_wrap .root svg {
  margin-right: 10px;
}
.cms_nav_bar_wrapper .language_dropdown_wrap svg {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
.cms_nav_bar_wrapper .language_dropdown_wrap .dropdown_wrap {
  position: absolute;
  top: 120%;
  right: 0;
  width: 150px;
  background: #fff;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
}
.cms_nav_bar_wrapper .language_dropdown_wrap .dropdown_wrap li {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
}
.cms_nav_bar_wrapper .language_dropdown_wrap .dropdown_wrap li:last-child {
  margin-bottom: 0;
}
.cms_nav_bar_wrapper .language_dropdown_wrap .dropdown_wrap li svg {
  margin-right: 10px;
}
.cms_nav_bar_wrapper .language_dropdown_wrap .dropdown_wrap li:hover {
  background: #E10770;
  color: #fff;
}
.cms_nav_bar_wrapper .language_dropdown_wrap .dropdown_wrap li.active {
  background: #E10770;
  color: #fff;
}

.side_bar_mask {
  transition: all 0.3s ease;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}
@media only screen and (max-width: 1200px) {
  .side_bar_mask.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.2);
  }
}
.side_bar_mask .side_bar_wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 270px;
  background: #2b2a28;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  z-index: 10;
  overflow-y: scroll;
  transition: all 0.25s ease;
}
.side_bar_mask .side_bar_wrapper::-webkit-scrollbar {
  width: 0;
}
@media only screen and (max-width: 1400px) {
  .side_bar_mask .side_bar_wrapper {
    width: 240px;
  }
}
@media only screen and (max-width: 1200px) {
  .side_bar_mask .side_bar_wrapper {
    width: 270px;
    transform: translateX(-300px);
    transition: all 0.25s ease;
  }
  .side_bar_mask .side_bar_wrapper.active {
    transform: translateX(0);
  }
}
@media only screen and (min-width: 1200px) {
  .side_bar_mask .side_bar_wrapper.active:not(:hover) {
    width: 80px;
  }
  .side_bar_mask .side_bar_wrapper.active:not(:hover) .top {
    padding: 10px;
    grid-template-columns: 30px;
    justify-content: center;
  }
  .side_bar_mask .side_bar_wrapper.active:not(:hover) .top .logo img {
    display: none;
  }
  .side_bar_mask .side_bar_wrapper.active:not(:hover) .top .logo .favicon {
    display: block;
  }
  .side_bar_mask .side_bar_wrapper.active:not(:hover) .top .circle {
    display: none;
  }
  .side_bar_mask .side_bar_wrapper.active:not(:hover) .content .sidebar_dropdown .dropdown_label {
    grid-template-columns: 100%;
  }
  .side_bar_mask .side_bar_wrapper.active:not(:hover) .content .sidebar_dropdown .dropdown_label div {
    grid-template-columns: 100%;
    justify-items: center;
  }
  .side_bar_mask .side_bar_wrapper.active:not(:hover) .content .sidebar_dropdown .dropdown_label div span {
    display: none;
  }
  .side_bar_mask .side_bar_wrapper.active:not(:hover) .content .sidebar_dropdown .dropdown_label .arrow {
    display: none;
  }
  .side_bar_mask .side_bar_wrapper.active:not(:hover) .content li a {
    grid-template-columns: 100%;
    justify-items: center;
  }
  .side_bar_mask .side_bar_wrapper.active:not(:hover) .content li a span {
    display: none;
  }
}
.side_bar_mask .side_bar_wrapper .top {
  display: grid;
  grid-template-columns: 1fr 16px;
  align-items: center;
  grid-gap: 20px;
  padding: 20px;
}
.side_bar_mask .side_bar_wrapper .top .logo {
  display: block;
  height: 32px;
}
.side_bar_mask .side_bar_wrapper .top .logo img {
  width: 100%;
  cursor: pointer;
}
.side_bar_mask .side_bar_wrapper .top .logo .favicon {
  display: none;
  cursor: pointer;
}
.side_bar_mask .side_bar_wrapper .top svg {
  color: #E10770;
  cursor: pointer;
}
@media only screen and (max-width: 1200px) {
  .side_bar_mask .side_bar_wrapper .top .circle {
    display: none;
  }
}
.side_bar_mask .side_bar_wrapper .top .times {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .side_bar_mask .side_bar_wrapper .top .times {
    display: block;
  }
}
.side_bar_mask .side_bar_wrapper .content {
  padding-top: 20px;
  padding-bottom: 20px;
}
.side_bar_mask .side_bar_wrapper .content .sidebar_dropdown {
  transition: all 0.2s ease;
}
.side_bar_mask .side_bar_wrapper .content .sidebar_dropdown .dropdown_label {
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
  grid-gap: 10px;
  color: white;
  margin: 0 15px;
  padding: 11px 10px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.side_bar_mask .side_bar_wrapper .content .sidebar_dropdown .dropdown_label.active {
  background: rgba(225, 7, 112, 0.3);
}
.side_bar_mask .side_bar_wrapper .content .sidebar_dropdown .dropdown_label.active .arrow {
  transform: rotate(0deg);
}
.side_bar_mask .side_bar_wrapper .content .sidebar_dropdown .dropdown_label.active + .dropdown_items_wrap {
  display: block;
}
.side_bar_mask .side_bar_wrapper .content .sidebar_dropdown .dropdown_label:hover div {
  margin-left: 5px;
}
.side_bar_mask .side_bar_wrapper .content .sidebar_dropdown .dropdown_label div {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 10px;
  align-items: center;
  transition: all 0.2s ease;
}
.side_bar_mask .side_bar_wrapper .content .sidebar_dropdown .dropdown_label div svg {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.side_bar_mask .side_bar_wrapper .content .sidebar_dropdown .dropdown_label .arrow {
  width: 20px;
  height: 20px;
  font-size: 20px;
  transform: rotate(-90deg);
  transition: all 0.2s ease;
}
.side_bar_mask .side_bar_wrapper .content .sidebar_dropdown .dropdown_items_wrap {
  display: none;
}
.side_bar_mask .side_bar_wrapper .content .sidebar_dropdown .dropdown_items_wrap .dropdown_items {
  padding: 10px 0;
}
.side_bar_mask .side_bar_wrapper .content li {
  margin: 0 15px;
  border-radius: 4px;
}
.side_bar_mask .side_bar_wrapper .content li.active {
  background: #E10770;
  box-shadow: 0 0 10px 1px rgba(247, 110, 190, 0.7);
  color: #fff;
}
.side_bar_mask .side_bar_wrapper .content li.active a {
  color: #fff;
}
.side_bar_mask .side_bar_wrapper .content li a {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  grid-gap: 10px;
  color: white;
  padding: 11px 10px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease;
}
.side_bar_mask .side_bar_wrapper .content li a:hover {
  padding-left: 15px;
}
.side_bar_mask .side_bar_wrapper .content li a svg {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.side_bar_mask .side_bar_wrapper .content .icon {
  stroke: white;
  color: white;
  fill: white;
}

.filters_slide_bar_wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
@media only screen and (max-width: 1200px) {
  .filters_slide_bar_wrapper {
    padding: 20px;
  }
}
.filters_slide_bar_wrapper .filters_slide_bar_holder {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  overflow-y: scroll;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder::-webkit-scrollbar {
  width: 0;
}
@media only screen and (max-width: 1200px) {
  .filters_slide_bar_wrapper .filters_slide_bar_holder {
    max-height: 90vh;
  }
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form {
  width: 100%;
  height: 100%;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .group h3 {
  font-size: 16px;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 30px;
  align-items: start;
}
@media only screen and (max-width: 1200px) {
  .filters_slide_bar_wrapper .filters_slide_bar_holder form .holder {
    grid-template-columns: 100%;
  }
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .left {
  border: 1px solid #ddd;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .left .group {
  border-bottom: 1px solid #ddd;
  padding: 15px 10px 0 10px;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .left .group:last-child {
  border-bottom: 0;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right {
  padding-top: 20px;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .dropdowns_row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
@media only screen and (max-width: 900px) {
  .filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .dropdowns_row {
    grid-template-columns: 100%;
  }
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .dropdowns_row h3 {
  display: none;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .web_price_row {
  margin: 20px 0;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .web_price_row h3 {
  margin-bottom: 25px;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .web_price_row .price_from_to {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .price_from_to {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .search_select_wrapper.mt-20 {
  margin: 35px 0 !important;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .form_checkbox {
  margin-bottom: 40px;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .data_pickers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .fixed_buttons {
  margin-top: 30px;
  justify-content: flex-end;
  display: grid;
  grid-template-columns: repeat(3, 200px);
  grid-gap: 30px;
}
@media only screen and (max-width: 900px) {
  .filters_slide_bar_wrapper .filters_slide_bar_holder form .holder .right .fixed_buttons {
    grid-template-columns: 100%;
  }
}

.form_field {
  width: 100%;
  height: 40px;
  position: relative;
}
.form_field input {
  z-index: 1;
  position: relative;
  font-size: 13px;
  border-radius: 5px;
  padding: 0 20px;
  background: transparent;
  color: #333e48;
  transition: all 0.2s ease;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.form_field input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.form_field input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.form_field input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.form_field input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.form_field input:focus {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  border-color: #E10770;
}
.form_field input:focus + label {
  transform: translate(-10px, -35px);
  color: #E10770;
  font-size: 0.7rem;
}
.form_field input:not([value=""]) + label {
  transform: translate(-10px, -35px);
  font-size: 0.7rem;
}
.form_field input[type=date] + label {
  transform: translate(-10px, -35px);
  font-size: 0.7rem;
}
.form_field label {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
}

.error_msg {
  margin-top: 5px;
}

.form_checkbox {
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
}
.form_checkbox .box {
  position: relative;
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 2px;
  border: 2px solid rgb(180, 180, 180);
  transition: all 0.2s ease;
  transform: rotate(-90deg);
  overflow: hidden;
}
.form_checkbox .box.active {
  background: rgb(248, 132, 204);
  border-color: rgb(248, 132, 204);
  transform: rotate(0);
}
.form_checkbox .box.active span {
  transform: translate(0);
}
.form_checkbox .box span {
  transition: all 0.2s ease;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: right;
  padding: 3px;
  transform: translate(250%);
}
.form_checkbox label {
  cursor: pointer;
  color: #626262;
  display: block;
  margin-left: 5px;
  font-size: 14px;
}

.chips_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.chips_wrap .chip {
  height: 26px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.7rem;
  background: #f0f0f0;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  padding: 2px 6px;
}
.chips_wrap .chip p {
  padding-left: 6px;
}
.chips_wrap .chip span {
  margin-left: 6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.chips_wrap .hidden {
  display: none;
}

.simple_react_calendar {
  background: #fff;
  width: 280px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  z-index: 5;
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
}
.simple_react_calendar .calendar {
  width: 100%;
  height: 100%;
}
.simple_react_calendar .calendar .calendar-month_header {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f8f8;
}
.simple_react_calendar .calendar .calendar-month_header button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}
.simple_react_calendar .calendar .calendar-month .calendar-days_of_week {
  display: grid;
  grid-template-columns: repeat(7, 40px);
  grid-template-rows: 40px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-size: 13px;
}
.simple_react_calendar .calendar .calendar-month .calendar-week {
  display: grid;
  grid-template-columns: repeat(7, 40px);
  grid-template-rows: 40px;
}
.simple_react_calendar .calendar .calendar-month .calendar-week button.calendar-week-day {
  background: #fff;
  border-radius: 50%;
}
.simple_react_calendar .calendar .calendar-month .calendar-week button.calendar-week-day.is-today {
  background: #ddd;
  color: #fff;
}
.simple_react_calendar .calendar .calendar-month .calendar-week button.calendar-week-day.is-selected {
  background: #E10770;
  color: #fff;
}

button.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 39px;
  background: #e10770;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  border-radius: 6px;
  letter-spacing: normal;
  text-transform: capitalize;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 1400px) {
  button.button {
    font-size: 13px;
    padding: 0 10px;
  }
}
button.button:hover {
  background: #e10770;
  box-shadow: 0 8px 25px -8px rgb(115, 103, 240);
}
button.button.default {
  background: #ededed;
  color: #626262;
  transition: all 0.2s ease;
}
button.button.default:hover {
  background: #ededed;
  box-shadow: 0 8px 25px -8px #b9b9b9;
}
button.button.danger {
  background: rgb(234, 84, 85);
  transition: all 0.2s ease;
}
button.button.danger:hover {
  background: rgb(234, 84, 85);
  box-shadow: 0 8px 25px -8px rgb(234, 84, 85);
}

button.icon_button {
  width: 30px;
  height: 30px;
  color: rgb(185, 185, 185);
}
button.icon_button.disable {
  opacity: 0.2;
}
button.icon_button.edit:hover {
  color: #E10770;
}
button.icon_button.delete:hover {
  color: rgb(234, 84, 85);
}
button.icon_button.primary:hover {
  color: #E10770;
}
button.icon_button.add:hover {
  color: rgb(86, 201, 58);
}
button.icon_button.icon_success_color {
  background: rgba(86, 201, 58, 0.15);
  color: rgb(86, 201, 58);
  width: 25px;
  height: 25px;
}
button.icon_button.icon_success_color svg {
  font-size: 18px;
}
button.icon_button.icon_danger_color {
  background: rgba(234, 84, 85, 0.15);
  color: rgb(234, 84, 85);
  width: 25px;
  height: 25px;
}
button.icon_button.icon_danger_color svg {
  font-size: 18px;
}
button.icon_button.filter_icon {
  position: relative;
  width: 35px;
  height: 35px;
}
button.icon_button.filter_icon svg {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 25px;
}
button.icon_button.filter_icon svg path {
  fill: rgba(0, 0, 0, 0.4);
}
@media only screen and (max-width: 1400px) {
  button.icon_button.filter_icon svg {
    width: 22px;
  }
}
button.icon_button.active {
  background: #f8d7da;
  color: #721c24;
  width: 25px;
  height: 25px;
}
button.icon_button.active svg {
  font-size: 18px;
}
@media only screen and (max-width: 950px) {
  button.icon_button.active svg {
    font-size: 15px !important;
  }
}
button.icon_button.active.active {
  background: #d4edda;
  color: #155724;
}

.event_icon {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 0.6;
}
.event_icon svg {
  font-size: 20px;
}
.event_icon::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 10px;
  top: -10px;
  background: #e0e0e0;
}
.event_icon::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 10px;
  bottom: -10px;
  background: #e0e0e0;
}
.event_icon.created {
  background: rgb(86, 201, 58);
}
.event_icon.updated {
  background: #ffc107;
}
.event_icon.deleted {
  background: rgb(234, 84, 85);
}

.search_select_wrapper {
  width: 100%;
}
.search_select_wrapper.no-scroll {
  margin-left: 20px;
  width: 200px;
}
.search_select_wrapper.no-scroll .dropdown_content ul {
  overflow-y: unset !important;
}
.search_select_wrapper.active .search_select_holder {
  border-color: #E10770;
  height: auto;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.search_select_wrapper.active .search_select_holder .root span.icon {
  transform: translateY(-50%) rotate(180deg);
}
.search_select_wrapper.active .search_select_holder label {
  color: #E10770;
}
.search_select_wrapper.active .search_select_holder .dropdown_holder {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  overflow: auto;
  max-height: 620px;
}
.search_select_wrapper .search_select_holder {
  background: #fff;
  width: 100%;
  min-height: 40px;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.4);
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.search_select_wrapper .search_select_holder .root {
  padding: 0 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.search_select_wrapper .search_select_holder .root p {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.search_select_wrapper .search_select_holder .root p span {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f9fa;
  margin-right: 10px;
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 12px;
  color: #333e48;
}
.search_select_wrapper .search_select_holder .root p span svg {
  margin: 1px 0 0 2px;
}
.search_select_wrapper .search_select_holder .root span.icon {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  font-size: 20px;
}
.search_select_wrapper .search_select_holder .root span.icon svg {
  margin-top: -8px;
}
.search_select_wrapper .search_select_holder label {
  display: block;
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  transition: all 0.2s ease;
  position: absolute;
  top: -22px;
  left: 0px;
}
.search_select_wrapper .search_select_holder .dropdown_holder {
  transition: all 0.2s ease;
  z-index: 10;
  position: absolute;
  top: calc(100% + 1px);
  left: -1px;
  right: -1px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 0;
  background: #fff;
  padding: 10px;
}
.search_select_wrapper .search_select_holder .dropdown_holder .dropdown_content input {
  width: 100%;
  height: 35px;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #333e48;
  padding: 0 10px;
}
.search_select_wrapper .search_select_holder .dropdown_holder .dropdown_content input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.search_select_wrapper .search_select_holder .dropdown_holder .dropdown_content ul {
  margin-top: 10px;
  overflow: hidden;
}
.search_select_wrapper .search_select_holder .dropdown_holder .dropdown_content ul.no_scroll {
  overflow-y: unset;
}
.search_select_wrapper .search_select_holder .dropdown_holder .dropdown_content ul li {
  font-size: 13px;
  padding: 7px 10px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  transition: all 0.2s ease;
}
.search_select_wrapper .search_select_holder .dropdown_holder .dropdown_content ul li:hover {
  background: #f8f8f8;
  cursor: pointer;
}
.search_select_wrapper .search_select_holder .dropdown_holder .dropdown_content ul li.active {
  background: #f8f8f8;
  color: #333e48;
}
.search_select_wrapper .search_select_holder .dropdown_holder .dropdown_content .no_result {
  margin-top: 10px;
  font-size: 13px;
}

.cms_content .form_nav_wrapper {
  display: flex;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: -20px -20px 50px -20px;
}
@media only screen and (max-width: 500px) {
  .cms_content .form_nav_wrapper {
    margin: -15px -15px 50px -15px;
  }
}
.cms_content .form_nav_wrapper .nav_item {
  color: #a4a4a4;
  margin-right: 20px;
  margin-bottom: -1px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 16px;
  font-weight: 400;
  padding: 15px 0;
  border-bottom: 1px solid transparent;
}
@media only screen and (max-width: 500px) {
  .cms_content .form_nav_wrapper .nav_item {
    font-size: 14px;
    padding: 12px 0;
    margin-right: 15px;
  }
}
.cms_content .form_nav_wrapper .nav_item.active {
  color: #E10770;
  border-bottom: 1px solid #E10770;
}

.cms_content .table {
  margin: 20px 0;
}
.cms_content .table .table_holder .table_cell,
.cms_content .table .table_holder a {
  width: 90%;
  font-size: 14px;
  color: #333e48;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 1600px) {
  .cms_content .table .table_holder .table_cell,
  .cms_content .table .table_holder a {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .cms_content .table .table_holder .table_cell,
  .cms_content .table .table_holder a {
    font-size: 11px;
  }
}
.cms_content .table .table_holder .table_cell .avatar,
.cms_content .table .table_holder a .avatar {
  margin-right: 10px;
}
.cms_content .table .table_holder .header {
  background: #e7e3e3;
  height: 50px;
  padding: 0 10px;
}
.cms_content .table .table_holder .content .table_row {
  position: relative;
  height: 50px;
  padding: 0 10px;
}
.cms_content .table .table_holder .content .table_row:nth-child(even) {
  background: #f8f8f8;
}
.cms_content .no_result {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
@media only screen and (max-width: 500px) {
  .cms_content .no_result {
    font-size: 14px;
  }
}
.cms_content .no_result svg {
  font-size: 1.7em;
  margin-right: 5px;
}
.cms_content .table_helper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 750px) {
  .cms_content .table_helper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cms_content .table_helper .align_row {
    width: 100%;
  }
  .cms_content .table_helper .align_row:first-child {
    order: 2;
  }
  .cms_content .table_helper .align_row:last-child {
    order: 1;
    margin-bottom: 20px;
  }
}
.cms_content .table_helper .paginate {
  width: 80px;
  height: 40px;
  border-radius: 6px;
  margin-right: 20px;
  border: 1px solid #dae1e7;
  position: relative;
}
.cms_content .table_helper .paginate .root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13px;
  color: #626262;
  cursor: pointer;
}
@media only screen and (max-width: 750px) {
  .cms_content .table_helper .paginate .root {
    padding: 0 10px;
    font-size: 12px;
  }
}
.cms_content .table_helper .paginate .root .rotate_arrow {
  transition: all 0.2s ease;
  transform: rotate(0);
}
.cms_content .table_helper .paginate .root .rotate_arrow.active {
  transform: rotate(180deg);
}
.cms_content .table_helper .paginate .dropdown_content {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background: #fff;
  z-index: 3;
  border-radius: 6px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.cms_content .table_helper .paginate .dropdown_content > div {
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding: 6px 0;
  color: #626262;
  font-size: 14px;
  transition: all 0.2s ease;
}
.cms_content .table_helper .paginate .dropdown_content > div:hover {
  background: #f8f8f8;
}
.cms_content .table_helper .search_holder {
  width: 250px;
  height: 40px;
  position: relative;
  margin-left: 10px;
}
@media only screen and (max-width: 750px) {
  .cms_content .table_helper .search_holder {
    margin: 0;
    width: 350px;
  }
}
@media only screen and (max-width: 500px) {
  .cms_content .table_helper .search_holder {
    width: 100%;
  }
}
.cms_content .table_helper .search_holder svg {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.5);
}
.cms_content .table_helper .search_holder input {
  width: 100%;
  height: 100%;
  font-size: 13px;
  padding: 0 20px 0 40px;
  background: transparent;
  color: #333e48;
  border-radius: 6px;
  border: 1px solid #dae1e7;
  transition: all 0.2s ease;
}
.cms_content .table_helper .search_holder input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.cms_content .table_helper .search_holder input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.cms_content .table_helper .search_holder input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.cms_content .table_helper .search_holder input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.cms_content .table_helper .search_holder input:focus {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  border-color: #E10770;
}
.cms_content .table_helper .search_holder button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  color: #ddd;
  border-radius: 4px;
}
.cms_content .pagination_nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cms_content .pagination_nav ul {
  display: flex;
  align-items: center;
  background: #f0f0f0;
  border-radius: 100px;
}
.cms_content .pagination_nav ul li {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  font-weight: bold;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cms_content .pagination_nav ul li.active {
  background: #E10770;
  color: #fff;
}
@media only screen and (max-width: 500px) {
  .cms_content .pagination_nav ul li {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
}

.expand_button {
  display: none;
}

.mobile_table_wrapper {
  display: none;
  cursor: auto;
  width: 100%;
  background: #fff;
  position: relative;
  padding: 10px 5px;
  margin: 10px 0;
  border-top: 1px solid #e7e3e3;
  border-bottom: 1px solid #e7e3e3;
}
.mobile_table_wrapper .mobile_table_holder {
  width: 100%;
  height: 100%;
}
.mobile_table_wrapper .mobile_table_holder .mobile_table_row {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 0 20px;
  padding: 10px 0;
  position: relative;
}
.mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_label {
  font-weight: 500;
  font-size: 12px;
  min-width: 50px;
}
.mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_content {
  font-weight: 300;
  font-size: 12px;
  word-break: break-all;
  position: relative;
}
.mobile_table_wrapper .mobile_table_holder .mobile_table_row .row_content a {
  font-weight: 300;
  font-size: 12px;
}

.cms_page form.form_holder .form_group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin: 20px 0;
}
@media only screen and (max-width: 1000px) {
  .cms_page form.form_holder .form_group {
    grid-template-columns: 100%;
    grid-gap: 40px 0;
  }
}
.cms_page form.form_holder .dropzone_wrapper {
  width: 100%;
}
.cms_page form.form_holder .dropzone_wrapper label {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.4);
}
.cms_page form.form_holder .dropzone_wrapper.main_photo .photo {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  position: relative;
  height: 250px;
}
@media only screen and (max-width: 1600px) {
  .cms_page form.form_holder .dropzone_wrapper.main_photo .photo {
    height: 200px;
  }
}
.cms_page form.form_holder .dropzone_wrapper.main_photo .photo svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(234, 84, 85);
  font-size: 1rem;
  cursor: pointer;
  font-size: 30px;
}
@media only screen and (max-width: 1600px) {
  .cms_page form.form_holder .dropzone_wrapper.main_photo .photo svg {
    font-size: 24px;
  }
}
.cms_page form.form_holder .dropzone_wrapper .input_file_holder {
  height: 100px;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  position: relative;
  cursor: pointer;
  margin: 20px 0;
}
.cms_page form.form_holder .dropzone_wrapper .input_file_holder input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.cms_page form.form_holder .dropzone_wrapper .input_file_holder span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}
.cms_page form.form_holder .dropzone_wrapper .photos_grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
@media only screen and (max-width: 500px) {
  .cms_page form.form_holder .dropzone_wrapper .photos_grid {
    grid-template-columns: 100%;
  }
}
.cms_page form.form_holder .dropzone_wrapper .photos_grid .photo {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  height: 250px;
  position: relative;
}
@media only screen and (max-width: 1600px) {
  .cms_page form.form_holder .dropzone_wrapper .photos_grid .photo {
    height: 200px;
  }
}
@media only screen and (max-width: 500px) {
  .cms_page form.form_holder .dropzone_wrapper .photos_grid .photo {
    height: 170px;
  }
}
.cms_page form.form_holder .dropzone_wrapper .photos_grid .photo svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(234, 84, 85);
  font-size: 1rem;
  cursor: pointer;
  font-size: 30px;
}
@media only screen and (max-width: 1600px) {
  .cms_page form.form_holder .dropzone_wrapper .photos_grid .photo svg {
    font-size: 24px;
  }
}
.cms_page form.form_holder .upload_photos_row {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 25% calc(75% - 20px);
  grid-gap: 0 20px;
}
@media only screen and (max-width: 1000px) {
  .cms_page form.form_holder .upload_photos_row {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}
.cms_page form.form_holder .form_buttons_group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cms_page form.form_holder .form_buttons_group .mh_20 {
  margin: 0 20px;
}
@media only screen and (max-width: 500px) {
  .cms_page form.form_holder .form_buttons_group.three_buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
  .cms_page form.form_holder .form_buttons_group.three_buttons button {
    margin: 0 !important;
  }
}
.cms_page form.form_holder .declaration_title {
  margin: 50px 0 40px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  color: #5a5a5a;
  font-weight: 500;
}
.cms_page form.form_holder .x3_row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 1000px) {
  .cms_page form.form_holder .x3_row {
    grid-template-columns: 100%;
    grid-gap: 40px;
    margin: 40px 0;
  }
}

.area_field {
  width: 100%;
  margin: 35px 0 0 0;
  position: relative;
}
.area_field textarea {
  position: relative;
  font-size: 13px;
  border-radius: 5px;
  padding: 10px 20px;
  background: transparent;
  color: #333e48;
  transition: all 0.2s ease;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.area_field textarea:focus {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  border-color: #E10770;
}
.area_field textarea:focus + label {
  color: #E10770;
}
.area_field label {
  position: absolute;
  top: -22px;
  left: 0;
  display: block;
  padding-left: 10px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.7rem;
  transition: all 0.2s ease;
}

.table_inline_form {
  width: 300px;
  display: flex;
  align-items: center;
}
.table_inline_form label {
  display: none;
}
.table_inline_form .input_holder .form_field label {
  font-size: 12px !important;
}
.table_inline_form .input_holder .form_field input:not([value=""]) + label {
  display: none !important;
}
.table_inline_form .input_holder .form_field input:focus + label {
  display: none !important;
}
.table_inline_form .input_holder .tooltip_wrap {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
  width: 24px;
  height: 24px;
}
.table_inline_form .input_holder .tooltip_wrap svg {
  color: rgb(234, 84, 85);
}
.table_inline_form button {
  margin-left: 20px;
}

.inline_update_form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 60px;
  padding: 0 10px;
  grid-gap: 0 20px;
  align-items: center;
  border-top: 1px solid #e7e3e3;
  border-bottom: 1px solid #e7e3e3;
}
.inline_update_form.not_full {
  bottom: 0;
  height: 50px;
}
.inline_update_form .input_holder {
  width: 220px !important;
  height: 35px;
}
@media only screen and (max-width: 500px) {
  .inline_update_form .input_holder {
    width: 170px !important;
  }
}
.inline_update_form .input_holder .form_field {
  height: 35px;
}
.inline_update_form .input_holder .form_field label {
  font-size: 12px !important;
}
.inline_update_form .input_holder .form_field input:not([value=""]) + label {
  display: none !important;
}
.inline_update_form .input_holder .form_field input:focus + label {
  display: none !important;
}
.inline_update_form .input_holder .tooltip_wrap {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
  width: 24px;
  height: 24px;
}
.inline_update_form .input_holder .tooltip_wrap svg {
  color: rgb(234, 84, 85);
}
.inline_update_form .search_select_wrapper {
  font-size: 13px;
  display: flex;
  align-items: center;
}
.inline_update_form .search_select_wrapper .search_select_holder {
  height: auto;
  width: 250px;
}
.inline_update_form .search_select_wrapper .error_msg {
  margin-left: 10px;
}
.inline_update_form .search_select_wrapper label {
  display: none;
}

.avatar {
  border: 1px solid #bfc9d1;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.skeleton_inner {
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.skeleton_inner .skeleton_wrapper {
  padding: 10px 20px;
  display: grid;
  align-items: center;
  grid-gap: 0 10px;
  background: #f2f2f2;
}
.skeleton_inner .skeleton_wrapper.header {
  padding: 20px;
}
.skeleton_inner .skeleton_wrapper .skeleton {
  background: #ddd;
  overflow: hidden;
  position: relative;
}
@media only screen and (max-width: 700px) {
  .skeleton_inner .skeleton_wrapper .skeleton.avatar {
    width: 35px;
    height: 35px;
  }
}
.skeleton_inner .skeleton_wrapper .skeleton.box {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.skeleton_inner .skeleton_wrapper .skeleton.text {
  width: 100%;
  height: 12px;
  border-radius: 30px;
}
.skeleton_inner .skeleton_wrapper .skeleton.label {
  width: 100px;
  height: 8px;
  border-radius: 30px;
  margin-bottom: 10px;
}
.skeleton_inner .skeleton_wrapper .skeleton.title {
  width: 50%;
  height: 20px;
  border-radius: 30px;
}
.skeleton_inner .skeleton_wrapper .skeleton.cell {
  width: 100%;
  height: 12px;
}
.skeleton_inner .skeleton_wrapper .skeleton.icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.skeleton_inner .skeleton_wrapper .skeleton.input {
  width: 100%;
  height: 38px;
  border-radius: 4px;
}
.skeleton_inner .skeleton_wrapper .skeleton.button {
  width: 120px;
  height: 38px;
  border-radius: 4px;
}
.skeleton_inner .skeleton_wrapper .skeleton.switch {
  width: 100%;
  height: 20px;
  border-radius: 30px;
}
.skeleton_inner .skeleton_wrapper .skeleton .shimmer_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 2s infinite;
}
.skeleton_inner .skeleton_wrapper .skeleton .shimmer_wrapper .shimmer {
  width: 95%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 20px rgba(255, 255, 255, 0.05);
}
.skeleton_inner.user_form {
  margin: 0;
}
.skeleton_inner.user_form .tabs {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 50px;
}
.skeleton_inner.user_form .tabs .tab {
  width: 100px;
  height: 12px;
  margin-right: 20px;
  border-radius: 30px;
}
.skeleton_inner.user_form .row {
  display: grid;
  grid-template-columns: 190px 1fr;
  grid-gap: 0 50px;
  align-items: flex-end;
}
@media only screen and (max-width: 1000px) {
  .skeleton_inner.user_form .row {
    grid-template-columns: 160px 1fr;
    grid-gap: 0 30px;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 800px) {
  .skeleton_inner.user_form .row {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}
.skeleton_inner.user_form .row .avatar {
  width: 190px;
  height: 190px;
}
@media only screen and (max-width: 1000px) {
  .skeleton_inner.user_form .row .avatar {
    width: 160px;
    height: 160px;
  }
}
.skeleton_inner.user_form .row .outer .inputs .input {
  margin-bottom: 20px;
}
@media only screen and (max-width: 1000px) {
  .skeleton_inner.user_form .row .outer .inputs .input {
    margin-bottom: 25px;
  }
}
.skeleton_inner.user_form .row .outer .buttons {
  display: flex;
  align-items: center;
}
.skeleton_inner.user_form .row .outer .buttons .button {
  margin-left: 20px;
}
@media only screen and (max-width: 1000px) {
  .skeleton_inner.user_form .row .outer .buttons .button {
    width: 70px;
  }
}
.skeleton_inner.news_form {
  margin: 0 !important;
}
.skeleton_inner.news_form .label {
  margin-top: 20px;
}
.skeleton_inner.news_form .textarea {
  height: 170px;
}
.skeleton_inner.news_form .dropzone {
  height: 250px;
  margin-bottom: 20px;
}
.skeleton_inner.news_form .buttons {
  display: flex;
  align-items: center;
}
.skeleton_inner.news_form .buttons .button {
  margin-left: 20px;
}
@media only screen and (max-width: 1000px) {
  .skeleton_inner.news_form .buttons .button {
    width: 70px;
  }
}
.skeleton_inner.show_order {
  margin: 0;
}
.skeleton_inner.show_order .title {
  margin: 10px 0;
}
.skeleton_inner.show_order .item {
  display: grid;
  align-items: center;
  grid-template-columns: 160px 1fr;
  padding: 13px 0;
}
@media only screen and (max-width: 700px) {
  .skeleton_inner.show_order .item {
    grid-template-columns: 130px 1fr;
  }
}
@media only screen and (max-width: 500px) {
  .skeleton_inner.show_order .item {
    grid-template-columns: 120px 1fr;
  }
}
.skeleton_inner.show_order .item .label {
  height: 12px;
  margin: 0;
}
.skeleton_inner.show_order .item .input {
  width: 300px;
}
@media only screen and (max-width: 700px) {
  .skeleton_inner.show_order .item .input {
    width: 100%;
  }
}
.skeleton_inner.show_order.note {
  width: 320px;
  margin: 0;
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 1000px) {
  .skeleton_inner.show_order.note {
    width: 100%;
    margin-top: 20px;
  }
}
.skeleton_inner.show_order.note .title {
  margin: 0;
}
.skeleton_inner.show_order.note .text {
  margin: 30px 0 10px 0;
}
.skeleton_inner.show_order.note .textarea {
  width: 100%;
  height: 200px;
  margin: 20px 0;
}
.skeleton_inner.show_order.note .input {
  margin-bottom: 20px;
}
.skeleton_inner.product_form {
  margin: 0;
}
.skeleton_inner.product_form.mt-20 .tabs {
  padding-bottom: 30px;
}
.skeleton_inner.product_form .skeleton_wrapper {
  display: block;
}
.skeleton_inner.product_form .tabs {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.skeleton_inner.product_form .tabs .tab {
  width: 100px;
  height: 12px;
  border-radius: 30px;
  margin-right: 20px;
}
@media only screen and (max-width: 800px) {
  .skeleton_inner.product_form .tabs .tab {
    width: 60px;
  }
}
@media only screen and (max-width: 500px) {
  .skeleton_inner.product_form .tabs .tab {
    width: 40px;
  }
}
.skeleton_inner.product_form .inputs_group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px 30px;
}
@media only screen and (max-width: 1000px) {
  .skeleton_inner.product_form .inputs_group {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}
.skeleton_inner.product_form .inputs_group .row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 40px;
  grid-gap: 0 10px;
}
.skeleton_inner.product_form .textarea_field {
  margin: 20px 0;
}
.skeleton_inner.product_form .textarea_field .textarea {
  width: 100%;
  height: 120px;
  border-radius: 6px;
}
.skeleton_inner.product_form .checkbox {
  display: grid;
  align-items: center;
  grid-template-columns: 20px 100px;
  grid-gap: 0 20px;
  margin-bottom: 20px;
}
.skeleton_inner.product_form .checkbox .label {
  margin: 0;
}
.skeleton_inner.product_form .buttons {
  display: flex;
  align-items: center;
}
.skeleton_inner.product_form .buttons .button {
  margin-left: 20px;
}
.skeleton_inner.categories_form {
  margin: 0;
}
.skeleton_inner.categories_form.mt-20 .tabs {
  padding-bottom: 30px;
}
.skeleton_inner.categories_form .skeleton_wrapper {
  display: block;
}
.skeleton_inner.categories_form .tabs {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.skeleton_inner.categories_form .tabs .tab {
  width: 100px;
  height: 12px;
  border-radius: 30px;
  margin-right: 20px;
}
@media only screen and (max-width: 800px) {
  .skeleton_inner.categories_form .tabs .tab {
    width: 60px;
  }
}
@media only screen and (max-width: 500px) {
  .skeleton_inner.categories_form .tabs .tab {
    width: 40px;
  }
}
.skeleton_inner.categories_form .inputs_group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px 30px;
}
@media only screen and (max-width: 1000px) {
  .skeleton_inner.categories_form .inputs_group {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}
.skeleton_inner.categories_form .inputs_group .row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 40px;
  grid-gap: 0 10px;
}
.skeleton_inner.categories_form .buttons {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.skeleton_inner.categories_form .buttons .button {
  margin-left: 20px;
}
.skeleton_inner.classic_form {
  margin: 0;
}
.skeleton_inner.classic_form .dropzone {
  width: 100%;
  height: 200px;
  margin: 10px 0 30px 0;
}
.skeleton_inner.classic_form .inputs_group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px 30px;
}
@media only screen and (max-width: 1000px) {
  .skeleton_inner.classic_form .inputs_group {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
  }
}
.skeleton_inner.classic_form .inputs_group .row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 40px;
  grid-gap: 0 10px;
}
.skeleton_inner.classic_form .buttons {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.skeleton_inner.classic_form .buttons .button {
  margin-left: 20px;
}
.skeleton_inner.invoices_data {
  margin: 0;
}
.skeleton_inner.invoices_data .item {
  display: grid;
  align-items: center;
  grid-template-columns: 90px 1fr;
  grid-gap: 0 30px;
  padding: 15px 0;
}
@media only screen and (max-width: 750px) {
  .skeleton_inner.invoices_data .item {
    grid-template-columns: 70px 1fr;
  }
}
.skeleton_inner.invoices_data .item .label {
  margin: 0;
  width: 100%;
}
.skeleton_inner.invoices_data .item .input {
  width: 300px;
}
@media only screen and (max-width: 500px) {
  .skeleton_inner.invoices_data .item .input {
    width: 100%;
  }
}
.skeleton_inner.invoices_show {
  margin: 0;
}
.skeleton_inner.invoices_show .title {
  margin: 15px 0;
}
.skeleton_inner.invoices_show .holder {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 20px;
}
@media only screen and (max-width: 1400px) {
  .skeleton_inner.invoices_show .holder {
    grid-template-columns: 100%;
    grid-gap: 50px 0;
  }
}
.skeleton_inner.invoices_show .holder .item {
  display: grid;
  align-items: center;
  grid-template-columns: 90px 1fr;
  grid-gap: 0 50px;
  padding: 15px 0;
}
@media only screen and (max-width: 750px) {
  .skeleton_inner.invoices_show .holder .item {
    grid-template-columns: 70px 1fr;
  }
}
.skeleton_inner.invoices_show .holder .item .label {
  margin: 0;
  width: 100%;
}
.skeleton_inner.invoices_show .holder .item .input {
  width: 300px;
}
@media only screen and (max-width: 500px) {
  .skeleton_inner.invoices_show .holder .item .input {
    width: 100%;
  }
}
.skeleton_inner.items_product {
  margin: 20px 0;
}
.skeleton_inner.items_product .skeleton_wrapper.body {
  display: grid;
  align-items: center;
  grid-template-columns: 100px 1fr 100px 100px 100px;
}
@media only screen and (max-width: 850px) {
  .skeleton_inner.items_product .skeleton_wrapper.body {
    grid-template-columns: 100px 1fr;
  }
  .skeleton_inner.items_product .skeleton_wrapper.body .text:nth-child(3) {
    display: none;
  }
  .skeleton_inner.items_product .skeleton_wrapper.body .text:nth-child(4) {
    display: none;
  }
  .skeleton_inner.items_product .skeleton_wrapper.body .text:nth-child(5) {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .skeleton_inner.items_product .skeleton_wrapper.body {
    grid-template-columns: 50px 1fr;
  }
}
.skeleton_inner.items_product .skeleton_wrapper.body .image {
  width: 100px;
  height: 40px;
  border-radius: 6px;
}
@media only screen and (max-width: 500px) {
  .skeleton_inner.items_product .skeleton_wrapper.body .image {
    width: 100%;
  }
}
.skeleton_inner.items_product .skeleton_wrapper.body .column {
  width: 250px;
}
@media only screen and (max-width: 500px) {
  .skeleton_inner.items_product .skeleton_wrapper.body .column {
    width: 100%;
  }
}
.skeleton_inner.items_product .skeleton_wrapper.body .column .title {
  margin: 0 0 10px 0;
}

@keyframes loading {
  from {
    transform: translateX(-150%);
  }
  to {
    transform: translateX(150%);
  }
}
.skeleton_wrapper.users {
  grid-template-columns: 40px 150px 400px 1fr 25px 25px;
}
@media only screen and (max-width: 1700px) {
  .skeleton_wrapper.users {
    grid-template-columns: 40px 100px 200px 1fr 25px 25px;
  }
}
@media only screen and (max-width: 900px) {
  .skeleton_wrapper.users {
    grid-template-columns: 40px 60px 100px 1fr 25px 25px;
  }
}
@media only screen and (max-width: 700px) {
  .skeleton_wrapper.users {
    grid-template-columns: 35px 1fr 25px 25px;
  }
  .skeleton_wrapper.users .skeleton:nth-child(3) {
    display: none;
  }
  .skeleton_wrapper.users .skeleton:nth-child(4) {
    display: none;
  }
}
.skeleton_wrapper.orders {
  padding: 20px;
  grid-template-columns: 20px 150px 400px 1fr 100px;
}
@media only screen and (max-width: 1000px) {
  .skeleton_wrapper.orders {
    padding: 15px 20px;
  }
}
@media only screen and (max-width: 1700px) {
  .skeleton_wrapper.orders {
    grid-template-columns: 40px 100px 200px 1fr 70px;
  }
}
@media only screen and (max-width: 1000px) {
  .skeleton_wrapper.orders {
    grid-template-columns: 20px 150px 1fr;
  }
  .skeleton_wrapper.orders .skeleton:nth-child(4) {
    display: none;
  }
  .skeleton_wrapper.orders .skeleton:nth-child(5) {
    display: none;
  }
}
@media only screen and (max-width: 760px) {
  .skeleton_wrapper.orders {
    grid-template-columns: 20px 80px 1fr;
  }
}
.skeleton_wrapper.activity_log {
  padding: 6px 20px;
  grid-template-columns: 60px 40px 70px 1fr 100px;
}
@media only screen and (max-width: 800px) {
  .skeleton_wrapper.activity_log {
    grid-template-columns: 25px 40px 60px 1fr;
  }
  .skeleton_wrapper.activity_log .skeleton:last-child {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .skeleton_wrapper.activity_log {
    grid-template-columns: 25px 40px 1fr;
  }
  .skeleton_wrapper.activity_log .skeleton:nth-child(4) {
    display: none;
  }
}
.skeleton_wrapper.products {
  grid-template-columns: 20px 200px 1fr 100px 25px 25px 25px;
}
@media only screen and (max-width: 1700px) {
  .skeleton_wrapper.products {
    padding: 20px;
  }
}
@media only screen and (max-width: 1000px) {
  .skeleton_wrapper.products {
    padding: 20px;
    grid-template-columns: 20px 100px 1fr 25px 25px 25px;
  }
  .skeleton_wrapper.products .skeleton:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .skeleton_wrapper.products {
    grid-template-columns: 20px 100px 1fr;
  }
  .skeleton_wrapper.products .skeleton:nth-child(2) {
    display: none;
  }
  .skeleton_wrapper.products .skeleton:nth-child(5) {
    display: none;
  }
  .skeleton_wrapper.products .skeleton:nth-child(6) {
    display: none;
  }
  .skeleton_wrapper.products .skeleton:nth-child(7) {
    display: none;
  }
}
.skeleton_wrapper.linking_product_our {
  grid-template-columns: 100px 200px 1fr 25px 25px 25px;
}
@media only screen and (max-width: 500px) {
  .skeleton_wrapper.linking_product_our {
    grid-template-columns: 50px 1fr 25px 25px 25px;
  }
  .skeleton_wrapper.linking_product_our .skeleton:nth-child(2) {
    display: none;
  }
}
.skeleton_wrapper.categories {
  grid-template-columns: 50px 1fr 100px 100px 25px 25px;
}
@media only screen and (max-width: 500px) {
  .skeleton_wrapper.categories {
    grid-template-columns: 25px 1fr 25px 25px;
  }
  .skeleton_wrapper.categories .skeleton:nth-child(3) {
    display: none;
  }
  .skeleton_wrapper.categories .skeleton:nth-child(4) {
    display: none;
  }
}
.skeleton_wrapper.linking_categories_our {
  grid-template-columns: 1fr 20px;
}
.skeleton_wrapper.web_margins {
  grid-template-columns: 200px 100px 1fr 25px 25px;
}
@media only screen and (max-width: 800px) {
  .skeleton_wrapper.web_margins {
    padding: 20px;
    grid-template-columns: 50px 1fr 25px 25px;
  }
  .skeleton_wrapper.web_margins .skeleton:nth-child(1) {
    display: none;
  }
}
.skeleton_wrapper.invoices_banks {
  grid-template-columns: 300px 1fr 20px 25px 25px;
}
@media only screen and (max-width: 1300px) {
  .skeleton_wrapper.invoices_banks {
    grid-template-columns: 200px 1fr 20px 25px 25px;
  }
}
@media only screen and (max-width: 700px) {
  .skeleton_wrapper.invoices_banks {
    grid-template-columns: 1fr 20px 25px 25px;
  }
  .skeleton_wrapper.invoices_banks .skeleton:nth-child(1) {
    display: none;
  }
}
.skeleton_wrapper.invoices {
  grid-template-columns: 20px 200px 1fr 100px;
}
@media only screen and (max-width: 770px) {
  .skeleton_wrapper.invoices {
    grid-template-columns: 20px 1fr;
  }
  .skeleton_wrapper.invoices .skeleton:nth-child(2) {
    display: none;
  }
  .skeleton_wrapper.invoices .skeleton:nth-child(4) {
    display: none;
  }
}
.skeleton_wrapper.invoices_emails {
  grid-template-columns: 1fr 20px 25px 25px;
}
.skeleton_wrapper.inline {
  grid-template-columns: 1fr 25px 25px;
}

.linking_categories_screen_outer .skeleton_inner {
  margin-top: 80px;
}
@media only screen and (max-width: 500px) {
  .linking_categories_screen_outer .supplier_categories_table .skeleton_inner {
    margin-top: 140px;
  }
}

.product_item_skeleton_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media only screen and (max-width: 1200px) {
  .product_item_skeleton_wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .product_item_skeleton_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
.product_item_skeleton_wrapper .product_item_skeletor {
  margin: 0;
}
.product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper {
  display: block;
  padding: 20px;
  transform: scale(0.97);
  border-radius: 6px;
}
.product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper .title {
  margin: 20px 0;
  width: 80%;
}
.product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper .photo {
  width: 100%;
  border-radius: 6px;
  height: 200px;
}
@media only screen and (max-width: 800px) {
  .product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper .photo {
    height: 120px;
  }
}
.product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper .row .price {
  width: 50px;
  height: 30px;
  border-radius: 6px;
}
@media only screen and (max-width: 800px) {
  .product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper .row .price {
    width: 40px;
    height: 22px;
  }
}
.product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper .row .thumbnail {
  width: 50px;
  height: 30px;
  border-radius: 6px;
}
@media only screen and (max-width: 800px) {
  .product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper .row .thumbnail {
    width: 40px;
    height: 22px;
  }
}
.product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper .button {
  border-radius: 30px !important;
  width: 100% !important;
}
@media only screen and (max-width: 800px) {
  .product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper .button {
    height: 34px !important;
  }
}
.product_item_skeleton_wrapper .product_item_skeletor .skeleton_wrapper .favorite {
  width: 25px;
  height: 25px;
  margin-top: 20px;
  margin: 20px auto 0 auto;
  border-radius: 50%;
}