.authorization_screen_wrapper {
  display: grid;
  grid-template-columns: 440px calc(100% - 460px);
  @include media-max(1450px) {
    grid-template-columns: 100%;
  }
  .navigation_title {
    margin-bottom: 20px;
    @include media-max(800px) {
      &.last {
        margin-top: 20px;
      }
    }
  }
  .right_side {
    width: 100%;
    display: grid;
    grid-gap: 0 20px;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 20px;
    @include media-max(1450px) {
      margin: 20px 0 0 0;
    }
    @include media-max(800px) {
      grid-template-columns: 100%;
    }
  }
  .authorization_screen {
    .table-authorization {
      .header,
      .table_row {
        @include flex-between;
        & > .table_cell:last-child {
          @include flex-align-center;
          justify-content: flex-end;
        }
      }
      .table_row {
        cursor: pointer;
        &.active {
          background: linear-gradient(
            146deg,
            #E10770,
            rgb(225 7 112 / 30%),
            #E10770
          ) !important;
          .table_cell {
            color: $color-white;
          }
          button.icon_button {
            color: $color-white;
          }
          .inline_update_form {
            button.icon_button {
              color: #b9b9b9;
            }
          }
        }
      }
    }
    @include media-max(500px) {
      .table_helper {
        .align_row {
          .table_inline_form {
            width: calc(100% - 100px);
            .input_holder {
              width: calc(100% - 65px);
            }
            button {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .permissions_inner {
    div {
      font-size: 14px;
      background: $color-grey-10;
      border-bottom: 1px solid $color-white;
      padding: 10px;
      cursor: pointer;
      &.active {
        background: linear-gradient(
          146deg,
          #E10770,
          rgb(225 7 112 / 30%),
          #E10770
        ) !important;
        color: $color-white;
      }
      @include media-max(800px) {
        font-size: 12px;
      }
    }
  }
  .preview_inner {
    @include media-max(800px) {
      margin-top: 20px;
    }
    &.box_holder {
      padding-top: 0;
    }
    h2 {
      font-weight: 500;
      font-size: 20px;
      padding: 30px 0;
      text-align: center;
      @include media-max(800px) {
        font-size: 16px;
        padding: 20px 0;
      }
    }
    .preview_box {
      &:last-child {
        margin-bottom: 0;
      }
      .item {
        font-size: 14px;
        background: $color-grey-10;
        border-bottom: 1px solid $color-white;
        padding: 10px;
        @include flex-between;
        cursor: pointer;
        @include media-max(800px) {
          font-size: 12px;
        }
        svg {
          color: $color-success;
          @include media-max(800px) {
            font-size: 16px;
          }
        }
      }
      &.role {
        .item {
          svg {
            color: $color-danger;
          }
        }
      }
    }
    .no_result {
      font-size: 14px;
      background: $color-grey-10;
      border-bottom: 1px solid $color-white;
      padding: 10px;
      justify-content: flex-start;
      @include media-max(800px) {
        font-size: 12px;
      }
    }
  }
}
